import React from 'react'
import { FormattedMessage } from 'react-intl';
import { getRedirectUrl } from '../../utils'

const Error404 = () => (
  <div className='error-page center'>
    <div className='error-img error-404' />
    <div className='error-details'>
      <div className='error-title'>
        <FormattedMessage id="404" defaultMessage="404" />
      </div>
      <div className='error-sub-title'>
        <h3><FormattedMessage id="Page not found" defaultMessage="Page not found" /></h3>
      </div>
      <div className='error-desc'>
        <FormattedMessage id="The page you are looking for does not exist. Check the URL or go to the homepage." defaultMessage="The page you are looking for does not exist. Check the URL or go to the homepage." />
      </div>
      <div className='go-home-button'>
        <a href={getRedirectUrl('app', {}, {}, false)} className='error-btn'>
          <FormattedMessage id="Go To Home" defaultMessage="Go To Home" />
        </a>
      </div>
    </div>
  </div>
)

export default Error404
