import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { detect } from 'detect-browser'
import cmp from 'semver-compare'
import { FormattedMessage } from 'react-intl';

const minBrowserVersions = {
  chrome: '4.10',
  edge: '6',
  firefox: '19.5',
  ie: '10',
  opera: '10.0',
  safari: '10.2',
  crios: '85.0',
  ios: '10.2',
}

class BrowserSupport extends Component {
  constructor() {
    super()
    const browser = detect()
    this.isBrowserSupported = !!minBrowserVersions[browser.name] &&
      (cmp(browser.version, minBrowserVersions[browser.name]) >= 0)
  }
  render() {
    if (!this.isBrowserSupported) {
      return <div><FormattedMessage id="Your browser is not supported, please upgrade!!!" defaultMessage="Your browser is not supported, please upgrade!!!" /></div>
    }

    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

BrowserSupport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

BrowserSupport.defaultProps = {
  children: null
}

export default BrowserSupport
