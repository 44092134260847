export default {
  en: {
    lastUpdated: '2019-12-16T09:10:54.189Z',
    version: '1.1',
    'landing.head-title': 'AI-powered Intelligence for Drug Development',
    'landing.head-content': 'offers a comprehensive approach to research and discovery in life sciences via AI and our self-learning ontology. Users get real-time, relevant insights from modules and dashboards that help to accelerate processes and thereby reduce costs throughout the drug development cycle.',
    'landing.our-solutions-title': 'Our Solutions',
    'landing.our-solutions-title-text': 'Comprehensive modules and dashboards to help accelerate processes from pre-clinical and clinical to regulatory and commercial phases',
    'landing.discover-title': 'Accurate insights from largest data ocean',
    'landing.discover-content': 'Leverage AI to analyze information from the largest life sciences data ocean using our self-learning ontology for continuous, real-time insights.',
    'landing.explore-title': 'Identify known and unknown connections',
    'landing.explore-content': 'Visualize connections between biological entities using power maps and research graphs to discover potential drugs and targets.',
    'landing.influence-title': 'Top KOLs discovery and management',
    'landing.influence-content': 'Get access to real-time discovery, network analysis. and management of top and emerging key opinion leaders (KOLs) and digital opinion leaders (DOLs).',
    'landing.integrate-title': 'Real-time performance overview',
    'landing.integrate-content': 'Optimize leadership strategy with real-time access to product performance, sales, budget and expenses, patient activity, CI landscape, etc.',
    'landing.dashboard-title': 'Relevant updates on your fingertips',
    'landing.dashboard-content': 'Obtain up-to-date, relevant information and insights generated from continuously crawled multiple publicly available sources in one place.',
    'landing.daas-title': 'Access to 95% publicly available data',
    'landing.daas-content': 'Life sciences data as a service (DaaS) APIs covering scientific sources such as publications, clinical trials, patents, grants, drug profiles, and gene profiles.',
    'landing.our-data-ocean-title': 'Our Data Ocean',
    'landing.our-data-ocean-content': 'Over 95% of web scanned and publicly available life sciences data from multiple sources – crawled, aggregated, analyzed, and visualized for accurate insights.',
    'dashboard.head-title': 'Focus on decision making rather than data accumulation',
    'dashboard.head-content': 'Dashboards empower teams involved at various stages of drug development with AI-enabled custom insights and updates to make strategic decisions. Let the machine do the data heavy lifting, so you can accelerate every step with deeper insights for specific objectives. Get real-time updates on patents, competitive intelligence, treatment guidelines, regulatory requirements, and medical affairs with our unique, intuitive dashboards. Why go searching everywhere when there could be a dashboard tailored to your specific needs!',
    'dashboard.highlights-title': 'Be ahead of everyone else with continuous AI-enabled insights',
    'dashboard.highlights-1': 'Get dashboards for any and all business functions such as Medical Affairs, Regulatory, Competitive Intelligence, R&D, Clinical, Commercial, Business Development, and Management ',
    'dashboard.highlights-2': 'Significant time-savings and reduction in mundane repetitive tasks through AI-powered aggregation and intuitive visualization of insights, updated from over 95% of public web, enterprise, and third-party data sets ',
    'dashboard.highlights-3': 'Timely updates and real-time notifications to make sure you do not miss out on an important business decision',
    'dashboard.medical-affairs-title': 'Medical Affairs',
    'dashboard.medical-affairs-content': 'Get relevant insights on clinical trials, guidelines, and label revisions regarding indications and interventions of interest with AI-powered dashboards that leverage data from over 100 trial registries, major regulatory bodies, and scientific societies across the globe. Accelerate processes for medical affairs and empower them with real-time updates for informed and timely decision-making.',
    'dashboard.regulatory-updates-title': 'Regulatory Updates',
    'dashboard.regulatory-updates-content': 'Stay up-to-date with the latest regulatory updates, label revisions, disease and drug guidelines, alerts, and drug-launch statuses from regulatory authorities worldwide, including FDA, EMA, TGA, Health Canada, Medsafe, CHMP, PRAC, etc. Get real-time insights about regulatory submissions of drugs regarding indication of your interest from major regulatory bodies.',
    'dashboard.sentiment-watch-title': 'Sentiment watch',
    'dashboard.sentiment-watch-content': 'Improve positioning of drugs through quick analysis of patients’ and physicians’ sentiments from digital and scientific media. Track the competitive share of voice across drugs and indications of interest, and stay abreast of real-time buzz on sponsors, sentiment momentum, hot topics, and weak signals. Identify top channels and derive targeted communication strategies for the highest impact.',
    'dashboard.guideline-watch-title': 'Guideline watch',
    'dashboard.guideline-watch-content': 'Gain insights from treatment guidelines across major societies worldwide for your indication of interest. Compare guidelines from different societies, analyze the latest trends, and monitor changes in real time with the AI-powered, intuitive dashboard. Identify top guideline authors and get indication-specific news to help with the success of your drug.',
    'dashboard.competitive-landscape-title': 'Competitive landscape',
    'dashboard.competitive-landscape-content': 'Real-time intelligence on competitor drugs, clinical trials, and patents for all therapeutic areas. Get detailed analysis of publications, clinical trials, and regulatory approvals, plus insights around drugs, targets, diseases, and proteins. Identify competitors for your search query, ranked and scored on the basis of patent-filing trends, assets in clinical trials (by phase), regulatory status, and more.',
    'dashboard.my-dashboard-title': 'My dashboard',
    'dashboard.my-dashboard-content': 'Didn’t find the one you need? Make your own dashboard that delivers customized insights on your topics of interest. Select data points and generate widgets to analyze the data in a click, saving significant time through intuitive visualization. Get notified with real-time updates and make decisions using the most relevant, to-the-point, and latest information.',
    'discover.head-title': 'Speed up your research by 8x',
    'discover.head-content': "Discover is a fully automated discovery engine using artificial intelligence to direct your focus on the data that matters most to you. Access the world's most comprehensive scientific literature database, covering 1.3M theses, 5M grants, 3.3M congress articles, 42M publications, 639k clinical trials, 3.7M news & press releases and much more. Why wait for months when you can access everything in real time!",
    'discover.keylist-title': 'Automate 80% of data intelligence',
    'discover.keylist-1': 'AI-powered discovery engine to help generate customizable results and intuitive graphics by scanning up to 95% of web to crawl necessary information.',
    'discover.keylist-2': 'Curated using life sciences language processing™ and life sciences ontology of over 30M biomedical terms',
    'discover.keylist-3': 'Get 2x more coverage than clinicaltrial.gov and 12% more than Pubmed',
    'discover.concept-based-search-title': 'Concept-based search',
    'discover.concept-based-search-content': 'Generate highly relevant results unlike traditional, ambiguous search engines. AI combined with life science ontology interprets your search term, disambiguates entities, and suggests concepts accordingly. You can sort and narrow down your search results using different sorting criteria, such as recency and relevancy, and numerous filter parameters, including time and source respectively.',
    'discover.accelerating-biomedical-research-title': 'Accelerating Biomedical Research',
    'discover.accelerating-biomedical-research-content': 'Experience enhanced productivity, improved research outcomes and unprecedented progress in your biomedical endeavors. Save your valuable research time with Ontosight®’s universe of life science data under one roof, contextual search for directly reaching relevant results, wordcloud to avoid missing important references and finding trending topics as well as keeping tabs on topics of interest through bookmarks, workspace and bibliography. ',
    'discover.provides-comprehensive-information-title': 'Providing comprehensive information',
    'discover.provides-comprehensive-information-content': 'With biomedical data doubling every 73 days [1], finding needed information is time consuming for researchers.',
    'discover.provides-comprehensive-information-keylist-1': '48.9M+ Publications',
    'discover.provides-comprehensive-information-keylist-2': '830k+ Clinical Trials',
    'discover.provides-comprehensive-information-keylist-3': '2.2M+  Congresses',
    'discover.provides-comprehensive-information-keylist-4': '4.6M  Grants',
    'discover.provides-comprehensive-information-keylist-5': '1.5M+ Thesis and dissertations',
    'discover.provides-comprehensive-information-keylist-6': '200k+ Protein, gene and drugs',
    'discover.provides-comprehensive-information-keylist-7': '9.4M+ Patents',
    'discover.real-time-data-update-title': 'Real-time data update',
    'discover.real-time-data-update-content': 'Generate insights based on present-day information with the help of our AI technology that can crawl relevant clinical and medical data. Our life science data repository is continually updated in near real time, to enable informed decisions built around the most recently published data.',
    'discover.custom-newsletters-title': 'Custom newsletters',
    'discover.custom-newsletters-content': 'Stay on top of your unique research area by creating research clusters, setting alerts to receive real-time updates as relevant literature, grants, and news are published. Organize bookmarks, citations, sharing, and team collaboration with a personalized workspace.',
    'discover.reat-time-novel-insight-title': 'Generating real-time novel insights',
    'discover.reat-time-novel-insight-content': 'Word Clouds summarizes the key topics and trends from the searched result sets enabling you to find weak signals, stay informed with latest advancements and generate new research questions.',
    'discover.relevant-information-title': 'Getting relevant information',
    'disocver.relevant-information-content': 'With so much data out there, it is important for researchers to get relevant content. Therefore our concept-based search to differentiate signal from noise; further allowing narrowing down to specific results using advanced  filters and sorting capabilities.',
    'discover.intuitive-visualization-title': 'Intuitive visualization',
    'discover.intuitive-visualization-content': 'Build insights through intuitive graphs rather than reading thousands of results. Leverage the analytics view to visually understand your search space with a quick glance on top trends, authors, and affiliations. Explore concepts (indications, interventions, or targets) related to your search query through intuitive word clouds.',
    'discover.data-from-millions-title': 'Data from millions of sources',
    'discover.data-from-millions-content': 'Ontosight Lite provides contextual data across pre-clinical, clinical, regulatory and commercial stages of a drug crawled and indexed from over a 100 terabytes of scientific data. It currently has 48.9M+ Publications from 30k+ scientific journals, 830k+ Clinical Trials from 100+ countries, 9.4M+ patents  from major patent offices and millions of more documents from different types.',
    'discover.real-time-data-upload-title': 'Real-time data update',
    'discover.real-time-data-upload-content': 'Ontosight Lite has a large life science data lake crawled from millions of publicly available sources. It crawls and indexes thousands of documents such as publications, clinical trials and more, on a daily basis to provide up-to-date insights.',
    'discover.contextual-ai-based-platform-title': 'Contextual AI based platform',
    'discover.contextual-ai-based-platform-content': 'Say goodbye to shortcomings of traditional keyword-based searches and embrace the future of intelligent research.',
    'discover.detailed-deep-dives-title': 'Detailed deep-dives',
    'discover.detailed-deep-dives-content': 'Understand the mechanism of a drug, or potential indications and trials underway, with detailed information and ontology connections, or explore gene ontology, phenotypes, and synthetic lethality. Automatically creates hooks for gene, protein, drug, and researcher entities from the search query, allowing you to find detailed information and ontology connections.',
    'explore.head-title': 'Visualize ‘never thought to be linked’ entities',
    'explore.head-content': 'Explore is an AI-based engine for exploration and identification of obvious and non-obvious connections between diseases, pathways, proteins/targets, and drugs. Visualize comprehensive network of biological knowledge to better understand disease pathways and uncover novel mechanism of action (MoA), or explore avenues for drug label expansion or combination therapies. Why risk missing an important connection when it may lead to the next blockbuster drug!',
    'explore.keylist-title': 'Biggest life sciences research graph',
    'explore.keylist-1': 'Strong and weak connections between 300K diseases, 6.5K pathways, 500K proteins, and 2M+ drugs & chemical compounds',
    'explore.keylist-2': 'Network graphs displaying associations which are up to three degrees of separation away, to link a disease to a drug based on its label, or indirect connection of a disease to a drug based on secondary targets, and pathways, which play a role in that disease',
    'explore.keylist-3': 'Accelerate and identify unexplored territory in drug discovery, drug repurposing, combination therapy, and target identification efforts',
    'explore.direct-indirect-connections-title': 'Direct/indirect connections',
    'explore.direct-indirect-connections-content': 'Find the missing branches of drug discovery by uncovering previously hidden connections. Gain insights from the interconnected networks between drugs, pathways, targets, and diseases. Accelerate your drug repurposing efforts by exploring and identifying known and unknown associations to find potential interventions and pathways.',
    'explore.explore-networks-title': 'Explore networks',
    'explore.explore-networks-content': 'Build networks around selected biological entities and understand associations which are three degrees of separation away. Identify known pathways for a disease, along with proteins associated with the pathways. Explore closely associated therapeutic areas and their corresponding drugs.',
    'explore.prioritize-assets-title': 'Prioritize assets',
    'explore.prioritize-assets-content': 'Score and rank entities in a table view, based on their commonality/association, to prioritize assets for target or indication prioritization. Redefine opportunities for drug repurposing with drugs used to treat other indications by identifying  a common pathway between two indications.',
    'Data Not Availble': 'Validate the accuracy of information by selecting connections between concepts. Evidence-based research network replaces the traditional black-box models to solve real problems in strategic drug discovery.',
    'influence.head-title': 'Track over 23 million researchers, investigators, and KOLs globally',
    'influence.head-content': 'Influence is an intuitive AI engine for discovery of use-case specific researchers, investigators, and key opinion leaders for your drug development projects. Identify networks of top, second-tier, or emerging KOLs and dive deep into their always up-to-date comprehensive profiles and connections to strategize meaningful engagements. Prioritize KOLs to meet precise needs across any disease area, rare diseases, or geography, and get real-time updates on your favorite KOLs with only relevant, targeted information valuable to you. Why rely on subjective evaluation of external parties when you can objectively select the ones ‘best for you’!',
    'influence.highlights-title': 'Worldwide heat-maps and 25 data filters',
    'influence.highlights-1': 'Objective and instantaneous list of most suitable researchers, investigators, or key opinion leaders generated by AI',
    'influence.highlights-2': 'Global coverage across all therapeutic areas, cross-therapy areas, and rare indications, with comprehensive profiles updated in real time.',
    'influence.highlights-3': 'Worldwide heat-map and indication level connections to identify the most suitable speaker/KOL for information dissemination, researcher for new discovery areas, or investigator for upcoming trial',
    'influence.kol-influencer-overview-title': 'KOL/influencer overview ',
    'influence.kol-influencer-overview-content': 'Obtain insights on activities and associations of KOLs. Discover speakers, investigators, scientific experts, digital influencers, HTA experts, and PAG influencers. Identify top KOLs, emerging KOLs, second-tier KOLs, digital opinion leaders for any therapeutic area and indication. Select geographies to find influencers, speakers, and principal investigators with top influence in those geographies.',
    'influence.detailed-profiles-title': 'Detailed profiles',
    'influence.detailed-profiles-content': 'Generate deeper insights about individual KOLs and influencers through holistic deep-dives into their research profiles, updated in real time. Study word cloud of indications, interventions, and genes based on the KOL’s research history and affiliations. Develop actionable engagement plan based on KOL and influencers’ association with patient advocacy groups, hospitals, physician societies, guidelines, HTA bodies and regulatory authorities through a holistic profile overview.',
    'influence.network-analysis-title': 'Network analysis',
    'influence.network-analysis-content': 'Visualize network connections of KOLs and their relative influence through intuitive, automated power maps to find the KOL with the maximum reach and connectivity. Identify your connections who can connect you to the target KOL or to some KOLs in your region and disease area of interest. Obtain detailed deep-dives to evidence of connection and relevant sources such as clinical trials, publications, congresses, and others.',
    'influence.customized-ranking-title': 'Customized ranking',
    'influence.customized-ranking-content': 'Compare & prioritize KOLs/influencers using customizable scoring logic to identify those most relevant to your needs (speakers, investigators, regulatory/HTA influencers, etc.). Narrow your results on the basis of KOL participation in clinical trials, congresses, or as publications authors, guidelines authors, etc. Employ a quantitative approach to discover who’s on the top, based on indication, geography, or data assets such as publications, congresses, clinical trials, guidelines, and other data sources.',
    'influence.events-calendar-title': 'Events calendar',
    'influence.events-calendar-content': 'Holistic overview of congresses and conferences from all over the globe. Easily access conference agenda and participants/attendees to plan your pre-conference, during-conference, and post-conference engagement and business strategies. Check events to be held for your indication/therapeutic area, and leverage key speakers’ networks and connections for your drug or research. Get flexible integration with any current system or CRM your company leverages.',
    'integrate.head-title': 'Resolve challenges of data fragmentation and accessibility',
    'integrate.head-content': 'Integrate unifies information from a multitude of sources via connectors to over 25 popular data software programs and formats. Artificial intelligence is used to clean, structure, and normalize, disparate and siloed datasets into a single continuously updating analytics dashboard. Ontosight® Integrate automates data processing, optimizes workflows and customer engagement across different business functions to enable faster insight generation from a unified data source. Why analyze multiple data sources manually and wait weeks for reports when you can easily access all this processed information in a single dashboard that unifies all your data!',
    'integrate.highlights-title': 'Let AI make your data “available-for-analysis”',
    'integrate.highlights-1': 'Achieve considerable time savings with a fully automated AI-driven data ingesting, aggregating, analyzing, and report generating platform, deployed via dashboards or APIs',
    'integrate.highlights-2': 'Currently supporting integration on over 25 data sources, such as ERP, CRM, call center, device data, reimbursement/patient access software, and EHR, and claim files',
    'integrate.highlights-3': 'On-demand analysis and visualizations, such as predictive modeling, scenario-based forecasting, and real-time performance monitoring, offering up-to-date insights for major business decisions',
    'integrate.data-amalgamation-title': 'Data amalgamation',
    'integrate.data-amalgamation-content': 'Merge internal and external, structured and unstructured data with readily available plugins for 25+ data sources such as ERP, CRM, call center, device data, reimbursement/patient access software, and EHR & claim files to provide seamless analytics on KPIs. Bring together scattered data sources from different enterprise solutions, departments, and third-party data providers and datasets stored in different software systems, with incompatible data formats. ',
    'integrate.reducing-data-disparity-title': 'Reducing data disparity',
    'integrate.reducing-data-disparity-content': 'Access structured enterprise and third-party data traditionally available in disparate and unstructured data formats. Data is automatically cleaned and structured by leveraging AI technologies such as computer vision and entity normalization. Computer vision converts information within PDFs, images, etc. into machine-readable text which can then be analyzed and fed into algorithms, whereas entity normalization connects entity-specific records across scattered data sources.',
    'integrate.predictive-analytics-title': 'Predictive analytics',
    'integrate.predictive-analytics-content': 'Predictive analytics provided by leveraging proprietary and patented artificial intelligence and machine learning algorithms. Real-time updates from internal and external sources enable results based on the most recent data, offering leadership a complete picture that is up-to-date. Gain the agility and flexibility to more effectively respond to market changes and better engage with their customers.',
    'integrate.enterprise-level-security-title': 'Enterprise level security',
    'integrate.enterprise-level-security-content': 'Solution is deployed within enterprise infrastructure such that data never leaves the enterprise firewall. Ensure comprehensive security of data with isolated environment, disk-encryption, regulatory compliances, and role-based access. Leverage the flexibility to define and manage users and user roles. Accelerate the labor-intensive, time-consuming process of data collation relevant for each internal stakeholders.',
    'daas.head-title': 'DaaS - RESTful APIs for life science data',
    'daas.head-content': 'DaaS empowers data analytics teams in life sciences with what’s most important to them – structured life sciences data! Experience the power and flexibility of data in the palm of your hand with comprehensive APIs. Equipped with Innoplexus’s proprietary machine learning algorithms and life sciences ontology, these APIs are every life sciences data team’s dream. Why spend hours crawling, structuring, and aggregating data when you can use our DaaS APIs and focus on building better solutions!',
    'daas.highlights-title': 'Build & grow with powerful DaaS APIs',
    'daas.highlights-1': 'Access to feeds from over 50 thousand sources covering theses, grants, congresses, publications, clinical trials, news, press releases, and much more',
    'daas.highlights-2': 'Clean, structured, and connected data that is updated in near real time, with flexibility to build custom applications ',
    'daas.highlights-3': 'Universal JSON data format for simple plug and play integration with almost any visualization tool, or exporting to any other file formats',
    'daas.comprehensive-data-title': 'Comprehensive data',
    'daas.comprehensive-data-content': 'Empower your data analytics teams with holistic life sciences data that is structured and validated. DaaS APIs provide normalized, non-relational, derived, aggregated, and cross-referenced entity relationship data from 1.3M theses, 5M grants, 3.3M congress articles, 42M publications, 639K clinical trials, 3.7M news & press releases, and much more.',
    'daas.flexible-and-secure-title': 'Flexible and secure',
    'daas.flexible-and-secure-content': 'Our RESTful APIs offer complete flexibility to build custom applications with standard JSON outputs, and are highly scalable for big data architectures. Experience secure data extraction and encrypted communications for enterprise use.',
    'daas.continuous-data-update-title': 'Continuous data update',
    'daas.continuous-data-update-content': 'Always be on top of your game with updates that matter the most. Daas APIs provide a continuous and seamless update in near real time for content and metadata, as soon as it is available in the public domain.',
    'daas.in-built-ai-and-network-analysis-title': 'In-built AI and network analysis',
    'daas.in-built-ai-and-network-analysis-content': 'Get ontology-driven, concept-based search results for on-point analysis or find activity/network-based top investigators or KOLs in your area of interest. Our DaaS APIs come with Innoplexus’s self-learning life sciences ontology that understands search queries and offers the most relevant results, taking into account all related concepts across various asset classes.',
    'button.request-demo': 'Request Demo',
    'link.contact': 'Contact',
    'link.privacy-policy': 'Privacy Policy',
    'link.user-policy': 'User Policy',
    'link.learn-more': 'Learn more',
    'text.our-office': 'Our Offices',
    'text.we-are-hiring': 'We are Hiring!',
    'text.customer-support': 'Customer Support',
    'text-follow-us': 'Follow us',
    'text-write-to-us': 'Write to us',
    'academics.head-title': 'Experience the Life Sciences AI. Now.',
    'lite.head-content': 'Accelerating your biomedical research by providing comprehensive, relevant information and generating real-time novel insights from millions of sources using contextual AI-based platform.',
    'academics.head-content': 'Accelerating your biomedical research by providing comprehensive, relevant information and generating real-time novel insights from millions of sources using contextual AI-based platform.',
    'lite.access-btn': 'Sign Up For Free',
    'academics.access-btn': 'Sign Up For Free',
    'academics.access-btn-title': 'Use your University email for requesting invite.',
    'academics.keylist-title': 'Push your competitive edge with relevant data',
    'academics.keylist-1': 'Most comprehensive life sciences ontology',
    'academics.keylist-2': 'Covers over 95% of publicly available life sciences data',
    'academics.keylist-3': 'Concept-based contextual search results',
    'academics.keylist-4': 'Holistic and detailed deep-dive functionalities',
    'academics.research-clusters-title': 'Research clusters',
    'academics.research-clusters-content': 'Save query around search criterion as a research cluster which can be used to track progress on the selected query results.',
    'academics.explore-top-concepts-title': 'Explore top concepts',
    'academics.explore-top-concepts-content': 'Discovery of top trends in indications, top trends for interventions or targets, top affiliations associated with search query, and top journals and authors from each asset class',
    'academics.discover-top-kols-title': 'Discover top KOLs',
    'academics.discover-top-kols-content': 'Identify key opinion leaders through an interactive word cloud. Deep-dive to view author profile, research activity graph, and details of the selected author',
    'academics.custom-sort-title': 'Classify relevant documents on the basis of title, abstract, author name, or full text with custom weightages for each',
    'academics.clinical-development-title': 'Clinical development',
    'academics.clinical-development-content': 'Plan clinical trials with up-to date information on clinical development pipeline across indications, interventions, and therapies',
    'academics.custom-filters-title': 'Custom filters',
    'academics.custom-filters-content': 'Improve relevance of search results by defining the time range in years, or sort by recency to get results from most recent years',
    'Header.solution-dropdown': 'Solutions',
    'Header.support-dropdown': 'Customer Support',
    'Header.try-discover-academ-link': 'Access Ontosight Lite',
    'link.careers': 'Careers',
    'link.address-germany': 'Eschborn (HQ), Germany',
    'link.address-usa': 'Hoboken, USA',
    'link.address-india': 'Pune, India',
    'link.login': 'Login',
    'Contact.title': 'Contact Us',
    'Contact.address': 'Address',
    'Contact.email': 'Email',
    'Contact.customer.support': 'Customer Support',
    'Highlights.title': 'Highlights',
    'discover.sub-footer-text': 'Enable accurate, informed decision-making with <div>Ontosight<sup>®</sup> Discover!</div>',
    'influence.sub-footer-text': 'Find top KOLs for drug development with <div>Ontosight<sup>®</sup>  Influence.</div>',
    'explore.sub-footer-text': 'Enable accurate, informed decision-making with',
    'dashboards.sub-footer-text': 'Get the right information in a click with our <div>Ontosight<sup>®</sup>Dashboard.</div>',
    'integrate.sub-footer-text': 'Optimize your leadership strategy with',
    'daas.sub-footer-text': 'Empower your data analytics teams with our',
    'landing.publication-title': 'Publications',
    'landing.publication-value': '42 M+',
    'landing.clinical-trials-title': 'Clinical Trials',
    'landing.clinical-trials-value': '639 K+',
    'landing.congresses-title': 'Congresses',
    'landing.congresses-value': '3.3 M+',
    'landing.guidelines-title': 'Treatment Guidelines',
    'landing.guidelines-value': '35 K+',
    'landing.theses-title': 'Theses',
    'landing.theses-value': '1.3 M+',
    'landing.patents-title': 'Patents',
    'landing.patents-value': '28.6 K+',
    'landing.grants-title': 'Grants',
    'landing.grants-value': '5 M+',
    'landing.authors-title': 'Researchers & Physicians',
    'landing.authors-value': '23 M+',
    'landing.demo': 'Demo',
    'marketplace.head-title': 'Discover the potential power of unpublished artifacts',
    'marketplace.head-content': 'Marketplace is a blockchain-enabled platform for exploration and submission of unpublished life sciences data. Are you the author of a clinical study that did not get published? Monetize it and make it searchable to the global life science community. You can also search and license data provided by others to save time and cost of duplicating existing experiment.',
    'marketplace.keylist-title': 'Uncover hidden value from unpublished data',
    'marketplace.keylist-1': 'Buy and sell unpublished or failed experiments rather than re-running them',
    'marketplace.keylist-2': 'Utilize AI that surfaces data stuck deep in data archives',
    'marketplace.keylist-3': 'Track all transactions over a secure Blockchain ',
    'marketplace.unpublished-experiments-title': 'Unpublished experiments',
    'marketplace.unpublished-experiments-content': 'Discover the potential power of unpublished clinical and medical research work done by scientists, doctorate and postdoctorate contributors, medical research organizations and institutes. Uncover hidden insights from unpublished proprietary data sets.',
    'marketplace.mobilizeyourdata-title': 'Mobilize your data',
    'marketplace.mobilizeyourdata-content': 'Whether you are a research or diagnostic laboratory, an academic institute or a biobank, incentivize your data such as laboratory work, experimental studies, pharmacological studies or patient data by auto-generating a brochure and making it searchable internally or internationally.',
    'marketplace.fast-publication-title': 'Fast publication',
    'marketplace.fast-publication-content': 'Get your work published in Marketplace by answering just a few questions and submitting your previously unpublished work instantly. General publication process takes more than a year while in Marketplace it is a real-time submission and publication.',
    'marketplace.right-value-for-work-title': 'Right value for work',
    'marketplace.right-value-for-work-content': 'Get the right value for your research. Our AI helps you calculate the best price for your hard work using factors, such as instruments used or time consumed.',
    'marketplace.data-security-and-ownership-title': 'Data security and ownership',
    'marketplace.data-security-and-ownership-content': 'Monitor authorship of your unpublished artifacts on a fully trackable, blockchain-enabled platform. The blockchain proprietary technology allows for a secure, decentralized owner structure.',
    'marketplace.sub-footer-text': 'Enable accurate, informed decision-making with <div>Ontosight<sup>®</sup> Marketplace!</div>',
    'explore.build-custom-networks-title': 'Build custom networks',
    'explore.build-custom-networks-content': 'Generate real-time visualization of biological networks around your search query. Rank associations to prioritize assets based on their commonality/association to filter out noise or remove obvious facts. Use any combination of filters to narrow down a disease area, druggability of proteins, pharmaceutical capacity or chemicals and more. Predict potential drugs, or targets for diseases of interest, based on clinical data and network modularity.',
    'explore.granular-deep-dives-title': 'Granular deep-dives',
    'explore.granular-deep-dives-content': 'Validate the veracity of information by selecting connections between concepts. Evidence-based network research replaces the traditional black box models to solve real-life problems in strategic drug discovery.',
  },
  de: {
    lastUpdated: '2019-12-16T09:10:54.189Z',
    version: '1.1',
    'landing.head-title': 'KI-gestützte Intelligenz für die Medikamentenentwicklung',
    'landing.head-content': 'bietet einen umfassenden Ansatz für die Forschung und Entdeckung von Life Science über KI und unsere selbstlernende Ontologie. User erhalten in Echtzeit relevante Erkenntnisse aus Modulen und Dashboards, die helfen, Prozesse zu beschleunigen und so die Kosten während des gesamten Medikamentenentwicklungszyklus zu senken.',
    'landing.our-solutions-title': 'Unsere Lösungen',
    'landing.our-solutions-title-text': 'Umfasst Module und Dashboards, um Prozesse von der präklinischen und klinischen bis hin zur regulatorischen und kommerziellen Phase zu beschleunigen.',
    'landing.discover-title': 'Genaue Erkenntnisse aus dem größten Datenmeer',
    'landing.discover-content': 'Nutzen Sie die KI, um Informationen aus dem größten Datenmeer der Life Science zu analysieren, indem Sie unsere selbstlernende Ontologie für kontinuierliche, Echtzeit-Insights nutzen.',
    'landing.explore-title': 'Identifizieren Sie bekannte und unbekannte Verbindungen.',
    'landing.explore-content': 'Visualisieren Sie Zusammenhänge zwischen biologischen Entitäten mithilfe von Power Maps und Grafiken, um potenzielle Medikamente und Targets zu entdecken.',
    'landing.influence-title': 'Top KOLs: Entdeckung und Management',
    'landing.influence-content': 'Erhalten Sie Zugang zu Echtzeit-Discovery, Netzwerkanalyse und Management von Top- und Emerging Key Opinion Leadern (KOLs) und Digital Opinion Leadern (DOLs).',
    'landing.integrate-title': 'Lösen Sie das Daten "Chaos" auf',
    'landing.integrate-content': 'Transformieren, bereinigen und aggregieren Sie nicht ausreichend genutzte Unternehmensdaten im analysefertigen Zustand, um visuelle Erkenntnisse zu gewinnen.',
    'landing.dashboard-title': 'Relevante Updates auf einen Blick',
    'landing.dashboard-content': 'Erhalten Sie aktuelle, relevante Informationen und Erkenntnisse aus kontinuierlich gecrawlten, unterschiedlichen öffentlich zugänglichen Quellen an einem Ort. ',
    'landing.daas-title': 'Stärken Sie Ihr Datenanalyse-Team',
    'landing.daas-content': 'DaaS bietet Zugang zu unmittelbaren Life Science Daten und ermöglicht es Nicht-IT-Anwendern, geschäftskritische Erkenntnisse zu gewinnen.',
    'landing.our-data-ocean-title': 'UNSER DATEN OZEAN',
    'landing.our-data-ocean-content': 'Über 95% der webgescannten und öffentlich zugänglichen Life-Science-Daten aus verschiedenen Quellen - gecrawlt, aggregiert, analysiert und visualisiert für genaue Einblicke.',
    'dashboard.head-title': 'Fokussieren Sie sich auf die Entscheidungsfindung und nicht auf die Datensammlung',
    'dashboard.head-content': 'Dashboards ermöglichen es Teams, die in verschiedenen Phasen der Arzneimittelentwicklung involviert sind, mit KI-gestützten, maßgeschneiderten Erkenntnissen und Updates strategische Entscheidungen zu treffen. Beschleunigen Sie jeden Schritt, in dem die Maschine die zahlreichen Daten für Sie erhebt. So erhalten Sie tiefere Einblicke für bestimmte Ziele. Mit unseren einzigartigen, intuitiven Dashboards erhalten Sie Echtzeit-Updates zu Patenten, Wettbewerbsinformationen, Behandlungsrichtlinien, regulatorischen Anforderungen und Medical Affairs. Warum überall suchen, wenn es ein Dashboard gibt, das auf Ihre speziellen Bedürfnisse zugeschnitten ist?',
    'dashboard.highlights-title': 'Mit kontinuierlichen KI-gestützten Erkenntnissen sind Sie allen anderen voraus',
    'dashboard.highlights-1': 'Dashboards für alle Geschäftsfunktionen wie Medical Affairs, Regulatory, Competitive Intelligence, R&D, Clinical, Commercial, Business Development oder Management',
    'dashboard.highlights-2': 'Signifikante Zeitersparnis und Reduzierung alltäglicher wiederkehrender Aufgaben durch KI-gestützte Aggregation und intuitive Visualisierung von Erkenntnissen, aktualisiert aus über 95% der Datensätze von Public Web, Unternehmen und Drittanbietern',
    'dashboard.highlights-3': 'Rechtzeitige Updates und Echtzeit-Benachrichtigungen, um sicherzustellen, dass Sie eine wichtige Geschäftsentscheidung nicht verpassen',
    'dashboard.medical-affairs-title': 'Medical Affairs',
    'dashboard.medical-affairs-content': 'Erhalten Sie relevante Einblicke in klinische Studien, Richtlinien und Label Revisions zu Indikationen und Arzneimitteln von Interesse mit KI-basierten Dashboards, die Daten von über 100 Studienregistern, relevanten Aufsichtsbehörden und wissenschaftlichen Gesellschaften auf der ganzen Welt nutzen. Beschleunigen Sie Medical Affairs und unterstützen Sie diese Abteilung mit Echtzeit-Updates für fundierte und zeitnahe Entscheidungen.',
    'dashboard.regulatory-updates-title': 'Regulatory Updates',
    'dashboard.regulatory-updates-content': 'Bleiben Sie auf dem Laufenden mit den neuesten regulatorischen Updates, Label Revisions, Krankheits- und Medikamentenrichtlinien, Warnmeldungen und dem Medikamenteneinführungsstatus von Zulassungsbehörden weltweit, einschließlich FDA, EMA, TGA, Health Canada, Medsafe, CHMP, PRAC, etc. Erhalten Sie in Echtzeit Einblicke in die Zulassungsanträge von Medikamenten von Interesse bei den wichtigsten Zulassungsbehörden.',
    'dashboard.sentiment-watch-title': 'Sentiment Watch',
    'dashboard.sentiment-watch-content': 'Bessere Positionierung von Medikamenten durch eine schnelle Analyse des Sentiments von Patienten und Ärzten in digitalen und wissenschaftlichen Medien. Verfolgen Sie den Share of Voice über spezifische Arzneimittel und Indikationen hinweg und bleiben Sie auf dem Laufenden über Echtzeit-Buzz von Sponsoren, Stimmungsdynamik, aktuelle und aufkommende Themen. Identifizieren Sie die besten Kanäle und leiten Sie gezielte Kommunikationsstrategien für die größtmögliche Wirkung ab.',
    'dashboard.guideline-watch-title': 'Guideline Watch',
    'dashboard.guideline-watch-content': 'Gewinnen Sie Einblicke in die Behandlungsrichtlinien der wichtigsten Gesellschaften weltweit für Ihre Indikation von Interesse. Vergleichen Sie Richtlinien aus verschiedenen Gesellschaften, analysieren Sie die neuesten Trends und überwachen Sie Änderungen in Echtzeit mit dem KI-basierten, intuitiven Dashboard. Identifizieren Sie die besten Autoren der Leitlinien und erhalten Sie indikationsspezifische Nachrichten, die Ihnen beim Erfolg Ihres Medikaments helfen.',
    'dashboard.competitive-landscape-title': 'Competitive Landscape',
    'dashboard.competitive-landscape-content': 'Gewinnen Sie Einblicke in die Behandlungsrichtlinien der wichtigsten Fachgesellschaften weltweit, die für Ihre Indikation von Interesse sind. Vergleichen Sie Richtlinien aus verschiedenen Gesellschaften, analysieren Sie die neuesten Trends und überwachen Sie Änderungen in Echtzeit mit dem KI-basierten, intuitiven Dashboard. Identifizieren Sie die besten Autoren der Leitlinien und erhalten Sie indikationsspezifische Nachrichten, die Ihnen beim Erfolg Ihres Medikaments helfen.',
    'dashboard.my-dashboard-title': 'My Dashboard',
    'dashboard.my-dashboard-content': 'Noch nicht das Richtige gefunden? Erstellen Sie Ihr eigenes Dashboard, das Ihnen maßgeschneiderte Einblicke in die für Sie interessanten Themen bietet. Wählen Sie Datenpunkte aus und generieren Sie Widgets, um die Daten mit einem Klick zu analysieren, was durch eine intuitive Visualisierung viel Zeit spart. Lassen Sie sich mit Echtzeit-Updates informieren und treffen Sie Entscheidungen anhand der relevantesten, punktgenauen und neuesten Informationen.',
    'discover.head-title': 'Beschleunigen Sie Ihre Forschung um das 8-fache',
    'discover.head-content': ' Discover ist eine vollautomatische Entdeckungsmaschine, die mit Hilfe von Künstlicher Intelligenz (KI) Ihren Fokus auf Daten mit der höchsten Relevanz lenkt. Greifen Sie auf die weltweit umfassendste Datenbank für wissenschaftliche Beiträge zu. 1.3 Mio. Masterarbeiten, 5 Mio. Stipendien, 3.3 Mio. Kongressartikel, 35 Mio. Publikationen, 580T Studien, 3.7 Mio. Nachrichten und Pressemitteilungen und vieles mehr wartet auf Sie. Warum monatelang warten, wenn Sie in Echtzeit auf alles zugreifen können?',
    'discover.keylist-title': 'Automatisierung von 80% der Datenintelligenz',
    'discover.keylist-1': 'KI-gestützte Discovery-Engine, die dazu beiträgt, anpassbare Ergebnisse und intuitive Grafiken zu generieren, indem sie bis zu 95% des Webs scannt, um notwendige Informationen zu erhalten.',
    'discover.keylist-2': 'Kuratiert mit dem Life Sciences Language Processing™ und der Life-Science-Ontologie von über 30 Mio biomedizinischen Begriffen',
    'discover.keylist-3': '2x mehr Abdeckung als clinicaltrial.gov und 12% mehr als Pubmed',
    'discover.concept-based-search-title': 'Konzeptbasierte Suche',
    'discover.concept-based-search-content': 'Generieren Sie Ergebnisse, die für Sie relevanter sind als die Resultate herkömmlicher, mehrdeutiger Suchmaschinen. Die KI in Kombination mit der Ontologie der Biowissenschaften versteht Ihren Suchbegriff, disambiguiert Entitäten und schlägt Konzepte vor. Schränken Sie Ihren Suchbereich mit zahlreichen Filtermöglichkeiten wie Zeit-, Quell- und benutzerdefinierten Gewichtungen ein, um relevante Dokumente auszuwählen, zu sortieren und zu klassifizieren.',
    'discover.real-time-data-update-title': 'Datenaktualisierung in Echtzeit ',
    'discover.real-time-data-update-content': 'Generieren Sie tagesaktuelle Erkenntnisse aus relevanten klinischen und medizinischen Daten mit Hilfe unserer KI-Technologie. Unser Life-Science-Datenspeicher wird kontinuierlich in nahezu Echtzeit aktualisiert, um fundierte Entscheidungen auf der Grundlage jüngst veröffentlichter Daten zu ermöglichen.',
    'discover.custom-newsletters-title': 'Kundenspezifischer Newsletter',
    'discover.custom-newsletters-content': 'Bleiben Sie up to date was in Ihrem Forschungsgebiet passiert. Richten Sie sich Research-Cluster ein und erhalten Sie Benachrichtigungen mit Echtzeit-Updates zu Literatur, Stipendien und Nachrichten. Richten Sie Lesezeichen ein und nutzen Sie die Zitat-, Freigabe- und Teamzusammenarbeits-Funktionen in Ihrem eigenem personalisierbaren Arbeitsbereich.',
    'discover.intuitive-visualization-title': 'Intuitive Visualisierung',
    'discover.intuitive-visualization-content': 'Lesen Sie nicht durch tausende von Ergebnissen, sondern erlangen Sie Erkenntnisse aus intuitiven Grafiken. Nutzen Sie die Analyseansicht, um Ihre Suche visuell zu verstehen, und werfen Sie einen kurzen Blick auf Top-Trends, Autoren und Zugehörigkeiten. Erkunden Sie Konzepte (Indikationen, Interventionen oder Targets) im Zusammenhang mit Ihrer Suchanfrage über intuitive Word Clouds.',
    'discover.detailed-deep-dives-title': 'Detaillierte Vertiefungen',
    'discover.detailed-deep-dives-content': 'Verstehen Sie den Mechanismus eines Medikaments oder potenzielle Indikationen und laufende Studien mit detaillierten Informationen und ontologischen Zusammenhängen oder erforschen Sie Gen-Ontologie, Phänotypen und synthetische Letalität. Automatisch werden Kennzeichen für Gen-, Protein-, Medikamenten- und Forschungseinheiten aus der Suchanfrage gesetzt, so dass Sie detaillierte Informationen und Ontologie-Verbindungen finden können. ',
    'explore.head-title': 'Visualisieren Sie Entitäten und entdecken Sie neue Verbindung ',
    'explore.head-content': 'Explore ist eine KI-basierte Maschine zur Erforschung und Identifizierung offensichtlicher und weniger offensichtlicher Zusammenhänge zwischen Krankheiten, Pfaden, Proteinen/Targets und Medikamenten. Visualisieren Sie ein umfassendes Netzwerk von biologischem Wissen, um Krankheitsverläufe besser zu verstehen und neuartige Wirkmechanismen (MoA) aufzudecken. Oder erkunden Sie Wege zur Zulassungserweiterung von Arzneimitteln oder Kombinationstherapien. Warum sollte man riskieren, eine wichtige Verbindung zu verpassen, wenn sie zum nächsten Blockbuster führen kann!',
    'explore.keylist-title': 'Größter Life Sciences Research Graph',
    'explore.keylist-1': 'Starke und schwache Verbindungen zwischen 300T Krankheiten, 6,5T Pfaden, 500T Proteinen und 2 Mio.+ Medikamenten und chemischen Verbindungen',
    'explore.keylist-2': 'Netzwerkgraphen stellen Assoziationen bis zum zweiten Grad dar, um eine Krankheit mit einem Medikament basierend auf dem Label oder einer indirekten Verbindung über ein sekundäres relevantes Target zu verbinden',
    'explore.keylist-3': 'Beschleunigen und identifizieren Sie unerforschte Gebiete in den Bereichen Medikamenten-Erforschung, Medikamenten Repurposing, Kombinationstherapie und Target-Identifikation ',
    'explore.direct-indirect-connections-title': 'Direkte/indirekte Verbindungen',
    'explore.direct-indirect-connections-content': 'Finden Sie die fehlenden Zweige der Wirkstoffforschung, indem Sie zuvor verborgene Verbindungen aufdecken. Gewinnen Sie Erkenntnisse aus den verbundenen Netzwerken zwischen Medikamenten, Pfaden, Targets und Krankheiten. \nBeschleunigen Sie die Neupositionierung Ihrer Medikamente (Repositioning / Repurposing), indem Sie bekannte und unbekannte Assoziationen erforschen und identifizieren, um mögliche neue Interventionen und Pfade zu finden. \n',
    'explore.explore-networks-title': 'Netzwerke erforschen',
    'explore.explore-networks-content': 'Bilden Sie Netzwerke rund um ausgewählte biologische Entitäten und verstehen Sie Assoziationen zweiten Grades. Identifizieren Sie bekannte Pfade für eine Krankheit, zusammen mit Proteinen, die mit den Pfaden verbunden sind. Erforschen Sie eng verwandte Therapiegebiete und die entsprechenden Medikamente.',
    'explore.prioritize-assets-title': 'Priorisierung von Assets',
    'explore.prioritize-assets-content': 'Scoren und ranken Sie auf Basis Ihrer Gemeinsamkeiten Entitäten für die Target und Indication Prioritization von Assets in einer Tabellenansicht. Neudefinition der Möglichkeiten für die Neupositionierung von Medikamenten zur Behandlung anderer Indikationen durch die Identifizierung eines gemeinsamen Pfades zwischen zwei Indikationen.',
    'Data Not Availble': 'Validieren Sie die Richtigkeit von Informationen, indem Sie Verbindungen zwischen Konzepten auswählen. Evidenzbasierte Netzwerkforschung ersetzt die traditionellen Black-Box-Modelle, um reale Probleme in der strategischen Wirkstofffindung zu lösen. ',
    'influence.head-title': 'Verfolgen Sie über 23 Millionen Wissenschaftler, Forscher und KOLs weltweit',
    'influence.head-content': 'Influence ist eine intuitive KI-Engine zur Entdeckung von anwendungsspezifischen Forschern, Wissenschaftlern und Key Opinion Leadern (KOLs) für Ihre Arzneimittelentwicklungsprojekte. Identifizieren Sie Netzwerke von Top-, Second-Level- oder Emerging-KOLs und tauchen Sie tief in ihre aktuellsten umfassenden Profile und Verbindungen ein, um sinnvolle  Strategien für Engagement zu entwickeln. Priorisieren Sie KOLs, um genaue Anforderungen in allen Krankheitsbereichen, bei seltenen Krankheiten oder in Regionen zu erfüllen, und erhalten Sie Echtzeit-Updates über Ihre favorisierten KOLs mit relevanten, zielgerichteten Informationen, die für Sie wertvoll sind. Verlassen Sie sich nicht auf die subjektive Bewertung externer Parteien, wenn Sie objektiv diejenigen auswählen können, die "am besten zu Ihnen passen"!',
    'influence.highlights-title': 'Weltweite Heat-Map und 25 Datenfilter',
    'influence.highlights-1': 'Objektive und sofortige Liste der am besten geeigneten Forscher, Wissenschaftler oder wichtigen Meinungsbildnern auf Basis von KI',
    'influence.highlights-2': 'Globale Abdeckung über alle Therapiegebiete, Cross-Therapiebereiche und seltene Indikationen hinweg, mit umfassenden Profilen, die in Echtzeit aktualisiert werden',
    'influence.highlights-3': 'Weltweite Heat-Map- und indikationsspezifische Verbindungen, um den am besten geeigneten Sprecher/KOL für die Informationsverbreitung, Forscher für neue Bereiche oder Forscher für eine bevorstehende Studie zu identifizieren',
    'influence.kol-influencer-overview-title': 'KOL/Influencer Übersicht',
    'influence.kol-influencer-overview-content': 'Erhalten Sie Einblicke in die Aktivitäten und Verbindungen von KOLs. Entdecken Sie Referenten, Forscher, wissenschaftliche Experten, digitale Influencer, HTA-Experten und Patientenvertreter. Identifizieren Sie Top-KOLs, aufstrebende KOLs, Second-Tier-KOLs, digitale Meinungsführer für jeden Therapiebereich und jede Indikation. Wählen Sie Regionen aus, um Influencer, Sprecher und leitende Wissenschaftler mit dem größten Einfluss in diesen Regionen zu finden.',
    'influence.detailed-profiles-title': 'Detaillierte Profile',
    'influence.detailed-profiles-content': 'Generieren Sie tiefere Einblicke über die einzelnen KOLs und Influencer durch ganzheitliche Deep-Dives in ihre Forschungsprofile, die in Echtzeit aktualisiert werden. Studieren Sie die Worldcloud von Indikationen, Interventionen und Genen, basierend auf der Forschungsgeschichte und dem Netzwerk des KOL. Entwickeln Sie Engagement-Pläne auf der Grundlage von KOL und Influencer Verbindungen mit Patientenvertretungen, Krankenhäusern, Ärztegesellschaften, Richtlinien, HTA-Gremien und Aufsichtsbehörden durch einen ganzheitlichen Profilüberblick.',
    'influence.network-analysis-title': 'Netzwerkanalyse',
    'influence.network-analysis-content': 'Visualisieren Sie die Netzwerkverbindungen von KOLs und deren relativen Einfluss durch intuitive, automatisierte Power Maps, um den KOL mit maximaler Reichweite und Konnektivität zu finden. Identifizieren Sie Ihre Verbindungen, die Sie mit dem Ziel-KOL oder mit einigen KOLs in Ihrer Region und und der passenden Indikation haben. Erhalten Sie detaillierte Deep-Dives zur Überprüfung der Verbindung und zu relevanten Quellen wie klinischen Studien, Publikationen, Kongressen und anderen.',
    'influence.customized-ranking-title': 'Individuelles Ranking',
    'influence.customized-ranking-content': 'Vergleichen und priorisieren Sie KOLs/Einflussnehmer mit Hilfe einer anpassbaren Bewertungslogik, um die für Ihre Bedürfnisse relevantesten Personen (Referenten, Investigatoren, Regulierungs-/HTA-Influencer usw.) zu identifizieren. Spezifizieren Sie Ihre Ergebnisse auf der Grundlage der Teilnahme von KOL an klinischen Studien, Kongressen oder als Publikationsautoren, Richtlinienautoren usw. Verwenden Sie einen quantitativen Ansatz, um herauszufinden, wer an der Spitze steht, basierend auf Indikation, Geographie oder Datenbeständen wie Publikationen, Kongressen, klinischen Studien, Richtlinien und anderen Datenquellen.',
    'influence.events-calendar-title': 'Veranstaltungskalender',
    'influence.events-calendar-content': 'Erhalten Sie einen ganzheitlichen Überblick über Kongresse und Konferenzen aus aller Welt. Greifen Sie leicht auf die Agenda der Konferenz und die Teilnehmer/Teilnehmerinnen zu, um Ihre Pre-Konferenz, Konferenz und After-Konferenz Geschäftsstrategien und Aktivitäten zu planen. \nPrüfen Sie die für Ihre Therapiegebiet relevanten Veranstaltungen und nutzen Sie die Netzwerke und Verbindungen der wichtigsten Referenten für Ihr Medikament oder Ihre Forschung. Eine flexible Integration mit jedem gängigen System oder CRM, das Ihr Unternehmen nutzt, ist möglich.\n',
    'integrate.head-title': 'Bewältigen Sie die Herausforderungen der Datenfragmentierung und -zugänglichkeit',
    'integrate.head-content': 'Integrate vereint Informationen aus einer Vielzahl von Quellen über Konnektoren zu über 25 gängigen Datensoftwareprogrammen und -formaten. Künstliche Intelligenz wird verwendet, um Datensätze zu bereinigen, zu strukturieren, zu normalisieren, zu disparieren und in einem einzigen, kontinuierlich aktualisierten Analyse-Dashboard zu speichern. Ontosight® Integrate automatisiert die Datenverarbeitung, optimiert Workflows und Kundeninteraktionen in verschiedenen Geschäftsfunktionen und ermöglicht so eine schnellere Generierung von Erkenntnissen aus einer einheitlichen Datenquelle. Warum mehrere Datenquellen manuell analysieren und wochenlang auf Berichte warten, wenn Sie ganz einfach auf all diese verarbeiteten Informationen in einem einzigen Dashboard zugreifen können, das alle Ihre Daten vereint!',
    'integrate.highlights-title': 'Lassen Sie die KI Ihre Daten "zur Analyse zur Verfügung stellen"',
    'integrate.highlights-1': 'Erzielen Sie erhebliche Zeitersparnisse mit einer vollautomatischen KI-gesteuerten Datenerfassungs-, Aggregations-, Analyse- und Berichtserstellungsplattform, die über Dashboards oder APIs bereitgestellt wird',
    'integrate.highlights-2': 'Unterstützt derzeit die Integration in über 25 Datenquellen, wie z.B. ERP, CRM, Call Center, Gerätedaten, Erstattungs-/Patientenzugangssoftware sowie EHR- und Schadensfalldateien',
    'integrate.highlights-3': 'On-Demand-Analysen und -Visualisierungen, wie z.B. prädiktive Modellierung, szenariobasierte Prognosen und Echtzeit-Leistungsüberwachung, bieten aktuelle Erkenntnisse für wichtige Geschäftsentscheidungen',
    'integrate.data-amalgamation-title': 'Datenzusammenführung',
    'integrate.data-amalgamation-content': 'Zusammenführung interner und externer, strukturierter und unstrukturierter Daten mit leicht verfügbaren Plugins für mehr als 25 Datenquellen wie ERP, CRM, Call Center, Gerätedaten, Erstattungs-/Patientenzugangssoftware sowie EHR- und Schadensfalldateien, um eine nahtlose Analyse von KPIs zu ermöglichen. Fassen Sie verstreute Datenquellen aus verschiedenen Unternehmenslösungen und Softwaresystemen, Abteilungen und von Drittanbietern sowie Datensätze mit bisher inkompatiblen Datenformaten zusammen.',
    'integrate.reducing-data-disparity-title': 'Reduzierung von Daten-Disparitäten ',
    'integrate.reducing-data-disparity-content': 'Greifen Sie auf strukturierte Unternehmens- und Fremddaten zu, die traditionell in unterschiedlichen und unstrukturierten Datenformaten verfügbar sind. Die Daten werden automatisch bereinigt und strukturiert, indem KI-Technologien wie Computer Vision und Entity Normalization genutzt werden. Computer Vision wandelt Informationen aus PDFs, Bildern usw. in maschinenlesbaren Texte um, die dann über Algorithmen analysiert werden können, während Entity Normalization unternehmensspezifische Datensätze über verteilte Datenquellen hinweg verbindet.',
    'integrate.predictive-analytics-title': 'Prädiktive Analytik',
    'integrate.predictive-analytics-content': 'Nutzen Sie Prädiktive Analytik die mit Hilfe proprietärer und patentierter Künstlicher Intelligenz und maschineller Lernalgorithmen ermöglicht wird. Echtzeit-Updates aus internen und externen Quellen ermöglichen Ergebnisse auf Basis der neuesten Daten und bieten dem Management ein vollständiges und aktuelles Bild. Gewinnen Sie die Agilität und Flexibilität, um effektiver auf Marktveränderungen zu reagieren und effektiver  mit Ihren Kunden in Kontakt zu treten.\n',
    'integrate.enterprise-level-security-title': 'Sicherheit auf Unternehmensebene',
    'integrate.enterprise-level-security-content': 'Die Lösung wird innerhalb der Unternehmensinfrastruktur so eingesetzt, dass die Daten nie die Unternehmens-Firewall verlassen. Gewährleisten Sie umfassende Datensicherheit durch eine isolierte Umgebung, Festplattenverschlüsselung, Einhaltung gesetzlicher Vorschriften und rollenbasierten Zugriff. Nutzen Sie die Flexibilität bei der Definition und Verwaltung von Benutzern und Benutzerrollen. Beschleunigen Sie den arbeitsintensiven, zeitaufwändigen Prozess der Datenerhebung, der für alle internen Interessengruppen relevant ist. ',
    'daas.head-title': 'DaaS - RESTful APIs für Life-Science-Daten',
    'daas.head-content': 'DaaS ermöglicht Data Analytics Teams  im Life Science Bereich das, was ihnen am wichtigsten ist - strukturierte Life Science Daten! Erleben Sie die Leistungsfähigkeit und Flexibilität von Daten mit umfassenden APIs. Ausgestattet mit Innoplexus proprietären maschinellen Lernalgorithmen und der Life Science Ontologie, sind diese APIs der Traum eines jeden Life Science-Datenteams. Warum stundenlang Daten crawlen, strukturieren und aggregieren, wenn Sie unsere DaaS-APIs nutzen und sich auf die Entwicklung besserer Lösungen konzentrieren können!',
    'daas.highlights-title': 'Bilden und wachsen Sie mit leistungsstarken DaaS-APIs',
    'daas.highlights-1': 'Zugang zu Feeds aus über 50.000 Quellen die Thesen, Stipendien, Kongresse, Publikationen, klinische Studien, Nachrichten, Pressemitteilungen und vieles mehr abdecken',
    'daas.highlights-2': 'Saubere, strukturierte und vernetzte Daten, die in nahezu Echtzeit aktualisiert werden, mit der Flexibilität, kundenspezifische Anwendungen zu erstellen',
    'daas.highlights-3': 'Universelles JSON-Datenformat für die einfache Plug-and-Play-Integration mit fast jedem Visualisierungstool oder den Export in beliebige andere Dateiformate',
    'daas.comprehensive-data-title': 'Umfassende Daten',
    'daas.comprehensive-data-content': 'Unterstützen Sie Ihre Data Analytics Teams mit ganzheitlichen Life-Science-Daten, die strukturiert und validiert sind. DaaS-APIs liefern normalisierte, nicht-relationale, abgeleitete, aggregierte und cross-referenzierte Entity-Beziehungsdaten aus 1.3 Mio. wissenschaftlichen Arbeiten, 5 Mio. Grants, 3.3 Mio. Kongressartikeln, 35 Mio. Publikationen, 580T Studien, 3.7 Mio. Nachrichten und Pressemitteilungen und vieles mehr.',
    'daas.flexible-and-secure-title': 'Flexibel und sicher',
    'daas.flexible-and-secure-content': 'Unsere RESTful APIs bieten vollständige Flexibilität bei der Erstellung benutzerdefinierter Anwendungen mit Standard JSON-Output und sind hochgradig skalierbar für große Datenarchitekturen. Erleben Sie eine sichere Datenextraktion und verschlüsselte Kommunikation für den Einsatz in Unternehmen.',
    'daas.continuous-data-update-title': 'Integrierte KI- und Netzwerkanalyse',
    'daas.continuous-data-update-content': 'Erhalten Sie ontologiegesteuerte, konzeptbasierte Suchergebnisse für die On-Point-Analyse oder finden Sie aktions-/netzwerkbasierte Top-Forscher oder KOLs in Ihrem Interessengebiet. Unsere DaaS-APIs sind mit der selbstlernenden Life-Science-Ontologie von Innoplexus ausgestattet, die Suchanfragen versteht und die relevantesten Ergebnisse liefert. Dabei werden alle damit verbundenen Konzepte über verschiedene Assets hinweg berücksichtigt.',
    'daas.in-built-ai-and-network-analysis-title': 'In-built AI und Netzwerkanalyse',
    'daas.in-built-ai-and-network-analysis-content': 'Get Ontologie-driven, konzeptbasierte Suchergebnisse für On-Punktanalyse oder findet Aktivität / Netzwerk-basierte Top-Ermittler oder KOLs in Ihrem Interessengebiet. Unsere DaaS APIs sind mit Innoplexus Selbstlern Life Sciences Ontologie, dass Suchanfragen und Angebote die treffendsten Ergebnisse versteht, unter Berücksichtigung aller damit verbundenen Konzepte in verschiedenen Anlageklassen.',
    'button.request-demo': 'Demo anfordern',
    'link.contact': 'Kontakt',
    'link.privacy-policy': 'Datenschutz-Bestimmungen',
    'link.user-policy': 'Benutzerrichtlinien',
    'link.learn-more': 'Mehr erfahren',
    'text.our-office': 'Unsere buros',
    'text.we-are-hiring': 'Wir stellen ein!',
    'text.customer-support': 'Kundendienst',
    'text-follow-us': 'Folge uns',
    'text-write-to-us': 'Schreib uns',
    'academics.head-title': 'Erleben Sie die Life Sciences AI. Jetzt.',
    'academics.head-content': 'Wir sind verpflichtet, Hilfe Forscher weltweit in ihrem Bestreben, die Herausforderungen im Leben Sciences.We zu lösen, haben unsere Discover Academics Access Programm ins Leben gerufen, die den freien Zugang von Discover Academics Version für alle Forscher und Studenten, mit dem Unternehmen assoziiert jeder Universität weltweit bietet.',
    'academics.access-btn': 'Jetzt Zugreifen',
    'academics.access-btn-title': 'Verwenden Sie Ihre E-Mail-Universität für Ihr Interesse an einladen.',
    'academics.keylist-title': 'Schieben Sie Ihren Wettbewerbsvorteil mit relevanten Daten',
    'academics.keylist-1': 'Umfassendste Life Sciences Ontologie',
    'academics.keylist-2': 'Abdeckungen über 95% der öffentlich zugänglichen Daten Life Sciences',
    'academics.keylist-3': 'Konzept-basierten kontextuellen Suchergebnisse',
    'academics.keylist-4': 'Ganzheitliche und Tieftauch Funktionalitäten detailliert',
    'academics.research-clusters-title': 'Forschungscluster',
    'academics.research-clusters-content': 'Abfrage speichern um Kriterium als Forschungscluster suchen, die verwendet werden können, Fortschritte bei den ausgewählten Abfrageergebnissen zu verfolgen.',
    'academics.explore-top-concepts-title': 'Entdecken top concepts',
    'academics.explore-top-concepts-content': 'Entdeckung der Top-Trends in Indikationen, Top-Trends für Interventionen oder Ziele, Top-Verbindungen mit dem Suchbegriff zugeordnet und Top-Zeitschriften und Autoren aus jeder Anlageklasse',
    'academics.discover-top-kols-title': 'Entdecken Sie oben KOLs',
    'academics.discover-top-kols-content': 'Identifizieren Sie die wichtigsten Meinungsführer über eine interaktive Wortwolke. Tieftauchgang Ansicht Autorenprofil, Forschungstätigkeit grafische Darstellung und Details des ausgewählten Autors',
    'academics.custom-sort-title': 'Classify relevante Dokumente auf der Grundlage von Titel, Abstract, Namen des Autors, oder Volltext mit benutzerdefinierten Gewichtungen für jeden',
    'academics.clinical-development-title': 'Die klinische Entwicklung',
    'academics.clinical-development-content': 'Planen klinische Studien mit bis zu aktuellen Informationen über klinische Entwicklungspipeline von über Indikationen, Interventionen und Therapien',
    'academics.custom-filters-title': 'benutzerdefinierte Filter',
    'academics.custom-filters-content': 'Verbesserung der Relevanz der Suchergebnisse durch den Zeitbereich in den Jahren definiert, oder sortiert nach Aktualitäts Ergebnisse von jüngsten Jahren zu erhalten',
    'Header.solution-dropdown': 'lösungen',
    'Header.support-dropdown': 'Kundendienst',
    'Header.try-discover-academ-link': 'Testen Sie Discover für Academics',
    'link.careers': 'Karriere',
    'link.address-germany': 'Eschborn (HQ), Deutschland',
    'link.address-usa': 'Hoboken, USA',
    'link.address-india': 'Pune, Indien',
    'link.login': 'Login',
    'Contact.title': 'Kontaktiere uns',
    'Contact.address': 'Adresse',
    'Contact.email': 'Email',
    'Contact.customer.support': 'Kundendienst',
    'Highlights.title': 'Highlights',
    'discover.sub-footer-text': 'Ermöglichen Sie genaue, fundierte  <div>Entscheidungen mit Ontosight<sup>®</sup> Discover!</div>',
    'influence.sub-footer-text': 'Finden Sie mit Ontosight<sup>®</sup>  Influence die besten KOLs für die Arzneimittelentwicklung.',
    'explore.sub-footer-text': 'Unterstützen Sie die Forschung und Wirkstoffforschung mit',
    'dashboards.sub-footer-text': '<div>Ontosight<sup>®</sup> Dashboards.</div> Erhalten Sie die richtigen Informationen mit nur einem Klick.',
    'integrate.sub-footer-text': 'Optimieren Sie Ihre Führungsstrategie mit',
    'daas.sub-footer-text': 'Unterstützen Sie Ihre Datenanalyseteams mit unseren',
    'landing.publication-title': 'Veröffentlichungens',
    'landing.publication-value': '42 Mio+',
    'landing.clinical-trials-title': 'Klinische Studien',
    'landing.clinical-trials-value': '639 T+',
    'landing.congresses-title': 'Kongress-Artikel',
    'landing.congresses-value': '3.3 Mio+',
    'landing.guidelines-title': 'Behandlungsrichtlinien',
    'landing.guidelines-value': '35 T+',
    'landing.theses-title': 'Dissertationen',
    'landing.theses-value': '1.3 Mio+',
    'landing.patents-title': 'Patente',
    'landing.patents-value': '28.6 T+',
    'landing.grants-title': 'Grants',
    'landing.grants-value': '5 Mio+',
    'landing.authors-title': 'Forscher & Ärzte',
    'landing.authors-value': '23 Mio+',
    'landing.demo': 'Demo',
    'marketplace.head-title': 'Entdecken Sie das Potenzial unveröffentlichter Forschung',
    'marketplace.head-content': 'Marketplace ist eine blockchain-fähige Plattform zur Erforschung und Übermittlung von unveröffentlichten Life-Science-Daten. Sind Sie der Autor einer klinischen Studie, die nicht veröffentlicht wurde? Über diese Plattform können Sie diese ganz einfach monetarisieren und der globalen Life-Science-Community zur Verfügung stellen. Sie können aber auch Daten von anderen suchen und lizenzieren. Dies spart Zeit und Kosten. Greifen Sie auf bereits bestehende Experimente zu statt diese erneut durchzuführen.',
    'marketplace.keylist-title': 'Verborgene Werte in Forschungsdaten aufdecken',
    'marketplace.keylist-1': 'Kaufen und verkaufen Sie unveröffentlichte oder gescheiterte Experimente',
    'marketplace.keylist-2': 'Verwenden Sie KI, die Daten in Datenarchiven identifiziert und aufbereitet\n',
    'marketplace.keylist-3': 'Verfolgen Sie alle gettätigten Transaktionen mit Hilfe unser sicheren Blockchain',
    'marketplace.unpublished-experiments-title': 'unveroffentlichte Versuche',
    'marketplace.unpublished-experiments-content': 'Entdecken Sie das Potenzial unveröffentlichter klinischer und medizinischer Forschungsarbeiten von Wissenschaftlern, Doktoranden und Postdoktoranden, medizinischen Forschungseinrichtungen und Instituten. Decken Sie verborgene Erkenntnisse aus unveröffentlichten proprietären Datensätzen auf.',
    'marketplace.mobilizeyourdata-title': 'Daten mobilisieren',
    'marketplace.mobilizeyourdata-content': 'Ob Sie ein Forschungs- oder Diagnoselabor, ein akademisches Institut oder eine Biobank sind, veräußern Sie Ihre Daten wie Laborarbeit, experimentelle Studien, pharmakologische Studien oder Patientendaten, indem Sie automatisch eine Broschüre generieren und diese intern oder international veröffentlichen.',
    'marketplace.fast-publication-title': 'schnelle Veröffentlichung',
    'marketplace.fast-publication-content': 'Holen Sie sich Ihre Arbeit in Marktplatz veröffentlicht von nur ein paar Fragen zu beantworten und Ihre bisher nicht veröffentlichte Arbeit sofort einreichen. Allgemeiner Publikationsprozess dauert länger als ein Jahr, während in dem Markt es eine Echtzeit-Übermittlung und Veröffentlichung ist.',
    'marketplace.right-value-for-work-title': 'Arbeit angemessen bewerten',
    'marketplace.right-value-for-work-content': 'Lassen Sie Ihre Forschung von uns bewerten. Anhand von Faktoren, wie verwendete Instrumente oder Zeitaufwand, bemisst unsere KI den besten Preis für Ihre harte Arbeit.',
    'marketplace.data-security-and-ownership-title': 'Datensicherheit und Eigentum',
    'marketplace.data-security-and-ownership-content': 'Erhalten Sie die Autorenschaft für Ihre unveröffentlichten Artefakte auf einer vollständig sicheren, blockkettenfähigen Plattform. Die proprietäre Technologie der Blockchain ermöglicht eine sichere, dezentrale Eigentümerstruktur.',
    'marketplace.sub-footer-text': 'Enable accurate, informed decision-making with <div>Ontosight<sup>®</sup> Marketplace!</div>',
    'explore.build-custom-networks-title': 'Erstellen Sie benutzerdefinierte Netzwerke',
    'explore.build-custom-networks-content': 'Generieren Sie in Echtzeit eine Visualisierung biologischer Netzwerke um Ihre Suchanfrage herum. Ordnen Sie Assoziationen zur Priorisierung von Assets basierend auf ihren Gemeinsamkeiten / Zusammenhängen, um Rauschen herauszufiltern oder offensichtliche Fakten zu beseitigen. Verwenden Sie eine beliebige Kombination von Filtern, um ein Krankheitsfeld, die Arzneimitteltauglichkeit von Proteinen, die pharmazeutische Kapazität oder Chemikalien und mehr einzugrenzen. Vorhersage potenzieller Medikamente oder Targets für interessante Krankheiten auf der Grundlage klinischer Daten und Netzwerkmodularität.',
    'explore.granular-deep-dives-title': 'Granuläre Tieftauchgänge',
    'explore.granular-deep-dives-content': 'Validieren Sie die Richtigkeit von Informationen, indem Sie Verbindungen zwischen Konzepten auswählen. Evidenzbasierte Netzwerkforschung ersetzt die traditionellen Black-Box-Modelle, um reale Probleme in der strategischen Wirkstofffindung zu lösen. ',
  },
  fr: {
    lastUpdated: '2019-12-16T09:10:54.189Z',
    version: '1.1',
    'landing.head-title': 'French Data Not Availble',
    'landing.head-content': 'French Data Not Availble',
    'landing.our-solutions-title': 'French Data Not Availble',
    'landing.our-solutions-title-text': 'French Data Not Availble',
    'landing.discover-title': 'French Data Not Availble',
    'landing.discover-content': 'French Data Not Availble',
    'landing.explore-title': 'French Data Not Availble',
    'landing.explore-content': 'French Data Not Availble',
    'landing.influence-title': 'French Data Not Availble',
    'landing.influence-content': 'French Data Not Availble',
    'landing.integrate-title': 'French Data Not Availble',
    'landing.integrate-content': 'French Data Not Availble',
    'landing.dashboard-title': 'French Data Not Availble',
    'landing.dashboard-content': 'French Data Not Availble',
    'landing.daas-title': 'French Data Not Availble',
    'landing.daas-content': 'French Data Not Availble',
    'landing.our-data-ocean-title': 'French Data Not Availble',
    'landing.our-data-ocean-content': 'French Data Not Availble',
    'dashboard.head-title': 'French Data Not Availble',
    'dashboard.head-content': 'French Data Not Availble',
    'dashboard.highlights-title': 'French Data Not Availble',
    'dashboard.highlights-1': 'French Data Not Availble',
    'dashboard.highlights-2': 'French Data Not Availble',
    'dashboard.highlights-3': 'French Data Not Availble',
    'dashboard.medical-affairs-title': 'French Data Not Availble',
    'dashboard.medical-affairs-content': 'French Data Not Availble',
    'dashboard.regulatory-updates-title': 'French Data Not Availble',
    'dashboard.regulatory-updates-content': 'French Data Not Availble',
    'dashboard.sentiment-watch-title': 'French Data Not Availble',
    'dashboard.sentiment-watch-content': 'French Data Not Availble',
    'dashboard.guideline-watch-title': 'French Data Not Availble',
    'dashboard.guideline-watch-content': 'French Data Not Availble',
    'dashboard.competitive-landscape-title': 'French Data Not Availble',
    'dashboard.competitive-landscape-content': 'French Data Not Availble',
    'dashboard.my-dashboard-title': 'French Data Not Availble',
    'dashboard.my-dashboard-content': 'French Data Not Availble',
    'discover.head-title': 'French Data Not Availble',
    'discover.head-content': 'French Data Not Availble',
    'discover.keylist-title': 'French Data Not Availble',
    'discover.keylist-1': 'French Data Not Availble',
    'discover.keylist-2': 'French Data Not Availble',
    'discover.keylist-3': 'French Data Not Availble',
    'discover.concept-based-search-title': 'French Data Not Availble',
    'discover.concept-based-search-content': 'French Data Not Availble',
    'discover.real-time-data-update-title': 'French Data Not Availble',
    'discover.real-time-data-update-content': 'French Data Not Availble',
    'discover.custom-newsletters-title': 'French Data Not Availble',
    'discover.custom-newsletters-content': 'French Data Not Availble',
    'discover.intuitive-visualization-title': 'French Data Not Availble',
    'discover.intuitive-visualization-content': 'French Data Not Availble',
    'discover.detailed-deep-dives-title': 'French Data Not Availble',
    'discover.detailed-deep-dives-content': 'French Data Not Availble',
    'explore.head-title': 'French Data Not Availble',
    'explore.head-content': 'French Data Not Availble',
    'explore.keylist-title': 'French Data Not Availble',
    'explore.keylist-1': 'French Data Not Availble',
    'explore.keylist-2': 'French Data Not Availble',
    'explore.keylist-3': 'French Data Not Availble',
    'explore.direct-indirect-connections-title': 'French Data Not Availble',
    'explore.direct-indirect-connections-content': 'French Data Not Availble',
    'explore.explore-networks-title': 'French Data Not Availble',
    'explore.explore-networks-content': 'French Data Not Availble',
    'explore.prioritize-assets-title': 'French Data Not Availble',
    'explore.prioritize-assets-content': 'French Data Not Availble',
    'Data Not Availble': 'French Data Not Availble',
    'influence.head-title': 'French Data Not Availble',
    'influence.head-content': 'French Data Not Availble',
    'influence.highlights-title': 'French Data Not Availble',
    'influence.highlights-1': 'French Data Not Availble',
    'influence.highlights-2': 'French Data Not Availble',
    'influence.highlights-3': 'French Data Not Availble',
    'influence.kol-influencer-overview-title': 'French Data Not Availble',
    'influence.kol-influencer-overview-content': 'French Data Not Availble',
    'influence.detailed-profiles-title': 'French Data Not Availble',
    'influence.detailed-profiles-content': 'French Data Not Availble',
    'influence.network-analysis-title': 'French Data Not Availble',
    'influence.network-analysis-content': 'French Data Not Availble',
    'influence.customized-ranking-title': 'French Data Not Availble',
    'influence.customized-ranking-content': 'French Data Not Availble',
    'influence.events-calendar-title': 'French Data Not Availble',
    'influence.events-calendar-content': 'French Data Not Availble',
    'integrate.head-title': 'French Data Not Availble',
    'integrate.head-content': 'French Data Not Availble',
    'integrate.highlights-title': 'French Data Not Availble',
    'integrate.highlights-1': 'French Data Not Availble',
    'integrate.highlights-2': 'French Data Not Availble',
    'integrate.highlights-3': 'French Data Not Availble',
    'integrate.data-amalgamation-title': 'French Data Not Availble',
    'integrate.data-amalgamation-content': 'French Data Not Availble',
    'integrate.reducing-data-disparity-title': 'French Data Not Availble',
    'integrate.reducing-data-disparity-content': 'French Data Not Availble',
    'integrate.predictive-analytics-title': 'French Data Not Availble',
    'integrate.predictive-analytics-content': 'French Data Not Availble',
    'integrate.enterprise-level-security-title': 'French Data Not Availble',
    'integrate.enterprise-level-security-content': 'French Data Not Availble',
    'daas.head-title': 'French Data Not Availble',
    'daas.head-content': 'French Data Not Availble',
    'daas.highlights-title': 'French Data Not Availble',
    'daas.highlights-1': 'French Data Not Availble',
    'daas.highlights-2': 'French Data Not Availble',
    'daas.highlights-3': 'French Data Not Availble',
    'daas.comprehensive-data-title': 'French Data Not Availble',
    'daas.comprehensive-data-content': 'French Data Not Availble',
    'daas.flexible-and-secure-title': 'French Data Not Availble',
    'daas.flexible-and-secure-content': 'French Data Not Availble',
    'daas.continuous-data-update-title': 'French Data Not Availble',
    'daas.continuous-data-update-content': 'French Data Not Availble',
    'daas.in-built-ai-and-network-analysis-title': 'French Data Not Availble',
    'daas.in-built-ai-and-network-analysis-content': 'French Data Not Availble',
    'button.request-demo': 'demande de démonstration',
    'link.contact': 'Contact',
    'link.privacy-policy': 'Politique de confidentialité',
    'link.user-policy': "Politique de l'utilisateur",
    'link.learn-more': 'Apprendre encore plus',
    'text.our-office': 'nos bureaux',
    'text.we-are-hiring': 'Nous recrutons!',
    'text.customer-support': 'Service client',
    'text-follow-us': 'Suivez nous',
    'text-write-to-us': 'Écrivez-nous',
    'academics.head-title': "L'expérience de la sciences de la vie AI. À présent.",
    'academics.head-content': 'French Data Not Availble',
    'academics.access-btn': 'Sign Up For Free',
    'academics.access-btn-title': 'French Data Not Availble',
    'academics.keylist-title': 'French Data Not Availble',
    'academics.keylist-1': 'French Data Not Availble',
    'academics.keylist-2': 'French Data Not Availble',
    'academics.keylist-3': 'French Data Not Availble',
    'academics.keylist-4': 'French Data Not Availble',
    'academics.research-clusters-title': 'French Data Not Availble',
    'academics.research-clusters-content': 'French Data Not Availble',
    'academics.explore-top-concepts-title': 'French Data Not Availble',
    'academics.explore-top-concepts-content': 'French Data Not Availble',
    'academics.discover-top-kols-title': 'French Data Not Availble',
    'academics.discover-top-kols-content': 'French Data Not Availble',
    'academics.custom-sort-title': 'French Data Not Availble',
    'academics.clinical-development-title': 'French Data Not Availble',
    'academics.clinical-development-content': 'French Data Not Availble',
    'academics.custom-filters-title': 'French Data Not Availble',
    'academics.custom-filters-content': 'French Data Not Availble',
    'Header.solution-dropdown': 'Solution',
    'Header.support-dropdown': 'Service client',
    'Header.try-discover-academ-link': 'Access Ontosight Lite',
    'link.careers': 'Careers',
    'link.address-germany': 'Eschborn (HQ), Allemagne',
    'link.address-usa': 'Hoboken, États-Unis',
    'link.address-india': 'Pune, Inde',
    'link.login': "S'identifier",
    'Contact.title': 'Contactez nous',
    'Contact.address': 'Adresse',
    'Contact.email': 'Email',
    'Contact.customer.support': 'Service client',
    'Highlights.title': 'Points forts',
    'discover.sub-footer-text': 'Activer précis, la prise de décision éclairée avec <div> Ontosight <sup> ® </ sup> Découvrez! </ Div>',
    'influence.sub-footer-text': 'Trouvez haut KOL pour le développement de médicaments avec <div> Ontosight <sup> ® </ sup> Influence. </ Div>',
    'explore.sub-footer-text': 'Activer précis, informé de la prise de décision',
    'dashboards.sub-footer-text': 'Obtenez les bonnes informations en un clic avec notre <div> Ontosight <sup> ® </ sup> Tableau de bord. </ Div>',
    'integrate.sub-footer-text': 'Optimiser votre stratégie de leadership',
    'daas.sub-footer-text': 'Empower les équipes de vos données avec notre',
    'landing.publication-title': 'publications',
    'landing.publication-value': '42 M +',
    'landing.clinical-trials-title': 'Essais cliniques',
    'landing.clinical-trials-value': '639 K +',
    'landing.congresses-title': 'congrès',
    'landing.congresses-value': '3.3 M +',
    'landing.guidelines-title': 'Des lignes directrices',
    'landing.guidelines-value': '68 K +',
    'landing.theses-title': 'thèses',
    'landing.theses-value': '1.3 M +',
    'landing.patents-title': 'brevets',
    'landing.patents-value': '28.6 K +',
    'landing.grants-title': 'subventions',
    'landing.grants-value': '5 M +',
    'landing.authors-title': 'Auteurs',
    'landing.authors-value': '23 M +',
    'landing.demo': 'Démo',
    'marketplace.head-title': "Découvrez la puissance potentielle d'artefacts inédits",
    'marketplace.head-content': "Marketplace est une plate-forme a permis blockchain-pour l'exploration et la présentation des données non publiées des sciences de la vie. Êtes-vous l'auteur d'une étude clinique qui n'a pas été publié? Monétiser et faire des recherches à la communauté mondiale des sciences de la vie. Vous pouvez également rechercher des données de licence fournies par d'autres pour gagner du temps et le coût de duplication expérience existante.",
    'marketplace.keylist-title': 'Uncover valeur cachée des données non publiées',
    'marketplace.keylist-1': 'Acheter et vendre des expériences inédites ou échoué plutôt que réexécuter les',
    'marketplace.keylist-2': "AI que les données d'utiliser des surfaces collées profondément dans les archives de données",
    'marketplace.keylist-3': 'Suivre toutes les transactions sur un Blockchain sécurisé',
    'marketplace.unpublished-experiments-title': 'expériences inédites',
    'marketplace.unpublished-experiments-content': "Découvrez la puissance potentielle des travaux de recherche clinique et médicale inédite fait par des scientifiques, doctorat et postdoctorat collaborateurs, organismes de recherche médicale et des instituts. Dévoilez des informations cachées à partir d'ensembles de données exclusives non publiées.",
    'marketplace.mobilizeyourdata-title': 'Mobilisez vos données',
    'marketplace.mobilizeyourdata-content': "Que vous soyez une recherche ou un laboratoire de diagnostic, un institut universitaire ou une biobanque, vos données telles encourager le travail de laboratoire, des études expérimentales, des études pharmacologiques ou des données du patient par auto-génération d'une brochure et d'en faire des recherches à l'interne ou à l'étranger.",
    'marketplace.fast-publication-title': 'publication rapide',
    'marketplace.fast-publication-content': "Obtenez votre travail publié dans Marketplace en répondant à quelques questions et soumettre votre travail non publié précédemment instantanément. processus de publication générale prend plus d'un an alors que dans ce marché est une soumission en temps réel et la publication.",
    'marketplace.right-value-for-work-title': 'bonne valeur pour le travail',
    'marketplace.right-value-for-work-content': 'Obtenez la bonne valeur pour votre recherche. Notre AI vous aide à calculer le meilleur prix pour votre travail en utilisant des facteurs tels que les instruments utilisés ou le temps.',
    'marketplace.data-security-and-ownership-title': 'la sécurité et la propriété des données',
    'marketplace.data-security-and-ownership-content': 'auteur de moniteur de vos artefacts inédits sur une plate-forme, a permis blockchain-entièrement traçable. La technologie exclusive blockchain permet une sécurité, la structure propriétaire décentralisée.',
    'marketplace.sub-footer-text': 'Enable accurate, informed decision-making with <div>Ontosight<sup>®</sup> Marketplace!</div>',
    'explore.build-custom-networks-title': 'French Data Not Availble',
    'explore.build-custom-networks-content': 'French Data Not Availble',
    'explore.granular-deep-dives-title': 'French Data Not Availble',
    'explore.granular-deep-dives-content': 'French Data Not Availble',
  },
}
