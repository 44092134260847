import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ToolTip from 'react-portal-tooltip'
import { style, generateRandomString } from '../../utils'

class PanelHeading extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isTooltipActive: false,
      id: generateRandomString()
    }
  }
  render() {
    return (
      <div className='panel-heading has-space-btwn'>
        <div>
          <h3 className='title'>
            <span className='ff-medium'>{this.props.title}</span>
            <span>{this.props.titleText}</span>
          </h3>
        </div>
        {this.props.tooltip && <div onBlur={() => null} id={this.state.id} onMouseEnter={() => this.setState({ isTooltipActive: true })} onMouseLeave={() => this.setState({ isTooltipActive: false })} className='info-icon' />}
        <ToolTip tooltipTimeout={0} arrow='center' style={style} active={this.state.isTooltipActive} position='left' parent={`#${this.state.id}`}>
          {this.props.children}
        </ToolTip>
      </div>
    )
  }
}

PanelHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  titleText: PropTypes.string,
  tooltip: PropTypes.bool,
}

PanelHeading.defaultProps = {
  children: null,
  title: '',
  titleText: '',
  tooltip: false
}

export default PanelHeading
