import { AppContainer } from 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-hot-loader/patch'
import { init } from '@sentry/browser';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import RootComponent from './RootComponent'
import { BrowserSupport, CookieSupport, ErrorBoundary } from '../common/components'
import '../assets/styles/index.scss'

addLocaleData([...en, ...de, ...fr]);

init({
  dsn: process.env.sentryUrl
});

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <BrowserSupport>
          <CookieSupport>
            <Component />
          </CookieSupport>
        </BrowserSupport>
      </ErrorBoundary>
    </AppContainer>
    , document.getElementById('app')
  )
}

render(RootComponent)

if (module.hot) {
  module
    .hot
    .accept('./RootComponent', () => {
      render(RootComponent)
    })
}
