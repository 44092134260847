import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loginAction } from './logic'
import { removeCookies, getCookie } from '../../utils'
import { modalCloseAction } from '../Modal/logic'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalText: ''
    }
    if (sso) {
      sso.config({
        base_url: process.env.sso.baseUrl,
        client_id: process.env.sso.clientId,
        window_type: 'iframe',
        response_type: 'code',
        divId: this.props.divId,
        lang: this.props.lang,
        cssUrl: `${process.env.APPS.app.redirectUrl}landingImages/${this.props.cssPath}`
      })
    }
  }
  componentDidMount () {
    const { alreadyLoggedIn, hashCode, code } = parse(this.props.location.search)
    if (sso) {
      if (hashCode) {
        const { email } = parse(this.props.location.search)
        const dim = {
          window_type: 'iframe',
          showImage: false,
          scope: process.env.sso.scope,
          notCancellable: false,
          response_type: 'code',
          divId: this.props.divId,
          hashCode,
          emailId: email || null,
          removeSession: true,
          lang: this.props.lang,
          cssUrl: `${process.env.APPS.app.redirectUrl}landingImages/${this.props.cssPath}`
        }
        if (parse(this.props.location.search).request_type === 'reset_password') {
          dim.cssUrl = `${process.env.APPS.app.redirectUrl}landingImages/sso-reset-password.css`
          sso.resetPassword(dim)
          if (this.props.divId === 'ssoLoginLite') {
            this.setState({
              modalText: 'Reset your Password!'
            })
          }
        } else {
          sso.registerUser(dim, (status) => {
            removeCookies()
            localStorage.clear()
            if (status.callback_url.split('code=')[1]) {
              this.props.loginAction({
                params: stringify({
                  code: status.callback_url.split('code=')[1],
                  app: this.props.app
                })
              })
            }
          })
        }
      } else if (alreadyLoggedIn === 'true') {
        removeCookies()
        sso.login({ scope: process.env.sso.scope, nosession: true, lang: this.props.lang }, (status) => {
          this.props.loginAction({
            params: stringify({
              code: status.code,
              app: this.props.app
            })
          })
        })
      } else if (code) {
        this.props.loginAction({
          params: stringify({
            code,
            app: this.props.app
          })
        })
      } else if (getCookie('accessToken')) {
        this.props.history.replace(`${process.env.redirectRoute}?${stringify({ ...parse(this.props.location.search) })}`)
      } else {
        const dimen = {
          window_type: 'iframe',
          showImage: false,
          scope: process.env.sso.scope,
          notCancellable: false,
          response_type: 'code',
          divId: this.props.divId,
          lang: this.props.lang
        }
        sso.login(dimen, (status) => {
          if (status.type && status.type === 'ssoLogin') {
            if (status.code) {
              if (this.props.divId === 'ssoLiteLogin') {
                this.props.loginAction({
                  params: stringify({
                    code: status.code,
                    app: this.props.app,
                    request_from: 'lite'
                  })
                })
              } else {
                this.props.loginAction({
                  params: stringify({
                    code: status.code,
                    app: this.props.app
                  })
                })
              }
            } else {
              this.props.modalCloseAction()
            }
          } else if (status.type && status.type === 'ssoRequestInvite') {
            if (status.data && status.data.is_tour_period && status.data.email) {
              this.props.loginAction({
                url: 'v0/auth/triallogin',
                params: stringify({
                  email: status.data.email,
                  app: this.props.app
                })
              })
            } else {
              this.props.modalCloseAction()
            }
          } else {
            this.props.modalCloseAction()
          }
        })
      }
    }
  }
  render () {
    const styles = {
      width: '100%',
      height: this.props.height,
      ...this.props.style
    }
    return (
      <div className={`${this.props.divId === 'ssoLoginLite' ? 'sign-in-up-buttons' : ''}`} >
        <div className={`${this.props.divId === 'ssoLoginLite' ? 'sign-up-button common-button active-button-lite' : ''}`}>{this.state.modalText}</div>
        <div id={this.props.divId} style={styles} />
      </div>
    )
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loginAction: PropTypes.func.isRequired,
  app: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lang: PropTypes.string,
  style: PropTypes.object,
  modalCloseAction: PropTypes.func.isRequired,
  divId: PropTypes.string.isRequired,
  cssPath: PropTypes.string.isRequired
}
Login.defaultProps = {
  app: process.env.apiAppName,
  height: 'calc(100vh - 60px)',
  lang: 'en',
  style: {}
}

const mapStateToProps = state => ({
  auth: state.auth,
  lang: state.language.lang,
})

export default withRouter(connect(mapStateToProps, { loginAction, modalCloseAction })(Login))
