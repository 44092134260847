import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import T from '../T'

class ReadMore extends Component {
  constructor (props) {
    super(props)
    this.state = {
      limit: this.props.limit ? this.props.limit : 50,
      read: true
    }
    this.onToggle = this.onToggle.bind(this)
  }
  onToggle (e) {
    e.stopPropagation()
    this.setState(prevState => ({
      read: !prevState.read
    }))
  }
  render () {
    if (this.props.children) {
      if (this.props.type === 'text') {
        if (this.props.children.length <= this.state.limit) {
          return <div className={`read-more ${this.props.className ? this.props.className : ''}`}>{ this.props.children }</div>
        }
        return <div className={`read-more ${this.props.className ? this.props.className : ''}`}> {this.state.read && this.props.children.substring ? `${this.props.children.substring(0, this.state.limit)}....` : this.props.children} <a role='presentation' className='handicon' onClick={this.onToggle}> {this.state.read ? <FormattedMessage id="Read more" defaultMessage="Read more" /> : <FormattedMessage id="Read less" defaultMessage="Read less" />}</a></div>
      } else if (Array.isArray(this.props.children) === false || (Array.isArray(this.props.children) && this.props.children.length <= this.state.limit)) {
        return (
          <div className={`read-more ${this.props.className ? this.props.className : ''}`}> { this.props.children } </div>
        )
      }
      return (
        <div className={`read-more ${this.props.className ? this.props.className : ''}`}> {this.state.read ? this.props.children.filter((d, i) => i < this.state.limit) : this.props.children} <span role='presentation' className='handicon' onClick={this.onToggle}> {this.state.read ? <FormattedMessage id="Read more" defaultMessage="Read more" /> : <FormattedMessage id="Read less" defaultMessage="Read less" />}</span></div>
      )
    }
    return <T data='' {...(this.props.message ? { message: this.props.message } : {})} />
  }
}

ReadMore.propTypes = {
  limit: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  message: PropTypes.string,
  type: PropTypes.string
}

ReadMore.defaultProps = {
  limit: 50,
  className: '',
  type: '',
  message: undefined
}
export default ReadMore
