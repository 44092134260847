import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ClickOutside } from 'reusable-react-components'
import { FormattedMessage, injectIntl } from 'react-intl';

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      inputText: '',
      searchOptions: props.searchOptions
    }
  }
  onChange(e, value = [], i = null) {
    e.stopPropagation()
    this.setState({
      open: false,
      inputText: ''
    }, () => {
      this.props.onChange(value, i)
    })
  }
  onTextChange(e, value) {
    this.setState({
      inputText: value === '' ? value : e.target.value
    }, () => {
      if (this.state.inputText.length > this.props.minLength) {
        if (this.props.localSearch) {
          this.setState({
            searchOptions: this.props.searchOptions.filter(d => d.toLowerCase().indexOf(this.state.inputText.toLowerCase()) > -1)
          })
        } else {
          this.props.onSearch(this.state.inputText)
        }
      }
    })
  }
  renderItems() {
    const {
      minLength, onSearch, options, loading
    } = this.props
    let { searchOptions } = this.props
    if (loading) {
      return (
        <div className='reusable-dropdown-no-data-found' >
          Loading ...
        </div>
      )
    }
    if (this.state.inputText.length <= minLength && options.length === 0 && onSearch) {
      return (
        <div className='reusable-dropdown-no-data-found' >
          {this.props.noDataSearchText}
        </div>
      )
    }
    if ((this.state.inputText.length > minLength) && onSearch) {
      searchOptions = this.props.localSearch ? this.state.searchOptions : searchOptions
      if (searchOptions.length === 0) {
        return (
          <div className='reusable-dropdown-no-data-found' >
            {this.props.noSearchDataText}
          </div>
        )
      }
      return searchOptions.map((item, i) => (
        <li key={i} className='reusable-dropdown-container-list-item' role='presentation' onClick={e => this.onChange(e, [item], i)} >{item}</li>
      ))
    }
    if (options.length === 0) {
      return (
        <div className='reusable-dropdown-no-data-found' >
          {this.props.noDataText}
        </div>
      )
    }
    return this.props.options.map((item, i) => {
      if (typeof item === 'object') {
        return (
          <li key={i} className='reusable-dropdown-container-list-item' role='presentation' onClick={e => this.onChange(e, [item], i)} ><FormattedMessage id={item.name || 'abc'} defaultMessage={item.name} /> {this.props.showTotal ? this.props.displayTotalKey ? `(${item[this.props.displayTotalKey] ? item[this.props.displayTotalKey] : 0})` : item.count ? `(${item.count})` : item.strength ? `(${item.strength})` : '(0)' : ''}</li>
        )
      }
      return (
        <li key={i} className='reusable-dropdown-container-list-item' role='presentation' onClick={e => this.onChange(e, [item], i)} ><FormattedMessage id={item || 'abc'} defaultMessage={item} /></li>
      )
    })
  }
  renderBox() {
    const { intl } = this.props
    if (this.state.open && this.props.onSearch) {
      return (
        <div className='reusable-dropdown-box-search' >
          <input className='reusable-dropdown-box-search-input' placeholder={intl.formatMessage({ id: this.props.placeholder, defaultMessage: this.props.placeholder })} type='text' value={this.state.inputText} onChange={e => this.onTextChange(e)} />
          {this.state.inputText.length > 0 ? <div role='presentation' onClick={e => this.onTextChange(e, '')} className='reusable-dropdown-box-clear'>&#10005;</div> : <div className='reusable-search-icon' />}
        </div>
      )
    }
    return (
      <button disabled={this.props.disabled} className={`reusable-dropdown-box-default ${this.props.disabled ? 'reusable-dropdown-box-disabled' : ''}`} onClick={() => this.setState({ open: !this.state.open })} >
        <span title={this.props.active && this.props.active.length ? intl.formatMessage({ id: this.props.active[0] || 'abc', defaultMessage: this.props.active[0] }) : intl.formatMessage({ id: this.props.title || 'abc', defaultMessage: this.props.title })} className={`text-elips ${this.props.additionalClass}`} >
          {this.props.active && this.props.active.length ? intl.formatMessage({ id: this.props.active[0] || 'abc', defaultMessage: this.props.active[0] }) : intl.formatMessage({ id: this.props.title || 'abc', defaultMessage: this.props.title })}
        </span>
        {this.props.active && this.props.active.length > 0 && this.props.clear ? <div role='presentation' onClick={e => this.onChange(e)} className='reusable-dropdown-box-clear'>&#10005;</div> : null}
      </button>
    )
  }
  render() {
    return (
      <div className='inline'>
        <ClickOutside onClickOutside={() => this.setState({ open: false })}>
          <div className={`reusable-dropdown ${this.props.additionalClass}`}>
            <div className='reusable-dropdown-box'>
              {this.renderBox()}
            </div>
            {this.state.open ? (
              <div className='reusable-dropdown-container scrollbar' >
                <ul className='reusable-dropdown-container-list' >
                  {this.renderItems()}
                </ul>
              </div>
            ) : null}
          </div>
        </ClickOutside>
      </div>
    )
  }
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])).isRequired,
  active: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  searchOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  minLength: PropTypes.number,
  noSearchDataText: PropTypes.string,
  noDataText: PropTypes.string,
  noDataSearchText: PropTypes.string,
  clear: PropTypes.bool,
  displayTotalKey: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalClass: PropTypes.string,
  showTotal: PropTypes.bool,
  localSearch: PropTypes.bool,
  intl: PropTypes.object.isRequired
}

Dropdown.defaultProps = {
  title: 'Select',
  open: false,
  active: [],
  placeholder: 'Search',
  searchOptions: [],
  disabled: false,
  onSearch: null,
  minLength: 0,
  noSearchDataText: 'No search data found',
  noDataText: 'No data found',
  noDataSearchText: 'Search for options',
  clear: false,
  displayTotalKey: '',
  loading: false,
  additionalClass: '',
  showTotal: true,
  localSearch: false
}

export default injectIntl(Dropdown)
