import { loginEpic, logoutEpic } from './Login/logic'
import { notificationEpic, notificationCountEpic } from './Notification/logic'
import { requestDemoEpic } from './RequestDemo/logic'
import { checkTokenEpic } from './CheckAccessToken/logic'
import { checkEmailEpic, requestAccessEpic, signUpEpic, signInEpic, requestEmailEpic, userExitsCheckEpic, forgetPasswordEpic } from './UnifiedHeader/logic'

export default {
  loginEpic,
  logoutEpic,
  notificationEpic,
  notificationCountEpic,
  requestDemoEpic,
  checkTokenEpic,
  checkEmailEpic,
  requestAccessEpic,
  signUpEpic,
  signInEpic,
  requestEmailEpic,
  userExitsCheckEpic,
  forgetPasswordEpic
}
