import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { FormattedMessage } from 'react-intl';

class CookieSupport extends Component {
  constructor() {
    super()
    this.cookieEnabled = navigator.cookieEnabled;
    this.state = {
      cookieAllowed: !!localStorage.getItem('cookie_allowed') || false
    }
    this.onDismiss = this.onDismiss.bind(this);
  }
  onDismiss() {
    localStorage.setItem('cookie_allowed', 1);
    this.setState({ cookieAllowed: true });
  }
  render() {
    if (!this.cookieEnabled) {
      return <div className='loader' ><FormattedMessage id="There seems to be an issue, please allow cookies and try again!!!" defaultMessage="There seems to be an issue, please allow cookies and try again!!!" /></div>
    }
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    return (
      <Fragment>
        {
          (!this.state.cookieAllowed) && query !== 'true' && hash !== 'true' &&
          <div className='cookie-notification container'>
            <div className='cookie-notification-text'><FormattedMessage id="By continuing to use our site, you agree to our cookie policy" defaultMessage="By continuing to use our site, you agree to our cookie policy" /> </div>
            <button className='cookie-notification-button' onClick={this.onDismiss}><FormattedMessage id="Dismiss" defaultMessage="Dismiss" /></button>
          </div>
        }
        {this.props.children}
      </Fragment>
    );
  }
}


CookieSupport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

CookieSupport.defaultProps = {
  children: null
}

export default CookieSupport
