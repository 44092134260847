import { authReducer } from './Login/logic'
import { modalReducer } from './Modal/logic'
import { statusReducer } from './Status/logic'
import { drawerReducer } from './Drawer/logic'
import { notificationReducer, notificationCountReducer } from './Notification/logic'
import { langReducer } from '../lang/logic'
import { requestDemoReducer } from './RequestDemo/logic'
import { checkTokenReducer } from './CheckAccessToken/logic'
import { checkEmailReducer, requestAcessReducer, signUpReducer, signInReducer, requestEmailReducer, userExitsCheckReducer, forgetPasswordReducer } from './UnifiedHeader/logic'
import { signUpFormOpenReducer } from '../../app/container/DiscoverAcademics/logic'

export default {
  auth: authReducer,
  modal: modalReducer,
  status: statusReducer,
  drawer: drawerReducer,
  notification: notificationReducer,
  count: notificationCountReducer,
  language: langReducer,
  requestDemo: requestDemoReducer,
  checkToken: checkTokenReducer,
  emailStatus: checkEmailReducer,
  accesStatus: requestAcessReducer,
  signUpStatus: signUpReducer,
  signInStatus: signInReducer,
  requestEmailStatus: requestEmailReducer,
  signUpFormOpen: signUpFormOpenReducer,
  userExitsCheck: userExitsCheckReducer,
  forgetPasswordStatus: forgetPasswordReducer
}
