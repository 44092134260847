import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { ClickOutside } from 'reusable-react-components'
import { CheckboxGroup } from '../'

const j = value => JSON.parse(JSON.stringify(value))
const sort = value => j(value).sort()

class MSDropdown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      options: this.props.options || [],
      active: this.props.active || [],
      selectAll: this.props.options && this.props.active ? this.props.options.length === this.props.active.length ? ['Deselect All'] : [] : [],
      term: '',
      open: this.props.open,
      searchOptions: this.props.searchOptions,
      error: false
    }
    this.onChange = this.onChange.bind(this)
    this.onSelectAll = this.onSelectAll.bind(this)
    this.onSearch = this.onSearch.bind(this)
  }
  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(nextProps.options) !== JSON.stringify(this.props.options) && nextProps.options) {
      this.setState({
        options: nextProps.options
      })
    }
    if (JSON.stringify(nextProps.active) !== JSON.stringify(this.props.active) && nextProps.active) {
      this.setState({
        active: nextProps.active,
        ...(nextProps.active.length === nextProps.options.length ? { selectAll: ['Deselect All'] } : { selectAll: [] })
      })
    }
    if (JSON.stringify(nextProps.searchOptions) !== JSON.stringify(this.props.searchOptions) && nextProps.searchOptions) {
      this.setState({
        searchOptions: nextProps.searchOptions
      })
    }
  }
  onChange (value) {
    if (this.props.maxSelect >= value.length || this.props.maxSelect === -1) {
      let options = j(this.state.options)
      const searchOptions = j(this.state.searchOptions)
      if (this.props.values.length > 1 && this.props.valueSeperator) {
        const temp = options.map(i => this.props.values.map(k => i[k]).join(this.props.valueSeperator))
        const a = value.filter(d => temp.indexOf(d) === -1)
        options = [
          ...searchOptions.filter(f => a.indexOf(this.props.values.map(k => f[k]).join(this.props.valueSeperator)) > -1),
          ...options
        ]
      }
      this.setState({
        active: value,
        error: false,
        options,
        ...(value.length === this.state.options.length ? { selectAll: ['Deselect All'] } : { selectAll: [] })
      })
    } else if (this.props.notify) {
      this.setState({
        error: true
      }, () => this.props.notify('maxSelect'))
    }
  }
  onSelectAll (value) {
    this.setState({
      selectAll: value.length ? ['Deselect All'] : [],
      active: value.length ? j(this.state.options) : []
    })
  }
  onSearch (e) {
    this.setState({
      term: e.target.value
    }, () => {
      if (this.props.onSearch) {
        this.props.onSearch(this.state.term)
      } else {
        const searchOptions = []
        const options = j(this.state.options)
        options.forEach((item) => {
          if (typeof (item) === 'object') {
            if (item[this.props.keys[0]].toLowerCase().indexOf(this.state.term.toLowerCase()) >= 0) {
              searchOptions.push(item)
            }
          } else if (item.toLowerCase().indexOf(this.state.term.toLowerCase()) >= 0) {
            searchOptions.push(item)
          }
        })
        this.setState({
          searchOptions
        })
      }
    })
  }
  outsideClick () {
    if (this.state.active.length >= this.props.minSelect && (this.props.maxSelect >= this.state.active.length || this.props.maxSelect === -1)) {
      const { open } = this.state
      this.setState({
        open: false,
        term: '',
        error: false
      }, () => {
        if (open && JSON.stringify(sort(this.props.active)) !== JSON.stringify(sort(this.state.active))) {
          if (this.props.onSubmit) {
            this.props.onSubmit(this.props.sort ? sort(this.state.active) : this.state.active)
          }
        }
      })
    } else {
      let n = ''
      if (this.props.minSelect > this.state.options.length) {
        this.setState({
          open: false,
          term: '',
          error: false
        })
        n = 'lessOptions'
      } else if (!(this.props.maxSelect >= this.state.active.length || this.props.maxSelect === -1)) {
        n = 'maxSelect'
      } else if (this.state.active.length < this.props.minSelect) {
        n = 'minSelect'
      }
      if (this.state.open && this.props.notify) {
        this.setState({
          error: true,
          open: false,
          term: '',
        }, () => this.props.notify(n))
      }
    }
  }
  renderContainer () {
    const { minLength, search } = this.props
    if (this.props.loading) {
      return (
        <div className='msd-search-content center' ><FormattedMessage id="Loading..." defaultMessage="Loading..." /></div>
      )
    }
    if (this.props.searchLoading && (this.state.term.length > minLength) && search) {
      return (
        <div className='msd-search-content center' ><FormattedMessage id="Loading..." defaultMessage="Loading..." /></div>
      )
    }
    if ((this.state.term.length > minLength) && search) {
      if (this.state.searchOptions.length) {
        return (
          <div className='msd-search-content scrollbar' >
            <CheckboxGroup valueSeperator={this.props.valueSeperator} values={this.props.values} keys={this.props.keys} options={this.state.searchOptions} active={this.state.active} id={`${this.props.id}-search-options`} onChange={this.onChange} />
          </div>
        )
      }
      return (
        <div className='msd-search-content center' >
          <FormattedMessage id="No search found" defaultMessage="No search found" />
        </div>
      )
    }
    return (
      <Fragment>
        {this.props.maxSelect === -1 && (
          <div className='msd-select-container' >
            <CheckboxGroup options={this.state.selectAll.length ? ['Deselect All'] : ['Select All']} active={this.state.selectAll} id={`${this.props.id}-selectAll`} onChange={this.onSelectAll} />
          </div>
        )}
        {this.state.options.length
        ? (
          <div className='msd-options-container scrollbar' >
            <CheckboxGroup valueSeperator={this.props.valueSeperator} values={this.props.values} keys={this.props.keys} serial={this.props.serial} options={this.state.options} active={this.state.active} id={`${this.props.id}-options`} onChange={this.onChange} />
          </div>
        ) : (
          <div className='msd-options-container center'>
            <FormattedMessage id="No data found" defaultMessage="No data found" />
          </div>
        )}
      </Fragment>
    )
  }
  renderSearch () {
    const { minLength, search } = this.props
    return (
      <div className='msd-search-container' >
        <input placeholder={this.props.placeholderSearch} autoFocus className={`msd-search ${this.state.error ? 'msd-error' : ''}`} value={this.state.term} onChange={this.onSearch} />
        {(this.state.term.length > minLength) && search
        ? (
          <div role='presentation' onClick={() => this.setState({ term: '' })} className='msd-search-clear'>x</div>
        ) : <div className='msd-search-icon' />}
      </div>
    )
  }
  renderBox () {
    const title = this.props.values.length > 1 && this.state.active.length && this.props.valueSeperator ? this.state.active[0].split(this.props.valueSeperator)[0] : this.state.active[0]
    return (
      <button onClick={() => this.setState({ open: true })} className={`msd-box ${this.state.error ? 'msd-error' : ''}`} >
        {this.state.active.length === 0 ? (
          <span>
            {this.props.title}
          </span>
        ) : (
          <div title={`${title}${this.state.active.length > 1 ? ` +${this.state.active.length - 1}` : ''}`} className='disp-flex' >
            <div className='text-elips' >{title}</div>
            <div>
              {this.state.active.length > 1 && ` +${this.state.active.length - 1}`}
            </div>
          </div>
        )}
      </button>
    )
  }
  render() {
    // const { minLength, search } = this.props
    const height = (this.props.maxSelect) === -1 ? 250 : 200
    return (
      <Fragment>
        {this.props.label && <div style={{ height: 25 }} className={`filter-blk-label vcenter ${this.state.error ? 'msd-error' : ''}`} ><div>{this.props.label}</div>{this.props.minSelect > 0 && <div className='required-red'>*</div>}</div>}
        <ClickOutside onClickOutside={e => this.outsideClick(e)}>
          <div className="msd">
            {this.props.search && this.state.open ? this.renderSearch() : this.renderBox()}
            {this.state.open && (
              <div style={{ height }} className={`msd-container ${this.state.error ? 'msd-error' : ''}`} >
                {this.renderContainer()}
              </div>
            )}
          </div>
        </ClickOutside>
      </Fragment>
    );
  }
}

MSDropdown.propTypes = {
  onSearch: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])).isRequired,
  active: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  search: PropTypes.bool,
  open: PropTypes.bool,
  searchOptions: PropTypes.array,
  minLength: PropTypes.number,
  title: PropTypes.string,
  minSelect: PropTypes.number,
  maxSelect: PropTypes.number,
  placeholderSearch: PropTypes.string,
  notify: PropTypes.func,
  serial: PropTypes.bool,
  keys: PropTypes.array,
  values: PropTypes.array,
  onSubmit: PropTypes.func,
  label: PropTypes.string,
  valueSeperator: PropTypes.string,
  loading: PropTypes.bool,
  sort: PropTypes.bool,
  searchLoading: PropTypes.bool,
}

MSDropdown.defaultProps = {
  onSearch: null,
  search: false,
  open: false,
  searchOptions: [],
  minLength: 0,
  title: 'Select',
  minSelect: 0,
  maxSelect: -1,
  placeholderSearch: 'Search',
  notify: null,
  serial: false,
  keys: ['name'],
  values: [],
  onSubmit: null,
  label: '',
  valueSeperator: '',
  loading: false,
  sort: true,
  searchLoading: false
}

export default MSDropdown;
