import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
// import { ERROR } from '../../../common/container/Status/logic'

// Constants
const CHECK_TOKEN = 'CHECK_TOKEN'
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS'
const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const checkTokenAction = payload => ({
  type: CHECK_TOKEN,
  payload
})

const checkTokenSuccess = payload => ({
  type: CHECK_TOKEN_SUCCESS,
  payload
})

export const checkTokenEpic = action$ => action$
  .ofType(CHECK_TOKEN)
  .mergeMap(() => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/users/me`, 'GET'))
    .map(response => checkTokenSuccess(response))
    .catch(error => Observable.of({
      type: CHECK_TOKEN_FAILURE,
      payload: error
    })))

export function checkTokenReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case CHECK_TOKEN: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
        data: action.payload.response || {},
        loading: false,
        error: false,
        flag: true
      }
    }
    case CHECK_TOKEN_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
