import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import Drawer from '../../../common/container/Drawer'
import { Error404, Loader } from '../../../common/components'

const FaqPage = Loadable({
  loader: () => import('../../../common/components/Faq' /* faq */),
  loading() {
    return <Loader error={false} loading />
  }
})

const LandingPage = Loadable({
  loader: () => import('../../../common/container/Landing' /* landing */),
  loading() {
    return <Loader error={false} loading />
  }
})

const DiscoverPage = Loadable({
  loader: () => import('../Discover' /* discover */),
  loading() {
    return <Loader error={false} loading />
  }
})

const MarketplacePage = Loadable({
  loader: () => import('../Marketplace' /* discover */),
  loading() {
    return <Loader error={false} loading />
  }
})

const InfluencePage = Loadable({
  loader: () => import('../Influence'),
  loading() {
    return <Loader error={false} loading />
  }
})

const ExplorePage = Loadable({
  loader: () => import('../Explore'),
  loading() {
    return <Loader error={false} loading />
  }
})

const IntegratePage = Loadable({
  loader: () => import('../Integrate'),
  loading() {
    return <Loader error={false} loading />
  }
})

const DaasPage = Loadable({
  loader: () => import('../Daas'),
  loading() {
    return <Loader error={false} loading />
  }
})

const DashboardPage = Loadable({
  loader: () => import('../Dashboard'),
  loading() {
    return <Loader error={false} loading />
  }
})

const ContactUsPage = Loadable({
  loader: () => import('../ContacctUs2'),
  loading() {
    return <Loader error={false} loading />
  }
})

const UserPolicyPage = Loadable({
  loader: () => import('../UserPolicy'),
  loading() {
    return <Loader error={false} loading />
  }
})

const PrivacyPolicyPage = Loadable({
  loader: () => import('../PrivacyPolicy'),
  loading() {
    return <Loader error={false} loading />
  }
})

const InfoPage = Loadable({
  loader: () => import('../Info'),
  loading() {
    return <Loader error={false} loading />
  }
})

const SettingsPage = Loadable({
  loader: () => import('../Settings' /* settings */),
  loading() {
    return <Loader error={false} loading />
  }
})

const DiscoverAcademicsPage = Loadable({
  loader: () => import('../DiscoverAcademics'),
  loading() {
    return <Loader error={false} loading />
  }
})

const LogoutPage = Loadable({
  loader: () => import('../Logout'),
  loading() {
    return <Loader error={false} loading />
  }
})

const ResearchChampionPage = Loadable({
  loader: () => import('../ResearchChampion/index'),
  loading() {
    return <Loader error={false} loading />
  }
})

const Main = () => (
  <Fragment>
    <Switch>
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/discover' component={DiscoverPage} />
      <Route exact path='/marketplace' component={MarketplacePage} />
      <Route exact path='/influence' component={InfluencePage} />
      <Route exact path='/explore' component={ExplorePage} />
      <Route exact path='/integrate' component={IntegratePage} />
      <Route exact path='/daas' component={DaasPage} />
      <Route exact path='/dashboards' component={DashboardPage} />
      <Route exact path='/medical' component={DashboardPage} />
      <Route exact path='/regulatory' component={DashboardPage} />
      <Route exact path='/sentiment' component={DashboardPage} />
      <Route exact path='/guideline' component={DashboardPage} />
      <Route exact path='/custom' component={DashboardPage} />
      <Route exact path='/competitive' component={DashboardPage} />
      <Route exact path='/contactus' component={ContactUsPage} />
      <Route exact path='/userpolicy' component={UserPolicyPage} />
      <Route exact path='/privacypolicy' component={PrivacyPolicyPage} />
      <Route exact path='/faq' component={FaqPage} />
      <Route exact path='/profile' component={InfoPage} />
      <Route exact path='/settings' component={SettingsPage} />
      <Route exact path='/lite' component={DiscoverAcademicsPage} />
      <Route exact path='/logout' component={LogoutPage} />
      <Route exact path='/research' component={ResearchChampionPage} />
      <Route component={Error404} />
    </Switch>
    <Drawer />
  </Fragment>
)

export default Main
