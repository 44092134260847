import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl';

const NoDataFound = props => (
  <div style={props.style} className={props.className ? props.className : 'no-data'} >{props.intl.formatMessage({ id: props.message || 'Data unavailable', defaultMessage: props.message || 'Data unavailable' })}</div>
)

NoDataFound.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  intl: PropTypes.object.isRequired
}
NoDataFound.defaultProps = {
  message: '',
  className: '',
  style: {}
}

export default injectIntl(NoDataFound)
