import { combineReducers } from 'redux'
import commonReducers from '../../common/container/reducers'
import { userInfoFetchReducer } from './Info/logic'
import { deleteDataReducer } from './DeleteHistory/logic'
import { deleteAccountReducer } from './DeleteAccount/logic'

const rootReducer = combineReducers({
  ...commonReducers,
  deleteData: deleteDataReducer,
  userInfo: userInfoFetchReducer,
  deleteAccount: deleteAccountReducer
})

export default rootReducer
