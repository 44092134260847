import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
/**
 * Component is used to add default message
 * if no data available,
 * else show the data
 */
const T = props => (typeof props.data === 'number' ? props.data : props.data || props.intl.formatMessage({ id: props.message, defaultMessage: props.message }))

T.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  message: PropTypes.string,
  intl: PropTypes.object.isRequired
}

T.defaultProps = {
  message: 'Data unavailable',
  data: null
}

export default injectIntl(T)
