import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ClickOutside } from 'reusable-react-components'
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stringify, parse } from 'query-string'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import Mixpanel from '../../utils/mixpanel';
import { logoutAction, loginAction } from '../Login/logic'
import { getCookie, getRedirectUrl, ucFirst, setAnalytics, checkPermission } from '../../utils'
import { setDataLang, LangSet } from '../../lang/logic'
import { SimpleDropDown, Loader } from '../../components'
import { drawerAction } from '../Drawer/logic'
import { notificationCountAction } from '../Notification/logic'
import { modalAction } from '../Modal/logic'
import CustomerSupport from '../CustomerSupport'
import { getApps, getActiveApp, getParticularApp } from '../utils'
import { checkEmailAction, requestAccessAction, signUpAction, signInAction, requestEmailAction, forgetPasswordAction } from './logic'
import Login from '../Login';

const mailGif = require('../../../assets/images/mailer.gif')

const open = {
  type: 'notification',
  open: true
}

const close = {
  type: '',
  open: false
}

const backUrlCheck = url => [
  `/${process.env.current}/notifications`, `/${process.env.current}/subscriptions`, '/guidelinewatch/details', '/guidelinewatch/comparison'
].indexOf(url)

const renderApps = () => {
  if (getCookie('accessToken')) {
    const Apps = getApps()
    return Apps.map((item, i) => {
      const childApps = getParticularApp(item.name)
      return (
        <li key={i} className={`left-nav-group-item ${getActiveApp(item.name) ? 'active' : ''} unified-apps-dropdown`}>
          <a {...(childApps && childApps.length && childApps.length === 1 ? { href: childApps[0][childApps[0].status] } : {})} role='presentation' className='nav-link-item' >
            {item.name}
          </a>
          {childApps && childApps.length && childApps.length > 1 &&
            <div className='unified-apps-dropdown-content' >
              {childApps.map((app, j) => (
                <a className={app.inactive === window.location.pathname ? 'active' : ''} key={`${i}-${j}-app-dropdown`} href={app[app.status]} >{app.name}</a>
              ))}
            </div>
          }
        </li>
      )
    })
  }
  return null
}

const renderAcademic = () => {
  if (!getCookie('accessToken')) {
    return (
      <li className='right-nav-group-item'>
        <a title='Academic access' style={{ color: '#ffffff' }} href={`${process.env.childrenApps.discover}/lite`}>Academic Access</a>
      </li>
    )
  }
  return null
}

class UnifiedHeader extends Component {
  state = {
    lang: this.props.lang,
    langTitle: this.props.lang === 'de' ? 'Deutsch' : (this.props.lang === 'fr' ? 'French' : 'English'),
    signIn_state: false,
    signUp_state: false,
    valid_email: false,
    valid_name: false,
    model_footer_message: {
      signIn_email: `Didn't get the verfication email? click here to Send it again.`, // eslint-disable-line
      signIn: 'Forgot Password? click here.',
    },
    header_type: window.location.pathname === '/' ? 'main' : 'lite',
    user_name: '',
    user_email: '',
    passwordError: '',
    confirmPasswordError: '',
    emailPublicDomain: "We're sorry, public domain email providers such as gmail, yahoo etc. are not permitted for sign up on Ontosight",
    passwordInput: {
      password: '',
      confirmPassword: ''
    },
    passwordMatch: false,
    nameError: '',
    varify_email: false,
    email_resent: false,
    waiting: false,
    already_has_account: false,
    verificationMailAlreadySent: false,
    send_mail: false,
    openForgetPasswordModal: false,
    passwordResetMailSent: false,
    varify_email_on_forget_password: false,
    emailDoseNotExist: false,
    emailPublicDomainError: false,
    domainAccessProvided: false,
    requestAccess: false,
    showNextButton: false,
    showLoader: false,
    inputFreeze: false,
    researchpage: window.location.pathname === '/research',
    disabledNextButton: true,
    accessProvided: false,
    requestDomainRegisterMessage: 'Only institutional or academic emails are allowed. If you think your email should get access, please click on Request Access button',
    case3Dot2PopupMessageShow: false,
    case3Dot3PopupMessageShow: false,
    showPassword: false,
    showComfirmPassword: false,
  }

  defaultState = () => {
    this.setState({
      user_name: '',
      passwordError: '',
      confirmPasswordError: '',
      passwordInput: {
        password: '',
        confirmPassword: ''
      },
      passwordMatch: false,
      nameError: '',
      signIn_state: false,
      signUp_state: false,
      valid_email: false,
      valid_name: false,
      varify_email: false,
      email_resent: false,
      waiting: false,
      already_has_account: false,
      verificationMailAlreadySent: false,
      send_mail: false,
      openForgetPasswordModal: false,
      passwordResetMailSent: false,
      varify_email_on_forget_password: false,
      emailDoseNotExist: false,
      emailPublicDomainError: false,
      domainAccessProvided: false,
      requestAccess: false,
      showNextButton: false,
      showLoader: false,
      inputFreeze: false,
      disabledNextButton: true,
      accessProvided: false,
      case3Dot2PopupMessageShow: false,
      case3Dot3PopupMessageShow: false,
    })
  }

  componentDidMount() {
    if (getCookie('accessToken') && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
      this.props.notificationCountAction()
    }
    this.setState({
      header_type: window.location.pathname === '/' ? 'main' : 'lite'
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.signUpFormOpen.flag === true && nextProps.signUpFormOpen.open === true) {
      this.setState({
        signUp_state: true,
      })
    }

    if (nextProps.accesStatus.flag && !this.props.accesStatus.flag) {
      if (nextProps.accesStatus.data.responseData.message === 'Your access request is sent to admin.') {
        this.setState({
          domainAccessProvided: true,
          waiting: false
        })
      }
    }

    if (nextProps.forgetPasswordStatus.error && nextProps.forgetPasswordStatus.loading) {
      this.setState({
        waiting: false
      })
    }

    if (nextProps.forgetPasswordStatus.flag && !this.props.forgetPasswordStatus.flag) {
      if (nextProps.forgetPasswordStatus.data.responseData) {
        this.setState({
          passwordResetMailSent: true,
          openForgetPasswordModal: false,
          waiting: false
        })
      }

      if (nextProps.forgetPasswordStatus.data.errorMessage === 'This email ID is not registered with us. Either correct the email ID or create a new account using this email ID. ') {
        this.setState({
          waiting: false,
          openForgetPasswordModal: false,
          emailDoseNotExist: true
        })
      }

      if (nextProps.forgetPasswordStatus.data.errorMessage === 'Please confirm your email address through the verification link sent to your email. If you have not received the email, please try to Resend the verification link') {
        this.setState({
          waiting: false,
          varify_email_on_forget_password: true,
          openForgetPasswordModal: false,
        })
      }
    }

    if (this.props.lang !== nextProps.lang) {
      this.setState({
        langTitle: nextProps.lang === 'de' ? 'Deutsch' : (nextProps.lang === 'fr' ? 'French' : 'English')
      })
    }

    if (!nextProps.requestEmailStatus.error && nextProps.requestEmailStatus.flag && !this.props.requestEmailStatus.flag) {
      if (nextProps.requestEmailStatus.data.responseData.message === 'Email sent successfully.') {
        if (this.state.verificationMailAlreadySent && !this.state.send_mail) {
          this.setState({
            verificationMailAlreadySent: false,
            email_resent: true,
          })
        } else {
          this.setState({
            email_resent: true,
            varify_email_on_forget_password: false
          })
        }
      }
    }

    if (nextProps.signUpStatus.error && !this.props.signUpStatus.error) {
      if (nextProps.signUpStatus.data.response.message === 'An account with this email id already exists for another application.Please subscribe.') {
        this.setState({
          already_has_account: true,
          waiting: false
        })
      }
    }
    if (!nextProps.signUpStatus.error && nextProps.signUpStatus.flag && !this.props.signUpStatus.flag) {
      if (nextProps.signUpStatus.data.message === 'Access provided to given user') {
        this.setState({
          varify_email: true,
          waiting: false
        })
      }


      if (nextProps.signUpStatus.data.message === 'User is already active please login to dashboard') {
        this.setState({
          already_has_account: true,
          waiting: false
        })
      }

      if (nextProps.signUpStatus.data.message === 'Access provided previously, email is not verified, please verify your email') {
        this.setState({
          verificationMailAlreadySent: true,
          waiting: false,
          send_mail: true
        })
        this.props.requestEmailAction({
          body: {
            email: this.state.user_email,
          }
        })
      }
    }

    if (!nextProps.signInStatus.error && nextProps.signInStatus.flag && !this.props.signInStatus.flag) {
      this.props.loginAction({
        params: stringify({
          code: nextProps.signInStatus.data.authCode,
          app: 'ontosight-master'
        })
      })
    }

    if (nextProps.emailStatus.data.message === 'case 3.1' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        accessProvided: true,
        waiting: false,
        showLoader: false,
        inputFreeze: true,
        showNextButton: false
      })
    }

    if (nextProps.emailStatus.data.message === 'case 3.2' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        case3Dot2PopupMessageShow: true,
        waiting: false,
        showLoader: false,
        inputFreeze: true,
        showNextButton: false
      })
    }

    if (nextProps.emailStatus.data.message === 'case 3.3' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        case3Dot3PopupMessageShow: true,
        waiting: false,
        showLoader: false,
        inputFreeze: true,
        showNextButton: false
      })
    }

    if (nextProps.emailStatus.data.message === 'case 5.2' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        already_has_account: true,
        waiting: false,
        showLoader: false,
        inputFreeze: true,
        showNextButton: false
      })
    }

    if (nextProps.emailStatus.data.message === 'case 1' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        valid_email: false,
        requestAccess: true,
        showNextButton: false,
        showLoader: false,
        inputFreeze: false,
      })
    }

    if (nextProps.emailStatus.data.message === 'case 2' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        valid_email: false,
        requestAccess: true,
        showNextButton: false,
        showLoader: false,
        inputFreeze: false,
      })
    }

    if (nextProps.emailStatus.data.message === 'case 4' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        valid_email: true,
        showNextButton: false,
        showLoader: false
      })
    }

    if (nextProps.emailStatus.data.message === 'case 5.1' && !this.props.emailStatus.flag && nextProps.emailStatus.flag) {
      this.setState({
        verificationMailAlreadySent: true,
        waiting: false,
        send_mail: true
      })
      this.props.requestEmailAction({
        body: {
          email: this.state.user_email,
        }
      })
    }
  };

  onSolutionSelection = (value) => {
    if (value === 'Discover Lite') {
      window.location.href = process.env.lite
    } else {
      window.location.href = `/${value.toLowerCase()}`
    }
  }

  onLangSelection = (lang) => {
    let langCode;
    let langTitle;
    switch (lang) {
      case 'Deutsch': {
        langCode = 'de';
        langTitle = 'Deutsch';
        break;
      }
      case 'French': {
        langCode = 'fr';
        langTitle = 'French';
        break;
      }
      default: {
        langCode = 'en';
        langTitle = 'English'
      }
    }
    if (langCode !== this.state.lang) {
      this.setState(() => ({
        lang: langCode,
        langTitle
      }));
      this.props.LangSet(langCode);
      if (langCode !== this.props.dataLang) {
        this.props.setDataLang(langCode);
      }
    }
  };

  onNotificationClick() {
    if (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)) {
      const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
      const url = getRedirectUrl(process.env.current, {}, {}, false)
      const params = stringify({
        ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
        backApp: process.env.backLabel
      })
      window.location = `${url}/notifications?${params}`
    } else {
      this.props.drawerAction(this.props.drawer.open ? close : open)
      if (this.props.drawer.open) {
        this.props.notificationCountAction()
      }
    }
  }

  onSubAppsClick(url, permission) {
    if (checkPermission(permission)) {
      this.props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
    } else {
      window.location.href = url;
    }
  }

  openModal(invite = false) {
    if (invite) {
      setAnalytics('rollingOutInvitesClickedTrigger');
      this.props.modalAction({
        type: 'request_invite',
        open: true,
        dialog: true,
        size: 'medium'
      })
    } else {
      this.props.modalAction({
        type: 'login',
        open: true,
        dialog: true,
        size: 'medium'
      })
    }
  }

  openNav = () => {
    document.getElementById('mySidenav').style.width = '100%';
  }

  closeNav = () => {
    document.getElementById('mySidenav').style.width = '0';
  }

  renderLanguage() {
    const { intl } = this.props
    const languagesOption = ['English', 'Deutsch'];
    if ((getCookie('accessToken') && checkPermission('language_localization')) || !getCookie('accessToken')) {
      return (
        <li className='language-item right-nav-group-item'>
          <SimpleDropDown
            // DropdownClass='has-arrow'
            options={languagesOption}
            title={intl.formatMessage({ id: this.state.langTitle || 'abc', defaultMessage: this.state.langTitle })}
            class={`${this.state.header_type === 'main' ? '' : 'makeTextBold'} `}
            onSelect={index => this.onLangSelection(languagesOption[index])}
          />
        </li>
      )
    }
    return null
  }
  renderSolution() {
    const Options = ['Discover', 'Discover Lite', 'Influence', 'Explore', 'Dashboards', 'DaaS', 'Integrate', 'Marketplace'];
    const Solutions = {
      en: 'Solution',
      fr: 'Solution',
      de: 'Lösungen'
    }
    return (
      <div className='solutions-item right-nav-group-item'>
        <SimpleDropDown
          // DropdownClass='has-arrow'
          options={Options}
          title={Solutions[this.props.lang]}
          onSelect={index => this.onSolutionSelection(Options[index])}
        />
      </div>
    )
  }
  renderNotification() {
    if (getCookie('accessToken') && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
      const count = process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)
        ? this.props.notification.count.unread_count
        : this.props.count.data
      const { intl } = this.props
      return (
        <li className='right-nav-group-item notification-drawer'>
          <a title={intl.formatMessage({ id: 'Notifications', defaultMessage: 'Notifications' })} role='presentation' className='notification-icon-wrap' onClick={() => this.onNotificationClick()} >
            <i className='notification-icon' />
            {count > 0 && <span className='badge-count'>{count}</span>}
          </a>
        </li>
      )
    }
    return null
  }
  renderAccessNow() {
    const style = {
      margin: '0 10px',
      cursor: 'pointer',
      fontSize: 16,
      color: '#2d2b2b9c'
    }
    if (!getCookie('accessToken')) {
      return (
        <li style={style} role='presentation' onClick={() => this.openModal(true)} className='right-nav-group-item'>
          <FormattedMessage id="academics.access-btn" defaultMessage="Access Now" />
        </li>
      )
    }
    return null
  }
  renderProfile() {
    if (getCookie('accessToken')) {
      const { intl } = this.props;
      const userOptions = [
        {
          name: <FormattedMessage id="Header.FAQ's" defaultMessage="FAQ's" />,
          title: intl.formatMessage({ id: 'Header.Frequently asked questions', defaultMessage: 'Frequently asked questions' }),
          cb: () => {
            setAnalytics('pageFromWhereFaqsClickedTrigger', 'pageFromWhereFaqsClicked', this.props.location.pathname);
            window.location = `${getRedirectUrl('app', {}, {}, false)}faq`
          }
        },
        {
          name: 'Profile',
          title: 'More about you',
          cb: () => { window.location = `${getRedirectUrl('app', {}, {}, false)}profile` },
          condition: !getCookie('trialUser')
        },
        {
          name: <FormattedMessage id="Header.AccountSettings" defaultMessage="Account Settings" />,
          title: intl.formatMessage({ id: 'Header.Check / Update your settings', defaultMessage: 'Check / Update your settings' }),
          cb: () => { window.location = `${getRedirectUrl('app', {}, {}, false)}settings` },
          condition: !getCookie('trialUser')
        },
        {
          name: <FormattedMessage id="Header.Logout" defaultMessage="Logout" />,
          title: intl.formatMessage({ id: 'Header.See you next time!', defaultMessage: 'See you next time!' }),
          cb: () => {
            setAnalytics('logoutTrigger', 'logout', getCookie('userName'));
            this.props.logoutAction(stringify({ app: this.props.app, trialUser: !!getCookie('trialUser') }))
          }
        }
      ]
      const filteredUserOptions = userOptions.filter(opt => !('condition' in opt) || opt.condition)
      const profileIcon = `<div title='${ucFirst(getCookie('name') || 'Guest')}' class='disp-flex vcenter' ><div class='user-img'>
      <img class='user-face' src='/src/assets/images/svg/users.svg' alt='Profile' />
      </div><div class='nav-item text-elips'>${ucFirst(getCookie('name') || 'Guest')}</div></div>`
      return (
        <li className='user-item' >
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={filteredUserOptions}
            title={profileIcon}
            onSelect={(index) => { filteredUserOptions[index].cb() }}
          />
        </li>
      )
    }
    return null
  }
  renderLogin() {
    if (!getCookie('accessToken')) {
      return (
        <li role='presentation' onClick={() => this.openModal(false)} className='right-nav-group-item'>
          <FormattedMessage id="link.login" defaultMessage="Login" />
        </li>
      )
    }
    return null
  }

  contactUs = () => (
    <li className='ontosgiht-lite-contact-us'>
      <div className='request-demo-btn'>
        <a href={`${process.env.redirectUrl}contactus`} className='btn-demo-req-contact-us'>Contact us</a>
      </div>
    </li>
  )

  handleSignInButtonClick = () => {
    // this.openModal(false)
    this.defaultState()
    this.setState({
      signIn_state: true,
      signUp_state: false,
      already_has_account: false
    })
  }

  signIn = () => (
    <li>
      <div className='request-demo-btn'>
        <button className='btn-demo-req-sign-in' onClick={() => this.handleSignInButtonClick(false)}> Sign In</button>
      </div>
    </li>
  )

  signUp = () => (
    <li>
      <div className='request-demo-btn'>
        <button className='btn-demo-req-sign-up' onClick={() => this.setState({ signUp_state: true, showNextButton: true })}> Sign Up For Free</button>
      </div>
    </li>
  )

  renderBtnGetStarted = () => (
    <li>
      <div className='request-demo-btn' style={{ padding: '3em 0' }}>
        <button onClick={() => this.setState({ signUp_state: true, showNextButton: true })}className='btn-demo-req-sign-up'> Get Started</button>
      </div>
    </li>
  )

  handelSignInSubmit = (event) => {
    event.preventDefault();
    this.props.signInAction({
      body: {
        email: this.state.user_email,
        password: this.state.passwordInput.password
      }
    })
    this.defaultState();
    event.stopPropagation();
  }

  renderSignIn = () => (
    <div>
      <Login height={385} divId='ssoLiteLogin' cssPath='sso-lite.css' />
    </div>
  )

  validateEmail = (event) => {
    event.preventDefault();
    this.setState({
      user_email: event.target.value
    })

    const userEmail = event.target.value.trim()
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(userEmail)) {
      this.setState({
        disabledNextButton: false
      })
    } else {
      this.setState({
        disabledNextButton: true
      })
    }
  }

  handleNameInput = (event) => {
    event.preventDefault();
    this.setState({
      user_name: event.target.value
    })
    const fullName = event.target.value.trim();
    if (fullName.includes(' ')) {
      const arr = fullName.split(' ')
      arr.forEach(name => this.validateName(name))
    } else {
      this.validateName(fullName)
    }
  }

  validateName = (name) => {
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;

    const numericalCharacter = ['-', '+', ')', '(', '/', '=']

    const nameNoteEmpty = name.length > 0;
    const specialCharacter = specialCharRegExp.test(name)

    let errorMessage = '';

    if (!nameNoteEmpty) {
      errorMessage = 'user name cannot be empty';
    } else if (specialCharacter) {
      errorMessage = 'User name cannot have special character'
    } else {
      for (let i = 0; i < name.length; i += 1) {
        if (numericalCharacter.includes(name[i])) {
          errorMessage = 'user name cannot Mathmatical Operators'
          break;
        }
      }
    }
    this.setState({
      nameError: errorMessage,
      valid_name: errorMessage.length === 0
    })
  }

  validatePassword = (password) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const notEmpty = password.length > 0
    const uppercasePassword = uppercaseRegExp.test(password);
    const lowercasePassword = lowercaseRegExp.test(password);
    const digitsPassword = digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword = minLengthRegExp.test(password);

    let errorMessage = '';

    if (!notEmpty || !uppercasePassword || !lowercasePassword || !digitsPassword || !specialCharPassword || !minLengthPassword) {
      errorMessage = 'Password should be between 8 and 20 characters long and should contain atleast one capital letter, one small letter, one number and @!#$%^&'
    }

    this.setState({
      passwordError: errorMessage
    })
  }

  validateConfirmPassword = (confirmPassword) => {
    if (this.state.passwordInput.password !== confirmPassword) {
      this.setState({
        confirmPasswordError: "Password's does not match",
        passwordMatch: false
      })
    } else {
      this.setState({
        confirmPasswordError: '',
        passwordMatch: true,
      })
    }
  }

  handleSignUpSubmit = (event) => {
    event.preventDefault();
    this.defaultState()
    event.stopPropagation()
  }

  handlePassword = (event) => {
    event.preventDefault();
    this.setState({
      passwordInput: {
        ...this.state.passwordInput,
        password: event.target.value.trim()
      }
    })
    this.validatePassword(event.target.value);
    event.stopPropagation();
  }

  handleConfirmPassword = (event) => {
    event.preventDefault();
    this.setState({
      passwordInput: {
        ...this.state.passwordInput,
        confirmPassword: event.target.value.trim()
      }
    })
    this.validateConfirmPassword(event.target.value)
    event.stopPropagation();
  }

  handleRequestAccess = () => {
    this.setState({
      waiting: true
    })
    this.props.requestAccessAction({
      body: {
        email: this.state.user_email
      }
    })
  }

  handleSignUp = () => {
    this.props.signUpAction({
      body: {
        email: this.state.user_email.toLowerCase(),
        first_name: this.state.user_name,
        password: this.state.passwordInput.password
      }
    })
    Mixpanel.track('Sign up', {
      eventData: JSON.stringify({
        email: this.state.user_email,
        name: this.state.user_name,
      }),
    });
    this.setState({
      waiting: true
    })
  }

  callCheckEmailApi = () => {
    this.setState({
      showNextButton: false,
      showLoader: true,
      inputFreeze: true
    })
    Mixpanel.track('Check email in Sign up', {
      eventData: JSON.stringify({
        email: this.state.user_email.toLowerCase()
      }),
    });
    this.props.checkEmailAction({
      body: {
        email: this.state.user_email.toLowerCase()
      }
    })
  }

  setInititalState = () => {
    this.setState({
      signUp_state: true, showNextButton: true, emailPublicDomainError: false, requestAccess: false
    })
  }

  renderButtonsOnCheckEmail = () => {
    if (this.state.showNextButton) {
      return (
        <div className='button-email-not-valid' >
          <button style={{ margin: '13px 284px 52px' }} disabled={this.state.disabledNextButton} className='button-form-yellow' type='submit' onClick={this.callCheckEmailApi}>Next</button>
        </div>
      )
    }

    if (!this.state.valid_email && !this.state.emailPublicDomainError && this.state.requestAccess) {
      return (
        <Fade delay={1000}>
          <div className='button-email-not-valid' >
            <button className='button-form-yellow' type='requestAccesssubmit' onClick={this.handleRequestAccess}>Request Access</button>
          </div>
        </Fade>
      )
    }
    if (this.state.valid_email) {
      return <button disabled={!this.state.valid_name} onClick={this.handleSignUp} className='button-form-submit' type='submit' >Sign Up</button>
    }
    return null;
  }

  renderSignUp = () => (
    <div className='singup-box' style={{ height: !this.state.requestAccess ? this.state.valid_email ? '415px' : '0' : '181px' }}>
      <form className='signup-form' onSubmit={this.handleSignUpSubmit}>
        <label htmlFor='ontosight-signup-email'>
          <input
            disabled={this.state.inputFreeze}
            type='email'
            id='ontosight-signup-email'
            placeholder='Email* (enter your organization email address)'
            onChange={this.validateEmail}
            value={this.state.user_email}
            className='sign-in-up-input'
            onFocus={this.setInititalState}
          />
          {<Loader loading={this.state.showLoader} height='50px' />}
          {this.state.requestAccess &&
          <p className='error-message-blue'>{this.state.requestDomainRegisterMessage}</p>}
          {!this.state.emailPublicDomainError ? null :
          <p className='error-message'>{this.state.emailPublicDomain}<span><sup>®</sup> Lite. Please use an institution or academic email address.</span></p>}
        </label>
        {
          <div className={`password-session ${this.state.valid_email ? 'active' : ''}`} >
            <label htmlFor='ontosight-signup-set-password' >
              <input
                type={this.state.showPassword ? 'text' : 'password'}
                id='ontosight-signup-set-password'
                placeholder='Set password*'
                className='sign-in-up-input'
                value={this.state.passwordInput.password}
                onChange={this.handlePassword}
              />
              <FontAwesomeIcon className='eye-icon-position-1' onClick={() => this.setState({ showPassword: !this.state.showPassword })} icon={!this.state.showPassword ? faEyeSlash : faEye} />
              {!this.state.passwordError.length ? null :
              <p className='error-message' >{this.state.passwordError}</p>}
            </label>
            <label htmlFor='ontosight-signup-confirm-password'>
              <input
                type={this.state.showComfirmPassword ? 'text' : 'password'}
                id='ontosight-signup-confirm-password'
                placeholder='confirm password*'
                className='sign-in-up-input'
                value={this.state.passwordInput.confirmPassword}
                onChange={this.handleConfirmPassword}
              />
              <FontAwesomeIcon className='eye-icon-position-2' style={{ bottom: !this.state.passwordError.length ? '212px' : '192px' }} onClick={() => this.setState({ showComfirmPassword: !this.state.showComfirmPassword })} icon={!this.state.showComfirmPassword ? faEyeSlash : faEye} />
              {this.state.confirmPasswordError &&
              <p className='error-message' >{this.state.confirmPasswordError}</p>}
            </label>
            {
              <div className={`name-session ${this.state.passwordMatch ? 'active' : ''}`} >
                <label htmlFor='ontosight-signup-name'>
                  <input
                    type='text'
                    id='ontosight-signup-name'
                    placeholder='Name'
                    className='sign-in-up-input'
                    onChange={this.handleNameInput}
                    value={this.state.user_name}
                  />
                  {this.state.nameError &&
                  <p className='error-message'>{this.state.nameError}</p>}
                </label>
              </div>
          }
            {this.renderButtonsOnCheckEmail()}
          </div>}
        {!this.state.valid_email && this.renderButtonsOnCheckEmail()}
      </form>
    </div>
  )

  onClickOutside = () => {
    this.defaultState()
    this.setState({
      user_email: '',
    })
  }

  handleResendEmail = () => {
    this.props.requestEmailAction({
      body: {
        email: this.state.user_email,
      }
    })
    this.setState({
      send_mail: false
    })
  }

  renderModelFooter = () => {
    if (this.state.varify_email || this.state.email_resent || this.state.send_mail) {
      return <button onClick={() => this.handleResendEmail()} className='model-footer-button'>{ this.state.model_footer_message.signIn_email }</button>
    }

    if (this.state.signIn_state) {
      return (
        <button
          onClick={() => {
        this.defaultState()
        this.setState({ openForgetPasswordModal: true })
      }}
          className='model-footer-button'
        >{ this.state.model_footer_message.signIn }
        </button>)
    }

    return null;
  }

  handleForgetPassword = (event) => {
    event.preventDefault()
    this.setState({
      waiting: true,
      openForgetPasswordModal: false
    })

    setTimeout(() => {
      this.props.forgetPasswordAction({
        body: {
          email: this.state.user_email,
          client_id: process.env.liteClientId
        }
      })
    }, 3000);
    event.stopPropagation();
  }

  handleForgetPasswordTagClick = () => {
    this.defaultState()
    this.setState({ openForgetPasswordModal: true, already_has_account: false })
  }

  renderSignInOrSignUpModel = () => {
    const flag = this.state.signIn_state

    if (this.state.domainAccessProvided) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition' style={{ minHeight: '200px' }}>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Your Access Request is being processed</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >Thank you for your interest in accessing Ontosight<sup>®</sup> Lite. </div>
                    <div className='model-text-lite' >We have received your request for access using {this.state.user_email}.</div>
                    <div className='model-text-lite' >Our team will review your request and inform you via email accordingly.</div>
                  </div>
                </div>
                {this.renderModelFooter()}
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.emailDoseNotExist) {
      const infoMessage = `We're sorry, but the email ${this.state.user_email}  you entered is not `
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Email Not Found</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >{infoMessage}</div>
                    <div className='model-text-lite' >used for sign up to Ontosight<sup>®</sup> Lite.</div>
                    <div className='model-text-lite' > Please ensure you have entered the correct email address or consider signing up</div>
                    <div className='request-demo-btn' style={{ padding: '1em 0' }}>
                      <button
                        className='btn-demo-req-sign-up'
                        onClick={() => {
                        this.defaultState()
                        this.setState({ signUp_state: true, user_email: '', showNextButton: true })
                      }}
                      > Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }
    if (this.state.passwordResetMailSent) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Reset Password Link sent successfully</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >Your password reset link for Ontosight Light has been sent to your email address, {this.state.user_email}.</div>
                    <div className='model-text-lite' >Please check your inbox and follow the instructions to reset your password.</div>
                  </div>
                  <img className='icon-email-sent' src={mailGif} alt='mail-gif' />
                </div>
                {this.renderModelFooter()}
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.varify_email_on_forget_password) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Email address not verified</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >The account verification link has been sent to the email address you provided {this.state.user_email}.</div>
                    <div className='model-text-lite' >Please check your email and follow the steps to verify your email.</div>
                  </div>
                  <img className='icon-email-sent' src={mailGif} alt='mail-gif' />
                </div>
                {this.renderModelFooter()}
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.openForgetPasswordModal) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='singup-box'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='sign-in-up-buttons'>
                  <button className='sign-up-button common-button active-button' >Forget Password</button>
                </div>
                <form className='signup-form' onSubmit={this.handleForgetPassword}>
                  <label htmlFor='ontosight-signup-email'>
                    <input
                      type='email'
                      id='ontosight-signup-email'
                      placeholder='Email* (enter your organization email address)'
                      onChange={(e) => {
                        this.setState({
                          user_email: e.target.value
                        })
                        e.preventDefault()
                      }}
                      value={this.state.user_email}
                      className='sign-in-up-input'
                    />
                  </label>
                  <button className='button-form-submit'>Submit</button>
                </form>
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.case3Dot3PopupMessageShow) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition' style={{ minHeight: '200px' }}>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Your Access Request is being processed</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >Thank you for your interest in accessing Ontosight<sup>®</sup> Lite.</div>
                    <div className='model-text-lite'>We have received your request for access using {this.state.user_email} </div>
                    <div className='model-text-lite'> Our team will review your request and inform you via email accordingly.</div>
                  </div>
                </div>
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.case3Dot2PopupMessageShow) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Email already registered with us, verify your email!</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >Your email is already registered with us {this.state.user_email}.</div>
                    <div className='model-text-lite'>Please check your inbox for a verification link, and follow the instructions to complete the verification process.</div>
                    <div className='model-text-lite'>You can sign in to Ontosight<sup>®</sup> Lite after verification.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.waiting || this.state.already_has_account || this.state.accessProvided) {
      const textForAccessProvided = 'If you\'ve forgotten your password, you can easily reset it by selecting the '
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                {this.state.already_has_account &&
                  <div className='header-model-lite' >
                    <span className='header-lite'>Email address already registered with Us</span>
                    <div className='body-content-model'>
                      <div className='model-text-lite' >This email address {this.state.user_email}, is already registered in </div>
                      <div className='model-text-lite'>Ontosight<sup>®</sup> Lite. To get started, please use the <a onClick={() => this.handleSignInButtonClick(false)} role='presentation'>sign in</a> option.</div>
                      <div className='model-text-lite'>{textForAccessProvided}</div>
                      <div className='model-text-lite'><a onClick={() => this.handleForgetPasswordTagClick()} role='presentation'>Forgot Password</a> link on the sign-in page. </div>
                    </div>
                  </div>
                }
                {this.state.accessProvided &&
                <div className='header-model-lite' >
                  <span className='header-lite'>Email address already registered with Us</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >Your email is already registered with us. </div>
                    <div className='model-text-lite'>Good news: we have provided you access to Ontosight<sup>®</sup> lite!
                    </div>
                    <div className='model-text-lite' >To get started, please use the <a onClick={() => this.handleSignInButtonClick(false)} role='presentation'>sign in</a>  option.</div>
                    <div className='model-text-lite' >If you have forgotten your password, you can use the <a onClick={() => this.handleForgetPasswordTagClick()} role='presentation'>Forgot Password </a>option</div>
                    <div className='model-text-lite' style={{ padding: 0 }}> on the sign in tab.</div>
                  </div>
                </div>
                }
                {this.state.waiting &&
                  <div className='header-model-lite' >
                    <span className='header-lite'>Loading...</span>
                    <Loader loading={this.state.waiting} height='380px' />
                  </div>
                }
                {this.state.verificationMailAlreadySent &&
                <div className='header-model-lite' >
                  <span className='header-lite'>Dear user you have already received verification mail previously</span>
                </div>
                }
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.verificationMailAlreadySent) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Email already used for signup, verification pending</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >{`The ${this.state.user_email} is already used for signup on Ontosight lite.`}</div>
                    <div className='model-text-lite' > We have sent you email with link to verify the eamil address</div>
                    <div className='model-text-lite' >After email verification you will be able to login to Ontosight lite, using the password you entered on signup </div>
                  </div>
                  <img className='icon-email-sent' src={mailGif} alt='mail-gif' />
                </div>
                {this.renderModelFooter()}
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.email_resent) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Email resent successfully</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite'>Email sent for verification!</div>
                    <div className='model-text-lite' >The account verification link has been sent to the email address you provided.</div>
                    <div className='model-text-lite' >Please check your email and complete the email verification process</div>
                  </div>
                  <img className='icon-email-sent' src={mailGif} alt='mail-gif' />
                </div>
                {this.renderModelFooter()}
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.varify_email) {
      return (
        <div className='reusable-overlay'>
          <ClickOutside onClickOutside={() => this.onClickOutside()} >
            <div className='reusable-modal sign-in-up-model'>
              <div className='box-for-transition'>
                <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                <div className='header-model-lite' >
                  <span className='header-lite'>Sign up successful, verify your email!</span>
                  <div className='body-content-model'>
                    <div className='model-text-lite' >Congratulations, you have successfully signed-up for Ontosight<sup>®</sup> Lite! </div>
                    <div className='model-text-lite' >We have sent email verification link to your email address {this.state.user_email}.</div>
                    <div className='model-text-lite' > Please check your inbox for a verification link, and follow the instructions to complete the verification process.</div>
                  </div>
                  <img className='icon-email-sent' src={mailGif} alt='mail-gif' />
                </div>
                {this.renderModelFooter()}
              </div>
            </div>
          </ClickOutside>
        </div>
      )
    }

    if (this.state.signIn_state || this.state.signUp_state) {
      return (
        <div>
          <div className='reusable-overlay'>
            <ClickOutside onClickOutside={() => this.onClickOutside()} >
              <div className='reusable-modal sign-in-up-model'>
                <div className='box-for-transition'>
                  <div className='reusable-modal-close' role='presentation' onClick={() => this.onClickOutside()} >&#10005;</div>
                  <div className='sign-in-up-buttons'>
                    <button className={`sign-up-button common-button ${flag ? '' : 'active-button'}`} onClick={() => this.setState({ signIn_state: false, signUp_state: true, showNextButton: true })}>Sign Up</button>
                    <button className={`sign-in-button common-button ${flag ? 'active-button' : ''}`} onClick={() => this.setState({ signIn_state: true, signUp_state: false })}>Sign In</button>
                  </div>
                  {this.state.signIn_state && this.renderSignIn()}
                  {this.state.signUp_state && this.renderSignUp()}
                  {this.state.signIn_state && this.renderModelFooter()}
                </div>
              </div>
            </ClickOutside>
          </div>
        </div>
      )
    }

    return null
  }

  render() {
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn

    if (!getCookie('accessToken') && (query === 'true' || hash === 'true')) {
      return null
    }
    const classNameToAdjustMarginTop = this.state.header_type === 'main' ? '' : 'adjusted'
    const LoggedIn = (
      <Fragment>
        <div className='unified-header-top-container'>
          <ul className='left-nav-group' >
            <li className='nav-logo-wrap' >
              <a href='/' className='logo' >
                <i className='icon icon-logo-iplexus' />
              </a>
            </li>
            {renderApps()}
          </ul>
          <ul className='right-nav-group'>
            <li className='right-nav-group-item'>
              <CustomerSupport title="CustomerSupport" />
            </li>
            {renderAcademic()}
            {this.renderLanguage()}
            {this.renderProfile()}
            {this.renderLogin()}
          </ul>
        </div>
        {/* <div className='unified-header-bottom-container'>
          <ul className='left-nav-group' />
          <ul className='right-nav-group'>
            {this.renderLanguage()}
          </ul>
        </div> */}
        <div className='unified-header-top-container unified-mobile-header'>
          <div id="mySidenav" className="sidenav">
            <a href="#" className="closebtn" onClick={() => this.closeNav()}>&times;</a>
            <a href="#">
              <ul className='left-nav-group'>
                {renderApps()}
              </ul>
            </a>
            <a className='mobile-bottom-menu' href="#">
              {this.renderLanguage()}
              {this.renderProfile()}
            </a>
          </div>
          <a className='hamburger-icon' onClick={() => this.openNav()} role='presentation'>&#9776;</a>
          <div className='nav-logo-wrap' >
            <a href='/' className='logo' >
              <i className='icon icon-logo-iplexus' />
            </a>
          </div>
        </div>
      </Fragment>);
    const LoggedOut =
      (
        <Fragment>
          {this.renderSignInOrSignUpModel()}
          <div className={`unified-header-${this.state.header_type === 'lite' ? 'logged-lite' : 'logged-out'} unified-header-top-container`}>
            <div className={`ontosight-lite-nav-${this.state.header_type === 'lite' ? 'lite-padding' : 'main'}`} >
              {/* <div className={`${this.state.header_type === 'lite' ? 'padding-lite-adjusted' : ''} 'ontosight-lite-nav'`}> */}
              <ul className={`left-nav-group ${classNameToAdjustMarginTop}`}>
                <li className='nav-logo-wrap' >
                  <a href='/' className='logo' >
                    <i className='icon icon-logo-iplexus' />
                  </a>
                </li>
                {renderApps()}
              </ul>
              {this.state.header_type === 'main' &&
              <div className='menu-landing'>
                <div className='dropdown'>
                  {this.renderSolution()}
                </div>
                <div className='dropdown' style={{ visibility: 'hidden' }}>
                  <div className='dropbtn'>
                    <CustomerSupport />
                  </div>
                </div>
              </div>
              }
              <ul className='right-nav-group'>
                {/* <div className='lite-nave-bar'> */}
                {this.renderProfile()}
                {this.state.header_type === 'main' && this.renderLanguage()}
                {this.state.header_type === 'main' ? this.props.location.pathname !== '/lite' ? this.renderLogin() : this.renderAccessNow() : null}
                {this.state.researchpage && this.renderBtnGetStarted()}
                {/* </div> */}
              </ul>
              {this.props.location.pathname !== '/lite' && this.props.location.pathname !== '/contactus' &&
              window.location.pathname !== '/research' &&
              <div className='try-ad-btn'>
                <a href={`${process.env.redirectUrl}lite`} className='ad'><FormattedMessage id="Header.try-discover-academ-link" defaultMessage="Try Discover Academics" /></a>
              </div>
                }
            </div>
          </div>
          {/* <div className='unified-header-bottom-container'>
            <ul className='left-nav-group' />
            <ul className='right-nav-group'>
              {this.renderLanguage()}
            </ul>
          </div> */}
          <div className='unified-header-logged-out unified-header-top-container unified-mobile-header'>
            <div id="mySidenav" className="sidenav">
              <a href="#" className="closebtn" onClick={() => this.closeNav()}>&times;</a>
              <a href="#">
                <div className='menu-landing'>
                  <div className='dropdown'>
                    {this.renderSolution()}
                  </div>
                  <div className='dropdown'>
                    <div className='dropbtn'>
                      <CustomerSupport />
                    </div>
                  </div>
                  {this.props.location.pathname !== '/lite' && this.props.location.pathname !== '/contactus' &&
                  <div className='try-ad-btn'>
                    <a href={`${process.env.redirectUrl}lite`} className='ad'><FormattedMessage id="Header.try-discover-academ-link" defaultMessage="Access Ontosight Lite" /></a>
                  </div>
              }
                </div>
              </a>
              <a className='mobile-bottom-menu' href="#">
                <ul className='right-nav-group'>
                  {this.renderProfile()}
                  {this.renderLanguage()}
                </ul>
              </a>
            </div>
            <a className='hamburger-icon' onClick={() => this.openNav()} role='presentation'>&#9776;</a>
            <ul className='left-nav-group'>
              <li className='nav-logo-wrap' >
                <a href='/' className='logo' >
                  <i className='icon icon-logo-iplexus' />
                </a>
              </li>
              {renderApps()}
            </ul>
            {this.props.location.pathname !== '/lite' ? this.renderLogin() : this.renderAccessNow()}
          </div>
        </Fragment>
      )
    return (
      <header id='header-container' className={`unified-header ${this.state.header_type === 'main' || window.location.pathname === '/research' ? '' : 'height-adjusted'} ${window.location.pathname === '/research' ? 'height-adjusted-research' : ''}`}>
        {
          getCookie('accessToken') ? LoggedIn : LoggedOut
        }
      </header>

    )
  }
}

UnifiedHeader.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  drawerAction: PropTypes.func.isRequired,
  drawer: PropTypes.object.isRequired,
  notificationCountAction: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  notification: PropTypes.object,
  app: PropTypes.string,
  LangSet: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  dataLang: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  checkEmailAction: PropTypes.func.isRequired,
  emailStatus: PropTypes.object.isRequired,
  requestAccessAction: PropTypes.func.isRequired,
  signUpAction: PropTypes.func.isRequired,
  signUpStatus: PropTypes.object.isRequired,
  signInAction: PropTypes.func.isRequired,
  signInStatus: PropTypes.object.isRequired,
  loginAction: PropTypes.func.isRequired,
  requestEmailAction: PropTypes.func.isRequired,
  requestEmailStatus: PropTypes.object.isRequired,
  signUpFormOpen: PropTypes.object.isRequired,
  forgetPasswordAction: PropTypes.func.isRequired,
  forgetPasswordStatus: PropTypes.object.isRequired,
  accesStatus: PropTypes.object.isRequired
  // userExitsCheck: PropTypes.object.isRequired,
  // userExitsCheckAction: PropTypes.func.isRequired
}
UnifiedHeader.defaultProps = {
  notification: {},
  app: process.env.apiAppName,
}

const mapStateToProps = state => ({
  drawer: state.drawer,
  count: state.count,
  notification: state.notificationList,
  lang: state.language.lang,
  dataLang: state.language.dataLang,
  emailStatus: state.emailStatus,
  accesStatus: state.accesStatus,
  signUpStatus: state.signUpStatus,
  signInStatus: state.signInStatus,
  requestEmailStatus: state.requestEmailStatus,
  signUpFormOpen: state.signUpFormOpen,
  userExitsCheck: state.userExitsCheck,
  forgetPasswordStatus: state.forgetPasswordStatus
})

export default withRouter(connect(mapStateToProps, {
  logoutAction, drawerAction, notificationCountAction, modalAction, setDataLang, LangSet, checkEmailAction, requestAccessAction, signUpAction, signInAction, loginAction, requestEmailAction, forgetPasswordAction
})(injectIntl(UnifiedHeader)))
