import mixpanel from 'mixpanel-browser';

const envCheck = process.env.type !== 'development';

const Mixpanel = {
  identify: (id) => {
    if (envCheck) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (envCheck) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (envCheck) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props) => {
      if (envCheck) {
        mixpanel.people.set(props);
      }
    },
  },
};

export default Mixpanel;
