import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import rootReducer from './container/rootReducer'
import rootEpic from './container/rootEpic'
import LangRoot from './langRoot'
import { LangSet, setDataLang } from '../common/lang/logic'

const epicMiddleware = createEpicMiddleware(rootEpic)
const store = createStore(rootReducer, applyMiddleware(epicMiddleware))

if (localStorage.lang) {
  store.dispatch(LangSet(localStorage.lang));
  store.dispatch(setDataLang(localStorage.lang));
}

const RootComponent = () => (
  <Provider store={store}>
    <LangRoot />
  </Provider>
)

export default RootComponent
