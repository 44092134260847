import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Dropdown } from 'reusable-react-components'
import PropTypes from 'prop-types'

class SingleSelectDropdownSearch extends Component {
  constructor (props) {
    super(props)
    this.state = {
      active: null,
      searchOptions: this.props.searchOptions
    }
  }
  componentWillMount () {
    if (this.props.setActive) {
      this.setState({ active: this.props.setActive })
    }
  }
  componentWillReceiveProps (nextProps) {
    const searchOptions = []
    if (nextProps.data.length) {
      for (let i = 0; i < nextProps.data.length; i += 1) {
        searchOptions.push(nextProps.data[i])
      }
      this.setState({
        searchOptions
      })
    }
    this.setState({ active: nextProps.setActive })
    // if (nextProps.setActive) {
    //   this.setState({ active: nextProps.setActive })
    // }
  }

  onChange (value, key) {
    // const q = parse(this.props.location.search)
    this.setState({
      [key]: value
    }, () => {
      // if (this.props.selectedOptions) {
      //   this.props.selectedOptions(this.state.active)
      // }
    })
    // _.invoke(this.props, 'onClick', value, key);
  }

  onSearch (text) {
    const searchOptions = []
    // any filter function and return array of options or get array of options from API
    // this.props.searchAutocompleteAction(text);
    const { data } = this.props
    data.map((item) => {
      if (item.indexOf(text) >= 0) {
        searchOptions.push(item)
      }
      return false
    })
    this.setState({
      searchOptions
    })
    // _.invoke(this.props, 'onSearch', text);
  }
  render () {
    const {
      intl,
      data,
      id,
      title,
      placeholder
    } = this.props
    return (
      <div>
        <Dropdown
          id={id}
          searchOptions={this.state.searchOptions}
          minLength={1}
          title={intl.formatMessage({ id: title || 'abc', defaultMessage: title })}
          options={data}
          placeholder={placeholder}
          active={this.state.active}
          onSearch={value => this.props.onSearch(value)}
          onChange={value => this.props.onChange(value)}
        />
      </div>
    )
  }
}

SingleSelectDropdownSearch.propTypes = {
  searchOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActive: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default withRouter(connect(null, {})(injectIntl(SingleSelectDropdownSearch)))
