import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'
import { parseHashUrl } from '../../utils';

const GoBack = (props) => {
  const { backUrl, backApp } = parse(props.location.search)
  const backHashUrl = parseHashUrl(props).backUrl
  const backHashApp = parseHashUrl(props).backApp
  if (!backUrl && !backHashUrl) {
    return null;
  }
  return (
    <Fragment>
      <div className='go-back'>
        <a href={backUrl || backHashUrl}><i className='back-arrow-left' /><FormattedMessage id="Back" defaultMessage="Back" />&nbsp;{backApp === 'competitive' ? 'to dashboard' : backApp || backHashApp ? `to ${backApp || backHashApp}` : ''}</a>
      </div>
      {props.divider && <div className='header-divider' />}
    </Fragment>
  )
}
GoBack.propTypes = {
  location: PropTypes.object.isRequired,
  divider: PropTypes.bool,
}

GoBack.defaultProps = {
  divider: true
}

export default withRouter(GoBack);
