import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../Login/logic'
import { DELETE_ACCOUNT_SUCCESS } from '../../../profile/container/DeleteAccount/logic'
import { DELETE_DATA_SUCCESS } from '../../../profile/container/DeleteHistory/logic'
import { REQUEST_DEMO_SUCCESS } from '../RequestDemo/logic'

export const ERROR = 'ERROR'
export const STATUS_CANCEL = 'STATUS_CANCEL'
const CUSTOM_MESSAGE = 'CUSTOM_MESSAGE'
const INITAL_STATE = {
  message: '',
  status: '',
  type: '',
  title: ''
}

export function statusCancel () {
  return {
    type: STATUS_CANCEL
  }
}

export function statusError (payload) {
  return {
    type: ERROR,
    payload
  }
}

export function customMessage (payload) {
  return {
    type: CUSTOM_MESSAGE,
    payload
  }
}

function setErrorStatus (payload) {
  const obj = {
    message: 'Oops! There has been an issue. Re-try in some time.',
    status: 'error',
    type: '400',
    title: 'Error'
  }
  switch (payload.status) {
    case 401:
      obj.message = 'Your current session has expired.'
      obj.type = '401'
      break
    case 403:
      obj.message = "You don't have required permissions, Please contact our adimin."
      obj.type = '403'
      break
    case 409:
      obj.message = payload.response.message || 'Oops! There has been an issue. Re-try in some time.'
      obj.type = '409'
      break
    default:
      break
  }
  return obj
}

export function statusReducer (state = INITAL_STATE, action = null) {
  switch (action.type) {
    case STATUS_CANCEL: {
      return INITAL_STATE
    }
    case ERROR: {
      return setErrorStatus(action.payload)
    }
    case LOGIN_SUCCESS: {
      return {
        message: 'You are successfully logged in',
        status: 'success',
        type: 'login',
        title: 'Success',
        hideNotification: true
      }
    }
    case DELETE_ACCOUNT_SUCCESS: {
      return {
        message: 'You account has successfully deleted',
        status: 'success',
        type: 'deleteAccount',
        title: 'Success'
      }
    }
    case CUSTOM_MESSAGE: {
      return {
        message: action.payload.message || '',
        status: action.payload.status || 'success',
        type: 'customMessage',
        title: action.payload.title || 'Success'
      }
    }
    case REQUEST_DEMO_SUCCESS: {
      return {
        message: 'Thank you for requesting demo, We will get back to you soon.',
        status: 'success',
        type: 'requestDemo',
        title: 'Success'
      }
    }
    case DELETE_DATA_SUCCESS: {
      return {
        message: 'You have successfully deleted all your user activity',
        status: 'success',
        type: 'deleteData',
        title: 'Success'
      }
    }
    case LOGOUT_SUCCESS: {
      return {
        message: 'You are successfully logged out',
        status: 'success',
        type: 'logout',
        title: 'Success'
      }
    }
    default:
      return state
  }
}
