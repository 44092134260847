import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ClickOutside } from 'reusable-react-components'
import { withRouter } from 'react-router-dom'
import { getCookie, setAnalytics } from '../../utils'
import { ErrorBoundary, CloseOnEscape } from '../'

class ChatBox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.onFeedbackClick = this.onFeedbackClick.bind(this);
  }

  componentDidMount () {
    if (feedback) {
      const obj = {
        appName: 'Ontosight-Umbrella',
        rootId: 'chatboxframe',
        token: getCookie('accessToken'),
        userid: getCookie('userName'),
        userName: `${getCookie('name')}${getCookie('lastName') ? ` ${getCookie('lastName')}` : ''}`,
        height: '325px',
        width: '396px'
      }
      feedback.configQueryForm(obj)
    }
  }

  onFeedbackClick() {
    setAnalytics('pageFeedbackManagerOpenedTrigger', 'pageFeedbackManagerOpened', this.props.location.pathname);
    this.setState({
      open: !this.state.open
    });
  }

  render () {
    const { intl } = this.props
    if (feedback) {
      return (
        <ErrorBoundary className='hide' >
          <CloseOnEscape onEscape={() => this.setState({ open: false })} >
            <ClickOutside onClickOutside={() => this.setState({ open: false })} >
              <div className={this.state.open ? 'chat-box open' : 'chat-box'} >
                <div className='chat-container' >
                  <div role='presentation' onClick={() => this.onFeedbackClick()} className='chat-button' ><FormattedMessage id="Feedback" defaultMessage="Feedback" /></div>
                  <ErrorBoundary className='hide' >
                    <iframe id='chatboxframe' src='about:blank' title={intl.formatMessage({ id: 'demo', defaultMessage: 'demo' })} />
                  </ErrorBoundary>
                </div>
              </div>
            </ClickOutside>
          </CloseOnEscape>
        </ErrorBoundary>
      )
    }
    return null
  }
}

ChatBox.propTypes = {
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withRouter(injectIntl(ChatBox))

// import { Component } from 'react';
// import { getCookie } from '../../../common/utils';

// class ChatBox extends Component {
//   componentDidMount () {
//     try {
//       if (INNO_FEEDBACK_MANAGER_CONFIG) {
//         INNO_FEEDBACK_MANAGER_CONFIG({
//           appID: process.env.feedback.appID,
//           userInfo: {
//             userID: getCookie('userName'),
//             userName: getCookie('name'),
//             userOrg: getCookie('org'),
//           },
//           root: {
//             elemID: 'inno-fm',
//           },
//           faqURL: '',
//           stylesheets: [],
//         })
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     return null;
//   }
//   render () {
//     return null
//   }
// }

// ChatBox.propTypes = {
// }

// export default ChatBox;
