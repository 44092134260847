import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';

class PillsFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      active: this.props.active ? this.props.active : typeof (this.props.data[0]) !== 'object' ? this.props.data[0] : this.props.data[0].value
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active) {
      this.setState({
        active: nextProps.active
      })
    }
  }

  pillsToggled(key, item) {
    if (item !== this.state.active) {
      this.setState({
        active: typeof (this.props.data[key]) !== 'object' ? this.props.data[key] : this.props.data[key].value
      }, () => this.props.onToggle(this.state.active))
    }
  }
  renderPills() {
    return this.state.data.map((item, key) => {
      if (typeof (item) === 'object') {
        return (
          <div role='presentation' key={key} className={this.state.active === item.value ? 'pills active' : 'pills'} onClick={() => this.pillsToggled(key, item)} >
            <FormattedMessage id={item.value.toUpperCase()} defaultMessage={item.value} />
          </div>
        )
      }
      return (
        <div role='presentation' key={key} className={this.state.active === item ? 'pills active' : 'pills'} onClick={() => this.pillsToggled(key, item)} >
          <FormattedMessage id={item.toUpperCase()} defaultMessage={item} />
        </div>
      )
    })
  }
  render() {
    return (
      <div className='pills-filter'>
        {this.props.label && <div className='filter-blk-label vcenter'>{this.props.label}<div className='required-red'>*</div></div>}
        <div className='disp-flex pills-filter-list'>
          {this.renderPills()}
        </div>
      </div>
    )
  }
}

PillsFilter.propTypes = {
  label: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  active: PropTypes.string || PropTypes.number,
  data: PropTypes.array.isRequired
}

PillsFilter.defaultProps = {
  label: '',
  active: ''
}

export default PillsFilter
