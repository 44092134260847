import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import { stringify } from 'querystring'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// check mail
const CHECK_EMAIL = 'CHECK_EMAIL';
const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
const CHECK_EMAIL_FAILURE = 'CHECK_EMAIL_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const checkEmailAction = payload => ({
  type: CHECK_EMAIL,
  payload
})

const checkEmailSuccess = payload => ({
  type: CHECK_EMAIL_SUCCESS,
  payload
})

export const checkEmailEpic = action$ => action$
  .ofType(CHECK_EMAIL)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/checkEmailAddress?`, 'POST', true, action.payload.body))
    .map(response => checkEmailSuccess(response))
    .catch(error => Observable.of(
      {
        type: CHECK_EMAIL_FAILURE,
        payload: error
      },
      {
        type: ERROR,
        payload: error
      }
    )))

export function checkEmailReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case CHECK_EMAIL: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case CHECK_EMAIL_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case CHECK_EMAIL_FAILURE: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// request access
const REQUEST_ACCESS = 'REQUEST_ACCESS';
const REQUEST_ACCESS_SUCCESS = 'REQUEST_ACCESS_SUCCESS';
const REQUEST_ACCESS_FAILURE = 'REQUEST_ACCESS_FAILURE';

const INITIAL_STATE_REQUEST_ACCESS = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const requestAccessAction = payload => ({
  type: REQUEST_ACCESS,
  payload
})

const requestAcessSuccess = payload => ({
  type: REQUEST_ACCESS_SUCCESS,
  payload
})

export const requestAccessEpic = action$ => action$
  .ofType(REQUEST_ACCESS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/requestaccess?${stringify(action.payload.params)}`, 'POST', true, action.payload.body))
    .map(response => requestAcessSuccess(response))
    .catch(error => Observable.of(
      {
        type: REQUEST_ACCESS_FAILURE,
        payload: error
      },
      {
        type: ERROR,
        payload: error
      }
    )))

export function requestAcessReducer (state = INITIAL_STATE_REQUEST_ACCESS, action = null) {
  switch (action.type) {
    case REQUEST_ACCESS: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case REQUEST_ACCESS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case REQUEST_ACCESS_FAILURE: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// sign up
const SIGN_UP = 'SIGN_UPL';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

const INITIAL_STATE_SIGN_UP = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const signUpAction = payload => ({
  type: SIGN_UP,
  payload
})

const signUpSuccess = payload => ({
  type: SIGN_UP_SUCCESS,
  payload
})

export const signUpEpic = action$ => action$
  .ofType(SIGN_UP)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/signup?${stringify(action.payload.params)}`, 'POST', true, action.payload.body))
    .map(response => signUpSuccess(response))
    .catch(error => Observable.of(
      {
        type: SIGN_UP_FAILURE,
        payload: error
      },
      {
        type: ERROR,
        payload: error
      }
    )))

export function signUpReducer (state = INITIAL_STATE_SIGN_UP, action = null) {
  switch (action.type) {
    case SIGN_UP: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}


// sign in
const SIGN_IN = 'SIGN_INL';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

const INITIAL_STATE_SIGN_IN = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const signInAction = payload => ({
  type: SIGN_IN,
  payload
})

const signInSuccess = payload => ({
  type: SIGN_IN_SUCCESS,
  payload
})

export const signInEpic = action$ => action$
  .ofType(SIGN_IN)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/login?${stringify(action.payload.params)}`, 'POST', true, action.payload.body))
    .map(response => signInSuccess(response))
    .catch(error => Observable.of({
      type: SIGN_IN_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function signInReducer (state = INITIAL_STATE_SIGN_IN, action = null) {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SIGN_IN_FAILURE: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// request invite
const REQUEST_EMAIL = 'REQUEST_EMAILL';
const REQUEST_EMAIL_SUCCESS = 'REQUEST_EMAIL_SUCCESS';
const REQUEST_EMAIL_FAILURE = 'REQUEST_EMAIL_FAILURE';

const INITIAL_STATE_REQUEST_EMAIL = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const requestEmailAction = payload => ({
  type: REQUEST_EMAIL,
  payload
})

const requestEmailSuccess = payload => ({
  type: REQUEST_EMAIL_SUCCESS,
  payload
})

export const requestEmailEpic = action$ => action$
  .ofType(REQUEST_EMAIL)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/resend_invite?${stringify(action.payload.params)}`, 'POST', true, action.payload.body))
    .map(response => requestEmailSuccess(response))
    .catch(error => Observable.of({
      type: REQUEST_EMAIL_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function requestEmailReducer(state = INITIAL_STATE_REQUEST_EMAIL, action = null) {
  switch (action.type) {
    case REQUEST_EMAIL: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case REQUEST_EMAIL_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case REQUEST_EMAIL_FAILURE: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// user exits
const USER_EXITS = 'USER_EXITS';
const USER_EXITS_SUCCESS = 'USER_EXITS_SUCCESS';
const USER_EXITS_FAILURE = 'USER_EXITS_FAILURE';

const INITIAL_STATE_USER_EXITS = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const userExitsCheckAction = payload => ({
  type: USER_EXITS,
  payload
})

const userExitsCheckSuccess = payload => ({
  type: USER_EXITS_SUCCESS,
  payload
})

export const userExitsCheckEpic = action$ => action$
  .ofType(USER_EXITS)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/user_exists?${stringify(action.payload.params)}`, 'POST', true, action.payload.body))
    .map(response => userExitsCheckSuccess(response))
    .catch(error => Observable.of(
      {
        type: USER_EXITS_FAILURE,
        payload: error
      },
      {
        type: ERROR,
        payload: error
      }
    )))

export function userExitsCheckReducer (state = INITIAL_STATE_USER_EXITS, action = null) {
  switch (action.type) {
    case USER_EXITS: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case USER_EXITS_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case USER_EXITS_FAILURE: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

// Forget password
const FORGET_PASSWORD = 'FORGET_PASSWORD';
const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';

const INITIAL_STATE_FORGET_PASSWORD = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const forgetPasswordAction = payload => ({
  type: FORGET_PASSWORD,
  payload
})

const forgetPasswordSuccess = payload => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload
})

export const forgetPasswordEpic = action$ => action$
  .ofType(FORGET_PASSWORD)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/auth/forgot_password${stringify(action.payload.params)}`, 'POST', true, action.payload.body))
    .map(response => forgetPasswordSuccess(response))
    .catch(error => Observable.of({
      type: FORGET_PASSWORD_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function forgetPasswordReducer (state = INITIAL_STATE_FORGET_PASSWORD, action = null) {
  switch (action.type) {
    case FORGET_PASSWORD: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FORGET_PASSWORD_FAILURE: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
