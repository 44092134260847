import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class SimpleToolTip extends Component {
  componentDidMount() {
    this.parent = typeof this.props.parent === 'string' ? document.querySelector(this.props.parent) : this.props.parent
  }
  render() {
    if (!this.props.isOpen || !this.parent) {
      return null
    }
    this.position = this.parent.getBoundingClientRect()
    return ReactDOM.createPortal(
      <div
        className={`simple-tooltip ${this.props.className ? this.props.className : ''}`}
        style={{ position: 'absolute', top: this.position.bottom, left: this.position.left }}
      >
        {this.props.children}
      </div>,
      document.body
    )
  }
}

SimpleToolTip.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  parent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

SimpleToolTip.defaultProps = {
  isOpen: false,
  className: ''
}

export default SimpleToolTip
