import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import mixpanel from 'mixpanel-browser';
import NotificationSystem from 'react-notification-system'
import { stringify } from 'query-string'
import { getCookie, removeCookies, getRedirectUrl, PATHS, setAnalytics, checkPermission } from '../../utils'
import { statusCancel, customMessage } from './logic'
import { modalCloseAction } from '../Modal/logic'
import { ChatBox, OutsideClick, ErrorBoundary } from '../../components'
import { setDataLang, LangSet } from '../../lang/logic'
import { getAllApps } from '../utils';

class Status extends Component {
  componentDidMount() {
    mixpanel.init(process.env.mixpanelToken, { debug: true });
    if (typeof (getCookie('accessToken')) === 'undefined' && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      this.props.history.replace(process.env.mainRoute)
    } else {
      this.goToApps()
    }
    if (getCookie('accessToken')) {
      setAnalytics('checkLoginTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
    }
    // this.checkLocalization(this.props.lang)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.status.type !== this.props.status.type && nextProps.status.type !== '') {
      const { intl } = nextProps
      this.showNotification(nextProps.status, intl)
      this.activityFlow(nextProps.status.type, nextProps)
      this.props.statusCancel()
    }
    if (typeof (getCookie('accessToken')) === 'undefined' && (PATHS.indexOf(nextProps.location.pathname.replace(/\/$/, '')) === -1) && nextProps.location.pathname !== '/') {
      let redirectRoute = process.env.mainRoute
      if (nextProps.status.type === '401') {
        const query = stringify({
          redirect_url: this.props.location.pathname + this.props.location.search
        })
        redirectRoute += `?${query}`
        this.props.history.replace(redirectRoute)
      } else if (process.env.standalone) {
        this.props.history.replace(redirectRoute)
      } else {
        window.location.href = getRedirectUrl('app', {}, {}, false)
      }
    }
  }
  goToApps = () => {
    const Apps = getAllApps()
    if (Apps && Apps.length) {
      const ActiveApp = Apps.find(item => item.inactive === window.location.pathname && item.status === 'active')
      if (ActiveApp && ActiveApp.active) {
        window.location.replace(ActiveApp.active)
      }
    }
  }
  checkLocalization (lang) {
    if (lang !== 'en' && getCookie('accessToken')) {
      const langCode = 'en';
      this.props.LangSet(langCode);
      this.props.setDataLang(langCode);
    }
  }
  activityFlow(value, nextProps) {
    switch (value) {
      case '401': {
        setAnalytics('sessionExpiredTrigger', 'sessionExpired', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        // this.removeFeedback()
        removeCookies()
        this.goToHome()
        break
      }
      case '403': {
        break
      }
      case 'login': {
        setAnalytics('userIdTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        this.checkLocalization(nextProps.lang)
        const Apps = getAllApps()
        if (Apps && Apps.length) {
          const ActiveApp = Apps.find(item => item.status === 'active')
          if (ActiveApp && ActiveApp.active) {
            window.location.replace(ActiveApp.active)
            break
          }
          if (Apps[0].inactive) {
            this.props.history.replace(Apps[0].inactive)
            this.props.modalCloseAction()
          } else {
            this.props.history.replace('/discover')
            this.props.modalCloseAction()
          }
        } else {
          this.props.history.replace('/discover')
          this.props.modalCloseAction()
          break
        }
        break
      }
      case 'logout': {
        localStorage.clear()
        // this.removeFeedback()
        removeCookies()
        this.goToHome()
        break
      }
      case 'deleteAccount': {
        // this.removeFeedback()
        removeCookies()
        this.goToHome()
        break
      }
      default:
        break
    }
  }
  goToHome = () => {
    const redirectRoute = process.env.mainRoute
    if (process.env.standalone) {
      this.props.history.replace(redirectRoute)
    } else {
      window.location.href = getRedirectUrl('app', {}, {}, false)
    }
  }
  // removeFeedback = () => {
  //   if (false) {
  //     const feedback = document.getElementById('inno-fm')
  //     if (feedback) {
  //       feedback.remove()
  //       const ele = document.createElement('div')
  //       ele.setAttribute('id', 'inno-fm')
  //       document.body.appendChild(ele)
  //       // feedback.childNodes.forEach(el => el.remove())
  //     }
  //   }
  // }
  handleClickOutside() {
    if (typeof (getCookie('accessToken')) === 'undefined' && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      const redirectRoute = process.env.mainRoute
      // this.removeFeedback()
      if (process.env.standalone) {
        this.props.history.replace(redirectRoute)
      } else {
        window.location.href = getRedirectUrl('app', {}, {}, false)
      }
    }
  }
  showNotification(value, intl) {
    const {
      title, message, status, hideNotification, type
    } = value
    if (!hideNotification) {
      this.statusMessage.addNotification({
        title: intl.formatMessage({ id: title, defaultMessage: title }),
        message: intl.formatMessage({ id: `${type}.message`, defaultMessage: message }),
        level: status,
        autoDismiss: 4,
        position: 'tc'
      })
    }
  }
  render() {
    return (
      <Fragment>
        <NotificationSystem ref={(ref) => { this.statusMessage = ref }} />
        <OutsideClick onClickOutside={() => this.handleClickOutside()} />
        {getCookie('accessToken') && (
          <ErrorBoundary>
            <ChatBox />
          </ErrorBoundary>
        )}
      </Fragment>
    )
  }
}

Status.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string
  }),
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  statusCancel: PropTypes.func.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  setDataLang: PropTypes.func.isRequired,
  LangSet: PropTypes.func.isRequired,
  // lang: PropTypes.string.isRequired,
  // customMessage: PropTypes.func.isRequired,
}

Status.defaultProps = {
  status: {
    message: '',
    status: '',
    type: '',
    title: ''
  }
}

const mapStateToProps = state => ({
  status: state.status,
  lang: state.language.lang
})

export default withRouter(connect(mapStateToProps, {
  statusCancel, modalCloseAction, setDataLang, LangSet, customMessage
})(injectIntl(Status)))
