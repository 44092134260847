import React from 'react'
import PropTypes from 'prop-types'

const Panel = props => (
  <div className={`panel ${props.className}`}>
    {props.children}
  </div>
)
Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
}
Panel.defaultProps = {
  children: null,
  className: ''
}
export default Panel
