import React from 'react'
import { FormattedMessage } from 'react-intl';

const Premium = () => (
  <div className='modal-premium'>
    <div className="modal-header">
      <h3 className="modal-title text-center"> <span className="premium-icon" /><FormattedMessage id="You discovered a Premium Feature" defaultMessage="You discovered a Premium Feature" /></h3>
    </div>
    <div className="modal-body">
      <div className="txt">
        <p className="text-center"><FormattedMessage id="You are trying to access a feature that is not included in Ontosight" defaultMessage="You are trying to access a feature that is not included in Ontosight" /><sup><FormattedMessage id="®" defaultMessage="®" /></sup> <FormattedMessage id="Academic." defaultMessage="Academic." /></p>
        <p><FormattedMessage id="Accelerate your research and increase your efficiency with additional features" defaultMessage="Accelerate your research and increase your efficiency with additional features" />:</p>
        <ul className="feature-list">
          <li>
            <span className="tick-premium" />
            <FormattedMessage id="News & Press Releases data" defaultMessage="News & Press Releases data" />
          </li>
          <li>
            <span className="tick-premium" />
            <FormattedMessage id="Treatment Guidelines data" defaultMessage="Treatment Guidelines data" />
          </li>
          {
            // <li>
            //   <span className="tick-premium" />
            //   Social Media data
            // </li>
          }
          <li>
            <span className="tick-premium" />
            <FormattedMessage id="Focused analysis on multiple fronts and customised dashboards" defaultMessage="Focused analysis on multiple fronts and customised dashboards" />
          </li>
          <li>
            <span className="tick-premium" />
            <FormattedMessage id="Detailed insights into clinical trials and interventions" defaultMessage="Detailed insights into clinical trials and interventions" />
          </li>
        </ul>
      </div>
      <div className="btn-wrap text-center">
        <a className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" /> </a>
      </div>
    </div>
  </div>
)

export default Premium
