import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import Main from './container/Main'
import Messages from '../common/lang/Messages'
import Status from '../common/container/Status'
import ModalComponent from '../common/container/Modal'
import UnifiedHeader from '../common/container/UnifiedHeader'
import Footer from '../common/container/Footer'
import Localization from '../common/lang'
import CheckAccessToken from '../common/container/CheckAccessToken'
import { getCookie } from '../common/utils';

const Dummy = withRouter(CheckAccessToken(!getCookie('accessToken'))(props => (
  <Fragment>
    <Status dummy={props} />
    <ModalComponent dummy={props} />
    <UnifiedHeader dummy={props} />
    <Main dummy={props} />
    <Footer dummy={props} />
  </Fragment>
)))

const LangRoot = props => (
  <IntlProvider locale={props.lang} messages={props.messages ? props.messages[props.lang] : Messages[props.lang]}>
    <Router>
      <Dummy {...props} />
    </Router>
  </IntlProvider>
)

LangRoot.propTypes = {
  lang: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  lang: state.language.lang
});

export default connect(mapStateToProps)(Localization()(LangRoot));
