import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drawerAction } from '../Drawer/logic'
import { OutsideClick, CloseOnEscape } from '../../components'
import Notification from '../Notification'

class Drawer extends Component {
  handleClickOutside () {
    this.props.drawerAction({
      open: false,
      type: ''
    })
  }
  renderType () {
    if (this.props.drawer.type === 'notification') {
      return <Notification />
    }
    return null
  }
  render () {
    return (
      <div className={this.props.drawer.open ? 'overlay-drawer' : ''} >
        <CloseOnEscape onEscape={() => this.handleClickOutside()}>
          <OutsideClick onClickOutside={() => this.handleClickOutside()} ignoreClickWithinClass='notification-icon-wrap' >
            <div className={this.props.drawer.open ? 'drawer-right open' : 'drawer-right'} >
              {this.renderType()}
            </div>
          </OutsideClick>
        </CloseOnEscape>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  drawer: state.drawer
})

Drawer.propTypes = {
  drawerAction: PropTypes.func.isRequired,
  drawer: PropTypes.object.isRequired
}

Drawer.defaultProps = {
}

export default withRouter(connect(mapStateToProps, {
  drawerAction
})(Drawer))
