import React from 'react'
import PropTypes from 'prop-types'


const PanelBody = props => (
  <div className={`panel-body ${props.panelClass}`}>
    {props.children}
  </div>
)

PanelBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  panelClass: PropTypes.string,
}

PanelBody.defaultProps = {
  children: null,
  panelClass: ''
}

export default PanelBody
