import React from 'react'
import { FormattedMessage } from 'react-intl'
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'
import { getCookie } from '../../utils'

const support = [
  { number: '0800 180 0542', logo: 'ger-icon', title: 'GER' },
  { number: '1 800 299 4062', logo: 'us-icon', title: 'USA' },
  { number: '0800 915 420', logo: 'fr-icon', title: 'FR' },
  { number: '0800 563 632', logo: 'swz-icon', title: 'CH' },
]

const Footer = () => {
  const viewFooter = window.location.pathname === '/lite'
  const year = new Date().getFullYear()
  const query = parse(window.location.hash).alreadyLoggedIn
  const hash = parse(window.location.search).alreadyLoggedIn
  if (!getCookie('accessToken') && (query === 'true' || hash === 'true')) {
    return null
  }
  return (
    <div>
      {!getCookie('accessToken') && !viewFooter && window.location.pathname !== '/contactus' && window.location.pathname !== '/research' &&
        <section className='support-sec'>
          <div className='support-list'>
            <div className='offices'>
              <div className='support-title'>
                <FormattedMessage id="text.our-office" defaultMessage="Our Offices" />
              </div>
              <div className='office-country'>
                <a href='https://www.innoplexus.com/contact' target='_blank' rel="noopener noreferrer" ><FormattedMessage id="link.address-germany" defaultMessage="Eschborn (HQ), Germany" /></a>
              </div>
              <div className='office-country'>
                <a href='https://www.innoplexus.com/contact' target='_blank' rel="noopener noreferrer" ><FormattedMessage id="link.address-usa" defaultMessage="Hoboken, USA" /></a>
              </div>
              <div className='office-country'>
                <a href='https://www.innoplexus.com/contact' target='_blank' rel="noopener noreferrer" ><FormattedMessage id="link.address-india" defaultMessage="Pune, India" /></a>
              </div>
            </div>
            <div className='hiring'>
              <div className='support-title'>
                <FormattedMessage id="text.we-are-hiring" defaultMessage="We are Hiring!" />
              </div>
              <a href="https://jobs.innoplexus.com/" target="blank" className='btn-careers'><FormattedMessage id="link.careers" defaultMessage="Careers" /></a>
            </div>
            <div className='cust-support'>
              <div className='support-title'>
                <FormattedMessage id="text.customer-support" defaultMessage="Customer Support" />
              </div>
              <div className='customer-support-container customer-support-landing' >
                {
                  support.map((item, index) => (
                    <div key={index} role="presentation" className="customer-support-item" >
                      <div className='flag-media'>
                        <i style={{ display: 'block' }} className={item.logo} /><span className='flag-title'>{item.title}</span>
                      </div>
                      <div className='flag-count'>
                        <span className='contact-number'>{item.number}</span>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='media-links'>
              <div className='follow-us'>
                <div className='support-title'>
                  <FormattedMessage id="text-follow-us" defaultMessage="Follow us" />
                </div>
                <div className='social-link'>
                  <a className='linkdin icon' href='https://www.linkedin.com/company/innoplexus/' target='_blank' rel="noopener noreferrer" />
                  {/* <a className='pint icon' href='https://in.pinterest.com/innoplexus/' target='_blank' rel="noopener noreferrer" /> */}
                  <a className='twitter icon' href='https://twitter.com/Innoplexus' target='_blank' rel="noopener noreferrer" />
                  <a className='fb icon' href='https://www.facebook.com/innoplexus' target='_blank' rel="noopener noreferrer" />
                </div>
              </div>
              <div className='write-us'>
                <div className='support-title'>
                  <FormattedMessage id="text-write-to-us" defaultMessage="Write to us" />
                </div>
                <div className='support-email'>
                  <a className='email-link' href="mailto:Support@innoplexus.com">Support@innoplexus.com</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <footer className={`footer ${!getCookie('accessToken') ? 'footer-without-login' : ''} ${window.location.pathname === '/lite' ? 'liteFooter' : ''}`}>
        <div className='container footer-align'>
          <div className='logo-with-link'>
            <a href='https://www.innoplexus.com/' target='_blank' rel="noopener noreferrer" className='footer-innoplexus-logo' />
            <div className='footer-menus'>
              <a href='/contactus' className='link-footer-menu'>
                <FormattedMessage id="link.contact" defaultMessage="Contact" />
              </a>
              <a href='/privacypolicy' className='link-footer-menu'>
                <FormattedMessage id="link.privacy-policy" defaultMessage="Privacy Policy" />
              </a>
              <a href='/userpolicy' className='link-footer-menu'>
                <FormattedMessage id="link.user-policy" defaultMessage="User Policy" />
              </a>
            </div>
          </div>
          {viewFooter &&
          <div className='copy'>
            {/* © {year} - <a href='http://www.innoplexus.com' target='blank'><FormattedMessage id="Innoplexus AG" defaultMessage="Innoplexus AG" /></a> - <FormattedMessage id="All Rights Reserved." defaultMessage="All Rights Reserved." /> */}
            <span>
              © {year} - <a className='link-footer-menu last-txt' target='_blank' rel="noopener noreferrer" href='https://www.innoplexus.com/'> Innoplexus<sup>&reg;</sup></a>
            </span>
            <span className='freepik-text'>Illustrations design made by Freepik from https://storyset.com/</span>
          </div>}
        </div>
      </footer>
    </div>
  )
}


Footer.propTypes = {
  // location: PropTypes.object.isRequired
}


export default withRouter(Footer)
