import React, { Component } from 'react'
import { ClickOutside } from 'reusable-react-components'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';

const getMonth = date => new Date(date).getMonth()
const getYear = date => new Date(date).getFullYear()
const getDate = (year, month) => new Date(year, month, 0).getDate()

class MonthRange extends Component {
  constructor (props) {
    super(props)
    const start = new Date(getYear(this.props.start), getMonth(this.props.start), 1)
    const end = new Date(getYear(props.end), getMonth(props.end), getDate(getYear(props.end), getMonth(props.end) + 1))
    const endYear = getYear(end)
    const startYear = getYear(start)
    const minDate = this.props.minDate ? new Date(getYear(this.props.minDate), getMonth(this.props.minDate), 1) : null
    const maxDate = this.props.maxDate ? new Date(getYear(props.maxDate), getMonth(props.maxDate), getDate(getYear(props.maxDate), getMonth(props.maxDate) + 1)) : null
    this.state = {
      start,
      end,
      endYear,
      startYear,
      minDate,
      maxDate,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      open: this.props.open
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.start !== this.props.start || nextProps.end !== this.props.start) {
      const start = new Date(getYear(nextProps.start), getMonth(nextProps.start), 1)
      const end = new Date(getYear(nextProps.end), getMonth(nextProps.end), getDate(getYear(nextProps.end), getMonth(nextProps.end) + 1))
      const endYear = getYear(end)
      const startYear = getYear(start)
      this.setState({
        start,
        end,
        endYear,
        startYear
      })
    }
  }
  onToggle () {
    if (this.state.open) {
      this.onClose()
    } else {
      this.setState({
        open: true
      })
    }
  }
  onChange (key, i) {
    if (key === 'start') {
      this.setState({
        start: new Date(this.state.startYear, i, 1),
        ...(new Date(this.state.startYear, i, 1).getTime() > new Date(this.state.end).getTime() ? {
          end: new Date(this.state.startYear, i, getDate(this.state.startYear, i + 1)).getTime(),
          endYear: this.state.startYear
        } : {})
      })
    } else {
      this.setState({
        end: new Date(this.state.endYear, i, getDate(this.state.endYear, i + 1)),
        ...(new Date(this.state.endYear, i, getDate(this.state.endYear, i + 1)).getTime() < new Date(this.state.start).getTime() ? {
          start: new Date(this.state.endYear, i, 1).getTime(),
          startYear: this.state.endYear
        } : {})
      })
    }
  }
  onClose () {
    const start = new Date(getYear(this.props.start), getMonth(this.props.start), 1)
    const end = new Date(getYear(this.props.end), getMonth(this.props.end), getDate(getYear(this.props.end), getMonth(this.props.end) + 1))
    const endYear = getYear(end)
    const startYear = getYear(start)
    this.setState({
      start,
      end,
      endYear,
      startYear,
      open: false
    })
  }
  onSubmit () {
    this.setState({
      open: false
    }, () => this.props.onSubmit(new Date(this.state.start).getTime(), new Date(this.state.end).getTime()))
  }
  renderStartMonth () {
    return (
      <div className='month-container' >
        {
          this.state.months.map((d, i) => {
          const startActive = this.state.startYear === getYear(this.state.start) && i === getMonth(this.state.start)
          const inBetweenYear = (this.state.startYear >= getYear(this.state.start) && this.state.startYear <= getYear(this.state.end))
          const inBetweenMonth = inBetweenYear && (new Date(this.state.startYear, i, 1).getTime() >= new Date(getYear(this.state.start), getMonth(this.state.start) + 1, 1).getTime() && new Date(this.state.startYear, i, getDate(this.state.startYear, i + 1)).getTime() <= new Date(this.state.end).getTime())
          const active = startActive ? '' : 'btn-default'
          const inBetweenActive = inBetweenMonth ? 'btn-primary' : ''
          const disabled = (this.state.minDate && new Date(this.state.startYear, i, 1).getTime() < new Date(this.state.minDate).getTime()) || (this.state.maxDate && new Date(this.state.startYear, i, getDate(this.state.startYear, i + 1)).getTime() > new Date(this.state.maxDate).getTime())
          return (
            <button disabled={disabled} onClick={() => this.onChange('start', i)} className={`btn month-btn ${inBetweenActive} ${active}`} key={i} >{d}</button>)
          })
        }
      </div>
    )
  }
  renderEndMonth () {
    return (
      <div className='month-container' >
        {
          this.state.months.map((d, i) => {
          const inBetweenYear = (this.state.endYear >= getYear(this.state.start) && this.state.endYear <= getYear(this.state.end))
          const inBetweenMonth = inBetweenYear && (new Date(this.state.endYear, i, 1).getTime() >= new Date(this.state.start).getTime() && new Date(this.state.endYear, i, getDate(this.state.endYear, i + 1)).getTime() <= new Date(getYear(this.state.end), getMonth(this.state.end) - 1, getDate(getYear(this.state.end), getMonth(this.state.end))).getTime())
          const endActive = this.state.endYear === getYear(this.state.end) && i === getMonth(this.state.end)
          const active = endActive ? '' : 'btn-default'
          const inBetweenActive = inBetweenMonth ? 'btn-primary' : ''
          const disabled = (this.state.minDate && new Date(this.state.endYear, i, 1).getTime() < new Date(this.state.minDate).getTime()) || (this.state.maxDate && new Date(this.state.endYear, i, getDate(this.state.endYear, i + 1)).getTime() > new Date(this.state.maxDate).getTime())
          return (
            <button disabled={disabled} onClick={() => this.onChange('end', i)} className={`btn month-btn ${inBetweenActive} ${active}`} key={i} >{d}</button>)
          })
        }
      </div>
    )
  }
  renderStartYear () {
    const { minDate, maxDate, startYear } = this.state
    const disabledStart = minDate && getYear(minDate) >= startYear
    const disabledEnd = maxDate && getYear(maxDate) <= startYear
    return (
      <div className='year-container' >
        <button disabled={disabledStart} onClick={() => this.setState({ startYear: this.state.startYear - 1 })} className='btn'>
          <span className='icon-left' />
        </button>
        <div>{this.state.startYear}</div>
        <button disabled={disabledEnd} onClick={() => this.setState({ startYear: this.state.startYear + 1 })} className='btn'>
          <span className='icon-right' />
        </button>
      </div>
    )
  }
  renderEndYear () {
    const { minDate, maxDate, endYear } = this.state
    const disabledStart = minDate && getYear(minDate) >= endYear
    const disabledEnd = maxDate && getYear(maxDate) <= endYear
    return (
      <div className='year-container' >
        <button disabled={disabledStart} onClick={() => this.setState({ endYear: this.state.endYear - 1 })} className='btn'>
          <span className='icon-left' />
        </button>
        <div>{this.state.endYear}</div>
        <button disabled={disabledEnd} onClick={() => this.setState({ endYear: this.state.endYear + 1 })} className='btn'>
          <span className='icon-right' />
        </button>
      </div>
    )
  }
  renderContent () {
    if (this.state.open) {
      return (
        <div className={`month-range-custom-container picker-${this.props.alignPicker}`}>
          <div className='month-range-custom-content' >
            <div className='range-container' >
              {this.renderStartYear()}
              {this.renderStartMonth()}
            </div>
            <div className='range-container' >
              {this.renderEndYear()}
              {this.renderEndMonth()}
            </div>
          </div>
          <div className='month-range-custom-button-group' >
            <button onClick={() => this.onClose()} className='btn btn-default' >Cancel</button>
            <button onClick={() => this.onSubmit()} className='btn' ><FormattedMessage id="Apply" defaultMessage="Apply" /></button>
          </div>
        </div>
      )
    }
    return null
  }
  render() {
    const start = Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(this.state.start))
    const end = Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(this.state.end))
    return (
      <ClickOutside onClickOutside={() => this.onClose()}>
        <div className='month-range-custom' >
          <div role='presentation' onClick={() => this.onToggle()} className={this.props.disabled ? 'month-range-custom-box disabled' : 'month-range-custom-box'} >
            {start} - {end}
          </div>
          {this.renderContent()}
        </div>
      </ClickOutside>
    )
  }
}

MonthRange.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  disabled: PropTypes.bool,
  alignPicker: PropTypes.oneOf([
    'center', 'right', 'left'
  ]),
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

MonthRange.defaultProps = {
  start: new Date(0).getTime(),
  end: new Date().getTime(),
  disabled: false,
  alignPicker: 'center',
  open: false,
  minDate: null,
  maxDate: null
}

export default MonthRange
