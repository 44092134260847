import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { modalCloseAction } from '../../../common/container/Modal/logic'
import { deleteAccountAction } from './logic'

class DeleteAccount extends Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.delete.flag !== this.props.delete.flag && nextProps.delete.flag) {
      this.props.modalCloseAction()
    }
  }
  deleteAccount () {
    this.props.deleteAccountAction()
  }
  render () {
    return (
      <Fragment>
        <div className='modal-header'>
          <div className='modal-title'><FormattedMessage id="Profile.Delete Account" defaultMessage="Delete Account" /></div>
        </div>
        <div className='modal-body'>
          <b><FormattedMessage id="Profile.Are you sure, you want to close your account ?" defaultMessage="Are you sure, you want to close your account ?" /></b>
          <b><FormattedMessage id="Profile.It may take few days for your data" defaultMessage="It may take few days for your data to get deleted. You will lose access to your account immediately and all your associated data will be erased permenently." /></b>
        </div>
        <div className='modal-footer' >
          <button disabled={this.props.delete.loading} className='btn btn-danger delete-btn' onClick={() => this.deleteAccount()}>{this.props.delete.loading ? 'Deleting' : 'Delete'}</button>
          <button className='btn delete-btn btn-default' onClick={this.props.modalCloseAction}><FormattedMessage id="Profile.Cancel" defaultMessage="Cancel" /></button>
        </div>
      </Fragment>
    )
  }
}

DeleteAccount.propTypes = {
  modalCloseAction: PropTypes.func.isRequired,
  deleteAccountAction: PropTypes.func.isRequired,
  delete: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  delete: state.deleteAccount
})

export default connect(mapStateToProps, { modalCloseAction, deleteAccountAction })(DeleteAccount)
