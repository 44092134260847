import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

class ShowMore extends Component {
  constructor() {
    super()
    this.state = {
      itemsToShow: 4,
      expanded: false
    }
    this.showMore = this.showMore.bind(this);
  }

  showMore() {
    if (this.state.itemsToShow === 4) {
      this.setState({ itemsToShow: this.props.data[0].value.length, expanded: true })
    } else {
      this.setState({ itemsToShow: 4, expanded: false })
    }
  }

  renderlist() {
    let value;
    if (this.props.data[0].value.length) {
      value = this.props.data[0].value.slice(0, this.state.itemsToShow).map((car, i) =>
        <span className='tag' key={i}>{car}</span>)
    } else {
      value = null
    }
    return value
  }
  render() {
    const { intl } = this.props
    return (
      <div>
        {this.renderlist()}
        <span role="presentation" onClick={this.showMore}>
          {this.state.expanded ? (<a className='handicon'><FormattedMessage id="Show less" defaultMessage="Show less" /></a>)
            : (<a className='handicon'>{this.props.data[0].value.length > 4 ? `${(this.props.data[0].value.length - 4)} ${intl.formatMessage({ id: 'More', defaultMessage: 'More' })}` : null}</a>)}
        </span>
      </div>)
  }
}

ShowMore.propTypes = {
  data: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired
}

ShowMore.defaultProps = {
}

export default injectIntl(ShowMore)
