import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { modalCloseAction } from '../Modal/logic'
import { Premium, Modal } from '../../components'
import DeleteAccount from '../../../app/container/DeleteAccount'
import DeleteHistory from '../../../app/container/DeleteHistory'
import Login from '../Login'
import RequestInvite from '../RequestInvite'
import HubspotFormComponent from '../HubspotForm'

class ModalComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      there: ''
    }
  }
  renderType () {
    switch (this.props.modal.type) {
      case 'premium':
        return <Premium />
      case 'login':
        return <Login width='500px' height={500} app={this.props.app} divId='ssoLogin' cssPath='sso.css' />
      case 'login-lite':
        return <Login height={500} app={this.props.app} divId='ssoLoginLite' cssPath='sso-lite-2.css' />
      case 'delete_history':
        return <DeleteHistory />
      case 'delete_account':
        return <DeleteAccount />
      case 'request_invite':
        return <RequestInvite height={500} />
      case 'hubspot':
        return <HubspotFormComponent />
      default:
        return null
    }
  }
  render () {
    const {
      dialog, open, size, className
    } = this.props.modal
    return (
      <Modal dialog={dialog} open={open} type={this.props.modal.type} onClose={this.props.modalCloseAction} size={size} className={className || ''}>
        {this.state.there}
        {this.renderType()}
      </Modal>
    )
  }
}

ModalComponent.propTypes = {
  modal: PropTypes.object.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  app: PropTypes.string
}
ModalComponent.defaultProps = {
  app: process.env.apiAppName
}

const mapStateToProps = state => ({
  modal: state.modal
})

export default connect(mapStateToProps, { modalCloseAction })(ModalComponent)
