import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HubspotForm from 'react-hubspot-form'
import { customMessage } from '../Status/logic'
import { modalCloseAction } from '../Modal/logic'
import { Loader } from '../../components'

class HubspotFormComponent extends Component {
  onSubmit = () => {
    this.props.modalCloseAction()
    this.props.customMessage({
      message: 'Thank you for requesting demo, We will get back to you soon.',
      status: 'success',
      title: 'Success'
    })
  }
  render() {
    return (
      <div className='hubspot-form'>
        <div id='0d223f61-2c12-4e1c-8482-adbebf8de597' style={{ height: 500 }} >
          <div className='hubspot-title'>
            Speak to Our Experts
          </div>
          <HubspotForm
            portalId='4993422'
            formId='0d223f61-2c12-4e1c-8482-adbebf8de597'
            onSubmit={this.onSubmit}
            loading={<Loader loading error={false} height={500} />}
            cssClass='hubspot'
          />
        </div>
      </div>
    )
  }
}

HubspotFormComponent.propTypes = {
  customMessage: PropTypes.func.isRequired,
  modalCloseAction: PropTypes.func.isRequired
}

export default connect(null, { customMessage, modalCloseAction })(HubspotFormComponent)
