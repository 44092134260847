import { combineEpics } from 'redux-observable'
import common from '../../common/container/epics'
import { deleteAccountEpic } from './DeleteAccount/logic'
import { deleteDataEpic } from './DeleteHistory/logic'
import { userInfoFetchEpic } from './Info/logic'

const rootEpic = combineEpics(
  ...Object.values(common),
  deleteAccountEpic,
  userInfoFetchEpic,
  deleteDataEpic
)

export default rootEpic
