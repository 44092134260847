import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { connect } from 'react-redux'
import { checkTokenAction } from './logic'
import { removeCookies, getCookie, PATHS } from '../../utils';
import { SpecialLoader } from '../../components'

const CheckAccessToken = () => (BaseComponent) => {
  class CheckAccessTokenCheck extends Component {
    constructor (props) {
      super(props)
      this.state = {
        loading: !!getCookie('accessToken')
      }
    }
    componentWillMount() {
      const query = parse(window.location.hash)
      const hash = parse(window.location.search)
      if ((query.hashCode || hash.hashCode) || (query.code || hash.code) || (query.alreadyLoggedIn || hash.alreadyLoggedIn) || this.props.location.pathname === '/logout') {
        removeCookies()
        this.setState({
          loading: false
        })
      } else if (getCookie('accessToken') && !this.props.hide && this.props.location.pathname !== '/logout') {
        this.props.checkTokenAction()
      }
    }
    componentWillReceiveProps (nextProps) {
      if (nextProps.checkToken.error !== this.props.checkToken.error && nextProps.checkToken.error) {
        removeCookies()
        if (!(PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '') === -1) && this.props.location.pathname !== '/')) {
          this.props.history.replace('/')
        }
      }
      if ((nextProps.checkToken.flag !== this.props.checkToken.flag && nextProps.checkToken.flag) || (nextProps.checkToken.error !== this.props.checkToken.error && nextProps.checkToken.error)) {
        this.setState({
          loading: false
        })
      }
    }
    render() {
      if (this.state.loading) {
        return (
          <SpecialLoader />
        )
      }
      return <BaseComponent {...this.props} />
    }
  }
  CheckAccessTokenCheck.propTypes = {
    checkTokenAction: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    checkToken: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    hide: PropTypes.bool,
  }
  CheckAccessTokenCheck.defaultProps = {
    hide: false
  }
  const mapStateToProps = state => ({
    checkToken: state.checkToken
  })
  return withRouter(connect(mapStateToProps, { checkTokenAction })(CheckAccessTokenCheck))
}
export default CheckAccessToken
