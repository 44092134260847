
// Sign Up form open
const SIGNUP_FORM_OPEN = 'SIGNUP_FORM_OPEN';

const INITIAL_STATE = {
  open: false,
  flag: false,
}

export const signUpFormOpenAction = () => ({
  type: SIGNUP_FORM_OPEN
})

export const signUpFormOpenReducer = (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SIGNUP_FORM_OPEN: {
      return {
        ...state,
        open: true,
        flag: true,
      }
    }
    default:
      return state
  }
}
