import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { modalCloseAction } from '../../../common/container/Modal/logic'
import { deleteDataAction } from './logic'

class DeleteHistory extends Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.delete.flag !== this.props.delete.flag && nextProps.delete.flag) {
      this.props.modalCloseAction()
    }
  }
  deleteData () {
    this.props.deleteDataAction()
  }
  render () {
    return (
      <Fragment>
        <div className='modal-header'>
          <div className='modal-title'>Delete History</div>
        </div>
        <div className='modal-body'>
          <b><FormattedMessage id="Profile.Are you sure, you want to delete all" defaultMessage="Are you sure, you want to delete all your activity history on Ontosight including your past searches, bookmarks, workspace and annotations ?" /></b>
        </div>
        <div className='modal-footer' >
          <button disabled={this.props.delete.loading} className='btn delete-btn btn-danger' onClick={() => this.deleteData()}>{this.props.delete.loading ? 'Deleting' : 'Delete'}</button>
          <button className='btn btn-default delete-btn' onClick={this.props.modalCloseAction}><FormattedMessage id="Profile.Cancel" defaultMessage="Cancel" /></button>
        </div>
      </Fragment>
    )
  }
}

DeleteHistory.propTypes = {
  modalCloseAction: PropTypes.func.isRequired,
  deleteDataAction: PropTypes.func.isRequired,
  delete: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  delete: state.deleteData
})

export default connect(mapStateToProps, { modalCloseAction, deleteDataAction })(DeleteHistory)
